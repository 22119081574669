<template>
  <div class="StudentLoginPage_fr-2348">
    <div class="overlap-StudentLoginPage_gr-1">
      <div class="StudentLoginPage_gr-2351">
        <StudentLoginPage_gr2349 :src="StudentLoginPage_gr2351Props.src" />
        <div class="technical-education-1 inter-medium-gray-asparagus-15px">
          {{ technicalEducation }}
        </div>
      </div>
      <p class="lorem-ipsum-dolor-si inter-light-black-15px">
        {{ loremIpsumDolorSi }}
      </p>
      <div class="StudentLoginPage_gr-2350">
        <div class="x75-match inter-medium-gray-asparagus-15px">
          {{ x75Match }}
        </div>
        <img class="like-icon" :src="heart1" alt="Like Icon" />
      </div>
    </div>
  </div>
</template>

<script>
import StudentLoginPage_gr2349 from "./StudentLoginPage_gr2349.vue";
export default {
  name: "StudentLoginPage_fr2347",
  components: {
    StudentLoginPage_gr2349,
  },
  props: [
    "technicalEducation",
    "loremIpsumDolorSi",
    "x75Match",
    "heart1",
    "StudentLoginPage_gr2351Props",
  ],
};
</script>

<style scoped>
.StudentLoginPage_fr-2348 {
  align-items: flex-start;
  display: flex;
  height: 142px;
  left: 0;
  min-width: 272px;
  position: absolute;
  top: 88px;
}

.overlap-StudentLoginPage_gr-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 15px #0000000d;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 142px;
  padding: 15px 8px;
  width: 272px;
}

.StudentLoginPage_gr-2351 {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 28px;
  margin-left: 2px;
  min-width: 183px;
  position: relative;
}

.technical-education-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 18px;
  min-width: 145px;
  white-space: nowrap;
}

.lorem-ipsum-dolor-si {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  min-height: 36px;
  width: 252px !important;
}

.StudentLoginPage_gr-2350 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 153px;
  min-width: 254px;
}

.x75-match {
  letter-spacing: 0;
  line-height: normal;
  min-height: 18px;
  min-width: 81px;
  white-space: nowrap;
}

.like-icon {
  height: 18px;
  width: 18px;
}
</style>
