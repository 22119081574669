<template>
  <div
    class="w-[274px] max-w-sm border rounded-full px-4 py-2 flex items-center overflow-hidden transition-all duration-150"
    :class="[{ 'border-green-600': focus }]"
  >
    <div class="flex items-center justify-center rounded-full p-2 bg-[var(--style-percent-13)]">
      <img src="@/assets/images/searchgreen.png" alt="search" class="cursor-pointer" />
    </div>
    <div class="ml-4">
      <input
        type="text"
        class="w-full h-full bg-transparent outline-none text-sm"
        :placeholder="placeholder"
        v-model="val"
        @input="$emit('update', val)"
        @focusin="focus = true"
        @focusout="focus = false"
      />
    </div>
  </div>
</template>

<script setup>
  import { onBeforeMount, ref } from "vue";
  const props = defineProps({
    placeholder: String,
  });
  const val = ref("");
  const focus = ref(false);
  defineOptions({
    name: "SearchBar",
  });
</script>

<style></style>
