<template>
  <div v-if="visible" class="modal-overlay" @click.self="closeAlert">
    <div class="custom-alert">
      <div class="alert-content">
        <p>{{ message }}</p>
        <button class="ok-button" @click="closeAlert">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      message: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        visible: true,
      };
    },
    methods: {
      closeAlert() {
        this.visible = false;
        localStorage.clear();
        window.location.href = "/";
      },
    },
  };
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Dark semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* Ensure it is on top */
  }

  .custom-alert {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 400px;
  }

  .alert-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .alert-content p {
    font-size: 24px;
    font-weight: 600;
    color: #000;
  }
  

  .ok-button {
    align-self: flex-end;
    background-color: var(--style);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 25px;
    cursor: pointer;
    font-size: 16px;
  }

  .ok-button:hover {
    background-color: #45a049;
    /* Darker green on hover */
  }
</style>
