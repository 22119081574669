export const SERVER_PUBLIC_KEY_PEM =`
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkHCIFkEPi/5Jr8JppXwh
ChuvVE4KY+yyzUnWifYRjyMmQINue1OckJrmuZ+fSsm04zdKvCwj33rNOHSmjEDo
e7OEvTMf+vbRPtHrfSucRoYIDCHJKWVYwPhwoDHoAOo3FV50EylZYGOodEz9qX/x
WesM+6h/K8o+6ThdLgK9/An5NX8EH/tpBrBYNaDMNnQCWJD9UI6mTEM5TD3ztzXu
NrPPfzHF+S5S+fUSvEKD/lTnJSMNW6c6kZFT1IXfbZnhWI2rPgauEGLPO1cCmI+t
58GBT6K65PJYlTRxYHEngk69kVE6kRID/QX/LTX49ewGvyc4uohFT9Y/7e4D12vx
rwIDAQAB
-----END PUBLIC KEY-----`
