<template>
  <div class="StudentLoginPage_gr-2349">
    <img class="user-alt-1" :src="src" alt="user-alt 1" />
  </div>
</template>

<script>
export default {
  name: "StudentLoginPage_gr2349",
  props: ["src"],
};
</script>

<style scoped>
.StudentLoginPage_gr-2349 {
  align-items: center;
  background-color: var(--x259b35);
  border-radius: 100px;
  display: flex;
  height: 28px;
  min-width: 28px;
  padding: 0 7px;
}

.user-alt-1 {
  height: 14px;
  width: 14px;
}
</style>
