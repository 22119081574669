<template>
  <div class="academics">
    <div>
      <h1>{{ heading }}</h1>
    </div>
    <ResumeDescription v-for="(item, index) in dataArray" :key="index" :title="item.title"
      :description="item.description" />
  </div>
</template>

<script>
import ResumeDescription from "./resumeDescription.vue";

export default {
  name: "resumeSection",
  components: {
    ResumeDescription,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    dataArray: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.academics {
  margin-top: 30px;
}

.academics h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
