<template>
    <div
      class="h-screen z-50  w-full bg-gray-800/40 pt-40 left-0 top-0 fixed flex items-center justify-center"
    >
    <div class="w-full h-full absolute top-0 left-0" @click="$emit('close')"></div>
      <div class="w-96 h-96 rounded-2xl mx-auto bg-white flex flex-col items-center py-4 px-4 relative">
          <h1 class="inter mt-4 text-[20px] font-medium text-center">
            Add Class
          </h1>
          <div class="w-full mt-4 h-8 min-h-[32px] flex items-center mx-auto rounded-full px-4 bg-[#259B35]/10 mb-3">
            <input
              v-model="searchKey"
              placeholder="Search Class"
              class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
            >
          </div>
          <div class="w-full gap-2 overflow-y-scroll" v-if="filteredClasses.length>0">
            <div class="w-full mt-4 h-8 flex items-center justify-between mx-auto rounded-full px-4 h-12 cursor-pointer hover:bg-[#259B35]/10"
                v-for="c in filteredClasses" :key="JSON.stringify(c)"
                @click="updateClass(c)"
            >
                <p class="text-sm">{{ c.name }}</p>
                <!-- <span class="material-symbols-rounded text-sm text-[#259B35]">arrow_forward_ios</span> -->
            </div>
          </div>
          <div class="text-center py-5 text-gray-500" v-else>No Classes found !</div>
          <!-- Removed buttons for clarity, you can add them back if needed. -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
      props: {
          modalType:String,
          classes: Array
      },
      data(){
        return{
            searchKey:''
        }
      },
      computed:{
        filteredClasses(){
            return this.classes.filter(el => el.name.toLowerCase().includes(this.searchKey.toLowerCase()))
        }
      },
      methods:{
        updateClass(c){
            this.$emit('update', { class :c, type:this.modalType}); 
        }
      }
  }
  </script>
<style scoped>
.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
</style>