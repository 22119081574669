<template>
  <div class="main-wrapper">
    <div class="w-full" v-if="!hideTopNavigationBar">
      <top-navigation-bar />
    </div>
    <div class="w-full h-full grid grid-cols-1 grid-rows-1 mt-5 overflow-hidden">
      <slot />
    </div>
  </div>
</template>
<script>
  import TopNavigationBar from "../components/TopNavigationBar.vue";
  export default {
    components: {
      TopNavigationBar,
    },
    props: {
      hideTopNavigationBar: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
<style scoped>
  .main-wrapper {
    @apply w-full max-w-[1280px] h-full mx-auto px-5 m-0 flex flex-col items-center justify-center;
  }
</style>
