import { defineStore } from "pinia";

export const useProfileFilters = defineStore("profileFilters", {
  state: () => ({
    salaryRange: "",
    carrerCluster: "",
    jobZones: "",
  }),
  actions: {
    setOccupationFilters({ selectedSalary, selectedCluster, selectedEducation }) {
      console.log("dfd ", { selectedSalary, selectedCluster, selectedEducation });
      this.salaryRange = selectedSalary;
      this.carrerCluster = selectedCluster;
      this.jobZones = selectedEducation;
    },
    resetOccupationFilters() {
      this.salaryRange = "";
      this.carrerCluster = "";
      this.jobZones = "";
    },
  },
});
