<template>
    <studentDemoLayout>
        <div class="w-full h-full gap-5 flex">
            <BroadcastFeed />
            <StudentPrefrences />
        </div>
    </studentDemoLayout>
</template>

<script>
import BroadcastFeed from "../../components/BroadcastFeed.vue";
import StudentPrefrences from "../../components/StudentPrefrences.vue";
import studentDemoLayout from "../../layouts/studentDemoLayout.vue";

export default {
    name: "HomepageForStudentDemoByEducator",
    components: {
        BroadcastFeed,
        StudentPrefrences,
        studentDemoLayout
    },
    props: [],
};
</script>
