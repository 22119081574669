<template>
    <div class="w-full">
        <div class="mb-10">
            <h3 class="font-semibold text-lg mb-3">Job Zone</h3>
            <p class="text-black/80">
                {{ job["Name"] }}
            </p>
        </div>
        <div class="w-full grid grid-cols-2 gap-10">
            <div>
                <h3 class="font-semibold text-lg mb-3">Education</h3>
                <p class="text-black/80">
                    {{ job["Education"] }}
                </p>
            </div>
            <div>
                <h3 class="font-semibold text-lg mb-3">Experience</h3>
                <p class="text-black/80">
                    {{ job["Experience"] }}
                </p>
            </div>
            <div>
                <h3 class="font-semibold text-lg mb-3">Job Training</h3>
                <p class="text-black/80">
                    {{ job["Job Training"] }}
                </p>
            </div>
            <div>
                <h3 class="font-semibold text-lg mb-3">Examples</h3>
                <p class="text-black/80">
                    {{ job["Examples"] }}
                </p>
            </div>
        </div>

    </div>
</template>
<script>
import jobZoneRef from "../../assets/js/filtered_occupation/jobZoneRef.json";
import jobZone from "../../assets/js/onet_job_zones/job_zones.json";

export default{
    props:["OnetCode"],
    computed:{
        job(){
            const res = jobZone.find(el => el["O*NET-SOC Code"] === this.OnetCode);
            return jobZoneRef.find(el => el["Job Zone"] == res["Job Zone"]);
        }
    }
}


</script>