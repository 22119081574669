<template>
  <div class="rounded-[50px] p-8 bg-white shadow-3xl space-y-4 mb-6 mt-5">
    <h3 class="text-xl font-medium text-black">Scholarship</h3>
    <div class="p-4">
      <div class="">
        <div class="text-[15px] p-3 text-[#B4ACAC]">{{title}}</div>
        <ul class="divide-y">
          <li v-for='item in listItems' class="font-[500] text-[15px] p-3 flex justify-between">
            <p>{{item.name}}</p>
            <p>{{item.price}}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
  export default {
    data() {
      return {
        listItems: [
          { name: "Stamps Scholars Award", price: '23,456'}, 
          { name: "George C Awards", price: '23,456'}, 
          { name: "Diversity Award", price: '23,456'}, 
          { name: "Swim", price: '23,456'}, 
          { name: "Polo", price: '23,456'},
        ],
        title: "Scholarship"
      };
    },
  };
</script>