import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./globals.css";
import "./styleguide.css";
import 'vue3-tooltip/tooltip.css';
import 'vue3-toastify/dist/index.css';
import "./assets/css/toastify.css";
import "./assets/css/tooltip.css";
import { createPinia } from "pinia";
import VueApexCharts from "vue3-apexcharts";
import CustomAlert from "./components/Atoms/CustomAlert.vue";
import { $request, $requestResume } from './api.js';
import Vue3Toastify from 'vue3-toastify';
import {TooltipComponent, TooltipDirective} from "vue3-tooltip";

const pinia = createPinia();

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

const app = createApp(App).use(router).use(VueApexCharts).use(Vue3Toastify, {
  autoClose: 5000,
}).directive("click-outside", clickOutside).directive('tooltip', TooltipDirective).component("CustomAlert", CustomAlert).component('tooltip', TooltipComponent);

app.config.globalProperties.$customLog = function (msg) {
};

app.config.globalProperties.$globalResumeData = {};

app.config.globalProperties.$request = $request
app.config.globalProperties.$requestResume = $requestResume

app.provide('$request', app.config.globalProperties.$request);
app.use(router).use(pinia).mount("#app");
