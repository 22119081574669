<template>
  <div class="container-center-horizontal">
    <form class="student-login-page screen" name="form1" action="form1" method="post">
      <div class="StudentLoginPage_flex-col">
        <div class="flex-row">
          <img class="image-1" :src="image1" alt="image 1" />
          <h1 class="title">
            {{ title }}
          </h1>
        </div>
        <div class="overlap-StudentLoginPage_gr6">
          <div class="StudentLoginPage_fr-container">
            <div class="StudentLoginPage_fr-2357">
              <div class="StudentLoginPage_fr-2354">
                <img
                  class="angle-right-1"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/angle-right-1.svg"
                  alt="angle-right 1"
                />
                <div class="career-profile">
                  {{ careerProfile }}
                </div>
              </div>
              <div class="StudentLoginPage_fr-2356">
                <div class="StudentLoginPage_gr-2331 inter-light-black-8px">
                  <div class="StudentLoginPage_flex-col-1">
                    <div class="conventional">
                      {{ conventional }}
                    </div>
                    <div class="enterprising">
                      {{ enterprising }}
                    </div>
                  </div>
                  <div class="StudentLoginPage_flex-col-2">
                    <div class="realistic">
                      {{ realistic }}
                    </div>
                    <div class="overlap-StudentLoginPage_gr1">
                      <div class="overlap-StudentLoginPage_gr">
                        <img
                          class="polygon-4"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-4.svg"
                          alt="Polygon 4"
                        />
                        <img
                          class="polygon-5"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-5.svg"
                          alt="Polygon 5"
                        />
                        <img
                          class="polygon-6"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-6.svg"
                          alt="Polygon 6"
                        />
                        <img
                          class="polygon-7"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-7.svg"
                          alt="Polygon 7"
                        />
                        <img
                          class="polygon-8"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-8.svg"
                          alt="Polygon 8"
                        />
                        <img
                          class="vector-23"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-23.svg"
                          alt="Vector 23"
                        />
                        <img
                          class="vector-24"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-24.svg"
                          alt="Vector 24"
                        />
                        <img
                          class="vector-25"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-25.svg"
                          alt="Vector 25"
                        />
                        <img
                          class="vector-26"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-26.svg"
                          alt="Vector 26"
                        />
                        <img
                          class="vector-27"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-27.svg"
                          alt="Vector 27"
                        />
                        <img
                          class="vector-28"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-28.svg"
                          alt="Vector 28"
                        />
                        <img
                          class="vector-31"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-31.svg"
                          alt="Vector 31"
                        />
                        <div class="investigative">
                          {{ investigative }}
                        </div>
                        <div class="ellipse-40" />
                        <div class="ellipse-44" />
                        <div class="ellipse-45" />
                        <div class="ellipse-46" />
                        <div class="ellipse-47" />
                        <div class="number valign-text-middle inter-light-white-8px">
                          {{ number1 }}
                        </div>
                        <div class="number-1 valign-text-middle inter-light-white-8px">
                          {{ number2 }}
                        </div>
                        <div class="number-2 valign-text-middle inter-light-white-8px">
                          {{ number3 }}
                        </div>
                        <div class="number-3 valign-text-middle inter-light-white-8px">
                          {{ number4 }}
                        </div>
                        <div class="number-4 valign-text-middle inter-light-white-8px">
                          {{ number5 }}
                        </div>
                      </div>
                      <div class="artistic">
                        {{ artistic }}
                      </div>
                    </div>
                    <div class="social">
                      {{ social }}
                    </div>
                  </div>
                </div>
                <div class="StudentLoginPage_fr-2346">
                  <div class="StudentLoginPage_gr-2346">
                    <div class="flex-row-1">
                      <div class="suggested-careers inter-normal-black-20px">
                        {{ suggestedCareers }}
                      </div>
                      <StudentLoginPage_gr2343 :src="StudentLoginPage_gr23431Props.src" />
                    </div>
                    <div class="StudentLoginPage_fr-234">
                      <div class="StudentLoginPage_gr-6">
                        <div class="StudentLoginPage_flex-col-3">
                          <div class="technical-education inter-light-black-15px">
                            {{ technicalEducation }}
                          </div>
                          <div class="x60000-annually inter-light-black-10px">
                            {{ x60000Annually1 }}
                          </div>
                        </div>
                        <div class="rectangle-10" />
                      </div>
                      <div class="StudentLoginPage_gr-7">
                        <div class="StudentLoginPage_flex-col-4">
                          <div class="adult-basic-secondary-ed inter-light-black-15px">
                            {{ adultBasicSecondaryEd }}
                          </div>
                          <div class="x60000-annually-1 inter-light-black-10px">
                            {{ x60000Annually2 }}
                          </div>
                        </div>
                        <div class="rectangle-10-1" />
                      </div>
                    </div>
                  </div>
                  <div class="StudentLoginPage_gr-2345">
                    <div class="flex-row-2">
                      <div class="opportunities inter-normal-black-20px">
                        {{ opportunities }}
                      </div>
                      <StudentLoginPage_gr2343 :src="StudentLoginPage_gr23432Props.src" />
                    </div>
                    <div class="StudentLoginPage_fr-234">
                      <div class="StudentLoginPage_gr-8">
                        <div class="StudentLoginPage_flex-col-5">
                          <div class="scholar-path inter-light-black-15px">
                            {{ scholarpath }}
                          </div>
                          <div class="st-peters-missouri inter-light-black-10px">
                            {{ stPetersMissouri }}
                          </div>
                        </div>
                        <img class="mask-StudentLoginPage_gr" :src="maskGroup" alt="Mask group" />
                      </div>
                      <StudentLoginPage_gr9 :rectangle10="StudentLoginPage_gr91Props.rectangle10" />
                      <StudentLoginPage_gr9 :rectangle10="StudentLoginPage_gr92Props.rectangle10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StudentLoginPage_fr2347
              :technical-education="StudentLoginPage_fr2347Props.technicalEducation"
              :lorem-ipsum-dolor-si="StudentLoginPage_fr2347Props.loremIpsumDolorSi"
              :x75-match="StudentLoginPage_fr2347Props.x75Match"
              :heart1="StudentLoginPage_fr2347Props.heart1"
              :StudentLoginPage_gr2351Props="StudentLoginPage_fr2347Props.StudentLoginPage_gr2351Props"
            />
          </div>
          <StudentLoginPage_fr23472
            :about-me="StudentLoginPage_fr23472Props.aboutMe"
            :address="StudentLoginPage_fr23472Props.address"
            :lorem-ipsum-dolor-si="StudentLoginPage_fr23472Props.loremIpsumDolorSi"
            :percent="StudentLoginPage_fr23472Props.percent"
            :StudentLoginPage_gr2349Props="StudentLoginPage_fr23472Props.StudentLoginPage_gr2349Props"
          />
        </div>
      </div>
      <div class="overlap-StudentLoginPage_gr5">
        <div class="welcome-back">
           {{ welcomeBack }}
        </div>
         <login-box v-bind="loginBoxProps" />
        <div class="StudentLoginPage_gr-2404">
           <img
            class="line-3"
            src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/line-34.svg"
            alt="Line 35"
          />
          <div class="or">
            {{ or }}
          </div>
          <img
            class="line-3"
            src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/line-34.svg"
            alt="Line 34"
          />
         </div>
        <!-- Student Login Page -->
        <div class="overlap-StudentLoginPage_gr-container">
          <a href="" @click.prevent="initiateGoogleSSO">
            <div class="overlap-StudentLoginPage_gr2">
              <div class="sign-in-with-google inter-normal-black-15px">
                {{ signInWithGoogle }}
              </div>
              <div class="rectangle-92"></div>
              <img class="image-3" :src="image3" alt="image 3" />
            </div>
          </a>
          <!-- <div class="overlap-StudentLoginPage_gr3">
            <div class="sign-in-with-nc-ed-cloud inter-normal-black-15px">
              {{ signInWithNcedcloud }}
            </div>
            <img class="image-4" :src="image4" alt="image 4" />
            <div class="rectangle-92-1" />
          </div> -->
        </div>
        <p class="display-msg error display-error"></p>
      </div>
    </form>
  </div>
</template>

<script>
    import LoginBox from "./LoginBox.vue";
    import StudentLoginPage_fr2347 from "./StudentLoginPage_fr2347.vue";
    import StudentLoginPage_fr23472 from "./StudentLoginPage_fr23472.vue";
    import StudentLoginPage_gr2343 from "./StudentLoginPage_gr2343.vue";
    import StudentLoginPage_gr9 from "./StudentLoginPage_gr9.vue";
    import {
      fetchData,
      googleSSO,
      googleCallback,
      sendCodeToBackend,
      edLinkLogin,
      storeUser,
      navigateToHomePageByRole,
      showError,
      clearError,
      getTopRole,
    } from "@/auth.js";

    export default {
        name: "StudentLoginPage",
        components: {
            StudentLoginPage_gr2343,
            StudentLoginPage_gr9,
            StudentLoginPage_fr2347,
            StudentLoginPage_fr23472,
            LoginBox,
        },
        props: [
            "image1",
            "title",
            "careerProfile",
            "conventional",
            "enterprising",
            "realistic",
            "investigative",
            "number1",
            "number2",
            "number3",
            "number4",
            "number5",
            "artistic",
            "social",
            "suggestedCareers",
            "technicalEducation",
            "x60000Annually1",
            "adultBasicSecondaryEd",
            "x60000Annually2",
            "opportunities",
            "scholarpath",
            "stPetersMissouri",
            "maskGroup",
            "welcomeBack",
            "or",
            "signInWithGoogle",
            "image3",
            "signInWithNcedcloud",
            "image4",
            "StudentLoginPage_gr23431Props",
            "StudentLoginPage_gr23432Props",
            "StudentLoginPage_gr91Props",
            "StudentLoginPage_gr92Props",
            "StudentLoginPage_fr2347Props",
            "StudentLoginPage_fr23472Props",
            "loginBoxProps",
        ],
        data() {
            return {
                pageTitle: "ScholarPath",
            };
        },
        mounted() {
            this.loadData();
            document.title = this.pageTitle;
        },
        methods: {
          async loadData() {
            try {
              await fetchData(this.$request);
            } catch (err) {
              this.error = err.message || "An error occurred";
            }
          },
          initiateGoogleSSO() {
            googleSSO(clearError, this.googleCallback);
          },
          async googleCallback(response) {
            try {
              await googleCallback(
                response,
                (code) => sendCodeToBackend(code, this.$request),
                showError,
                (payLoad) => edLinkLogin(true, payLoad, this.$request, storeUser, (role) => navigateToHomePageByRole(role, this.$router))
              );
            } catch (error) {
            }
          },
        },
    };
</script>

<style scoped>
  .student-login-page {
    align-items: flex-start;
    background-color: var(--black-haze);
    border: 1px none;
    display: flex;
    gap: 59px;
    height: 982px;
    overflow: hidden;
    width: 1512px;
  }

  .StudentLoginPage_flex-col {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 82px;
    margin-left: 48px;
    margin-top: 31px;
    min-height: 1018px;
    width: 529px;
  }

  .flex-row {
    align-items: center;
    display: flex;
    gap: 10px;
    min-width: 340px;
  }

  .image-1 {
    height: 95px;
    object-fit: cover;
    width: 97px;
  }

  .title {
    color: var(--style);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xl);
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 3px;
    min-height: 48px;
    min-width: 233px;
    white-space: nowrap;
  }

  .overlap-StudentLoginPage_gr6 {
    height: 841px;
    position: relative;
    width: 529px;
  }

  .StudentLoginPage_fr-container {
    height: 841px;
    left: 53px;
    position: absolute;
    top: 0;
    width: 476px;
  }

  .StudentLoginPage_fr-2357 {
    align-items: flex-start;
    background-color: var(--white);
    border-radius: 30px;
    box-shadow: 0px 0px 15px #0000000d;
    display: flex;
    flex-direction: column;
    gap: 13px;
    left: 187px;
    min-height: 841px;
    padding: 30px 14.7px;
    position: absolute;
    top: 0;
    width: 289px;
  }

  .StudentLoginPage_fr-2354 {
    align-items: center;
    display: inline-flex;
    gap: 30px;
    margin-left: 15.34px;
    position: relative;
  }

  .angle-right-1 {
    height: 47px;
    position: relative;
    width: 18px;
  }

  .career-profile {
    color: #141414;
    font-family: var(--font-family-inter);
    font-size: var(--font-size-l);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .StudentLoginPage_fr-2356 {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 46px;
    min-height: 680px;
    width: 259px;
  }

  .StudentLoginPage_gr-2331 {
    align-items: center;
    display: flex;
    gap: 1px;
    height: 212px;
    min-width: 281px;
  }

  .StudentLoginPage_flex-col-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 84px;
    margin-top: 5.76px;
    min-height: 107px;
    width: 56px;
  }

  .conventional {
    letter-spacing: 0;
    line-height: normal;
    min-height: 12px;
    width: 56px;
  }

  .enterprising {
    letter-spacing: 0;
    line-height: normal;
    margin-left: 1.15px;
    min-height: 12px;
    width: 52px;
  }

  .StudentLoginPage_flex-col-2 {
    align-items: center;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    min-height: 212px;
    width: 202px;
  }

  .realistic {
    letter-spacing: 0;
    line-height: normal;
    margin-right: 53.92px;
    min-height: 12px;
    text-align: center;
    width: 37px;
  }

  .overlap-StudentLoginPage_gr1 {
    height: 180px;
    margin-top: 4px;
    position: relative;
    width: 202px;
  }

  .overlap-StudentLoginPage_gr {
    height: 180px;
    left: 0;
    position: absolute;
    top: 0;
    width: 202px;
  }

  .polygon-4 {
    height: 171px;
    left: 0;
    position: absolute;
    top: 9px;
    width: 147px;
  }

  .polygon-5 {
    height: 137px;
    left: 14px;
    position: absolute;
    top: 27px;
    width: 120px;
  }

  .polygon-6 {
    height: 104px;
    left: 30px;
    position: absolute;
    top: 43px;
    width: 88px;
  }

  .polygon-7 {
    height: 67px;
    left: 44px;
    position: absolute;
    top: 61px;
    width: 60px;
  }

  .polygon-8 {
    height: 36px;
    left: 59px;
    position: absolute;
    top: 77px;
    width: 30px;
  }

  .vector-23 {
    height: 44px;
    left: 0;
    position: absolute;
    top: 52px;
    width: 74px;
  }

  .vector-24 {
    height: 84px;
    left: 74px;
    position: absolute;
    top: 11px;
    width: 1px;
  }

  .vector-25 {
    height: 44px;
    left: 75px;
    position: absolute;
    top: 52px;
    width: 71px;
  }

  .vector-26 {
    height: 44px;
    left: 1px;
    position: absolute;
    top: 94px;
    width: 74px;
  }

  .vector-27 {
    height: 84px;
    left: 74px;
    position: absolute;
    top: 95px;
    width: 2px;
  }

  .vector-28 {
    height: 44px;
    left: 75px;
    position: absolute;
    top: 94px;
    width: 71px;
  }

  .vector-31 {
    height: 100px;
    left: 19px;
    position: absolute;
    top: 40px;
    width: 114px;
  }

  .investigative {
    left: 147px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 39px;
    width: 55px;
  }

  .ellipse-40 {
    background-color: var(--bud);
    border-radius: 6.5px;
    height: 13px;
    left: 67px;
    position: absolute;
    top: 72px;
    width: 13px;
  }

  .ellipse-44 {
    background-color: var(--bud);
    border-radius: 6.5px;
    height: 13px;
    left: 67px;
    position: absolute;
    top: 54px;
    width: 13px;
  }

  .ellipse-45 {
    background-color: var(--bud);
    border-radius: 6.5px;
    height: 13px;
    left: 67px;
    position: absolute;
    top: 35px;
    width: 13px;
  }

  .ellipse-46 {
    background-color: var(--bud);
    border-radius: 6.5px;
    height: 13px;
    left: 67px;
    position: absolute;
    top: 19px;
    width: 13px;
  }

  .ellipse-47 {
    background-color: var(--bud);
    border-radius: 7.5px;
    height: 15px;
    left: 66px;
    position: absolute;
    top: 0;
    width: 15px;
  }

  .number {
    height: 10px;
    left: 68px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 74px;
  }

  .number-1 {
    height: 10px;
    left: 68px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 56px;
  }

  .number-2 {
    height: 10px;
    left: 69px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 36px;
  }

  .number-3 {
    height: 10px;
    left: 69px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 20px;
  }

  .number-4 {
    height: 10px;
    left: 66px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 3px;
  }

  .artistic {
    left: 152px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 135px;
    width: 31px;
  }

  .social {
    letter-spacing: 0;
    line-height: normal;
    margin-right: 52.77px;
    margin-top: 5px;
    min-height: 12px;
    text-align: center;
    width: 27px;
  }

  .StudentLoginPage_fr-2346 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-left: 3px;
    min-height: 367px;
    width: 253px;
  }

  .StudentLoginPage_gr-2346 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-height: 139px;
    width: 255px;
  }

  .flex-row-1 {
    align-items: center;
    display: flex;
    gap: 36px;
    height: 33px;
    margin-left: 2px;
    min-width: 251px;
    position: relative;
  }

  .suggested-careers {
    letter-spacing: 0;
    line-height: normal;
    margin-top: 1px;
    min-height: 24px;
    min-width: 182px;
    white-space: nowrap;
  }

  .StudentLoginPage_fr-234 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    width: 253px;
  }

  .StudentLoginPage_gr-6 {
    height: 37px;
    position: relative;
    width: 236px;
  }

  .StudentLoginPage_flex-col-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 7px;
    left: 40px;
    min-height: 37px;
    position: absolute;
    top: 0;
    width: 192px;
  }

  .technical-education {
    letter-spacing: 0;
    line-height: normal;
    min-height: 18px;
    white-space: nowrap;
  }

  .x60000-annually {
    letter-spacing: 0;
    line-height: normal;
    min-height: 12px;
    white-space: nowrap;
  }

  .rectangle-10 {
    background-color: var(--x259b35);
    border-radius: 2px;
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }

  .StudentLoginPage_gr-7 {
    height: 33px;
    margin-right: -5px;
    position: relative;
    width: 258px;
  }

  .StudentLoginPage_flex-col-4 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 6px;
    left: 40px;
    min-height: 33px;
    position: absolute;
    top: 0;
    width: 214px;
  }

  .adult-basic-secondary-ed,
  .scholar-path {
    letter-spacing: 0;
    line-height: normal;
    min-height: 16px;
    white-space: nowrap;
  }

  .x60000-annually-1,
  .st-peters-missouri {
    letter-spacing: 0;
    line-height: normal;
    min-height: 11px;
    white-space: nowrap;
  }

  .rectangle-10-1 {
    background-color: var(--x259b35);
    border-radius: 2px;
    height: 27px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }

  .StudentLoginPage_gr-2345 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-height: 198px;
    width: 255px;
  }

  .flex-row-2 {
    align-items: center;
    display: flex;
    gap: 88px;
    height: 33px;
    margin-left: 2px;
    min-width: 251px;
    position: relative;
  }

  .opportunities {
    letter-spacing: 0;
    line-height: normal;
    margin-top: 1px;
    min-height: 24px;
    min-width: 130px;
    white-space: nowrap;
  }

  .StudentLoginPage_gr-8 {
    height: 33px;
    margin-right: -24px;
    position: relative;
    width: 277px;
  }

  .StudentLoginPage_flex-col-5 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 6px;
    left: 40px;
    min-height: 33px;
    position: absolute;
    top: 0;
    width: 233px;
  }

  .mask-StudentLoginPage_gr {
    height: 27px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }

  .overlap-StudentLoginPage_gr5 {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    position: relative;
    @apply h-screen items-center justify-center px-[80px];
  }

  .welcome-back {
    align-self: flex-start;
    color: var(--x475f4a);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xl);
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-left: 19px;
    min-height: 48px;
    white-space: nowrap;
  }

  .StudentLoginPage_gr-2404 {
    align-items: center;
    display: flex;
    gap: 13px;
    height: 15px;
    margin-left: 2px;
    margin-top: 30px;
    min-width: 386px;
  }

  .line-3 {
    height: 1px;
    margin-top: 1px;
    width: 173px;
  }

  .or {
    color: var(--bud);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-s);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    min-height: 15px;
    min-width: 12px;
  }

  .overlap-StudentLoginPage_gr-container {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    gap: 5px;
    margin-top: 20px;
    min-width: 410px;
  }

  .overlap-StudentLoginPage_gr2 {
    border-radius: 5px;
    height: 51px;
    position: relative;
    width: 186px;
  }

  .error {
    color: #af4242;
    padding: 10px;
    display: none;
    width: 100%;
    margin-top: 25px;
  }

  .error.display-error {
    display: block;
    margin-bottom: 20px;
    transform: translateY(-20px);
  }

  .sign-in-with-google {
    left: 39px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 17px;
    white-space: nowrap;
  }

  .rectangle-92 {
    border: 0.5px solid;
    border-color: var(--x475f4a);
    border-radius: 5px;
    height: 51px;
    left: 0;
    position: absolute;
    top: 0;
    width: 186px;
  }

  .image-3 {
    height: 25px;
    left: 10px;
    object-fit: cover;
    position: absolute;
    top: 13px;
    width: 24px;
  }

  .overlap-StudentLoginPage_gr3 {
    border-radius: 5px;
    height: 51px;
    position: relative;
    width: 219px;
  }

  .sign-in-with-nc-ed-cloud {
    left: 40px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 17px;
    white-space: nowrap;
  }

  .image-4 {
    height: 25px;
    left: 10px;
    object-fit: cover;
    position: absolute;
    top: 13px;
    width: 25px;
  }

  .rectangle-92-1 {
    border: 0.5px solid;
    border-color: var(--x475f4a);
    border-radius: 5px;
    height: 51px;
    left: 0;
    position: absolute;
    top: 0;
    width: 219px;
  }
</style>
