<template>
  <div class="p-10">
    <div class="w-full flex items-center justify-between pb-5">
      <div></div>
      <div class="btns">
        <button class="single_btn text-xs py-1 px-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 18 18" fill="none">
            <g clip-path="url(#clip0_189_1097)">
              <path
                d="M17.34 7.59003L13.29 11.775C13.14 11.925 12.945 12 12.75 12C12.555 12 12.375 11.9325 12.225 11.79C11.925 11.505 11.9175 11.025 12.21 10.7325L16.065 6.75753H9.75C8.5125 6.75753 7.5 7.77003 7.5 9.00753V12.7575C7.5 13.17 7.1625 13.5075 6.75 13.5075C6.3375 13.5075 6 13.17 6 12.7575V9.00753C6 6.93753 7.68 5.25753 9.75 5.25753H16.065L12.21 1.27503C11.925 0.975026 11.925 0.502526 12.225 0.217526C12.525 -0.0674737 12.9975 -0.0674737 13.2825 0.232526L17.34 4.42503C18.21 5.29503 18.21 6.72003 17.3325 7.59753L17.34 7.59003ZM11.1825 12.8625C10.905 12.5925 9.78 11.1 9 10.05C9 10.05 9 11.55 9 12.66C9 13.77 8.265 14.745 7.2 14.955C5.76 15.2325 4.5 14.1375 4.5 12.75V9.00003C4.5 7.53003 5.1075 6.20253 6.0825 5.25003H3.75C1.68 5.25003 0 6.93003 0 9.00003V14.25C0 16.32 1.68 18 3.75 18H9C11.07 18 12.75 16.32 12.75 14.25V13.5C12.165 13.5 11.6025 13.275 11.1825 12.8625Z"
                fill="#475F4A"
              />
            </g>
            <defs>
              <clipPath id="clip0_189_1097">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="text-xs"> Share </span>
        </button>

        <button class="single_btn cursor-pointer text-xs py-1 px-2" @click="downloadPDF">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
            <g clip-path="url(#clip0_189_1101)">
              <path
                d="M10.6667 3.3332C10.6667 3.16094 10.5972 2.99414 10.4722 2.87109L7.75278 0.191406C7.62778 0.0683594 7.45833 0 7.28056 0H7.11111V3.5H10.6667V3.3332ZM15.8611 8.42188L13.2028 5.78594C12.9222 5.50977 12.4417 5.70391 12.4417 6.09492V7.875H10.6639V9.625H12.4417V11.4078C12.4417 11.7988 12.9222 11.993 13.2028 11.7168L15.8611 9.07812C16.0444 8.89766 16.0444 8.60234 15.8611 8.42188ZM5.33333 9.1875V8.3125C5.33333 8.07187 5.53333 7.875 5.77778 7.875H10.6667V4.375H6.88889C6.52222 4.375 6.22222 4.07969 6.22222 3.71875V0H0.666667C0.297222 0 0 0.292578 0 0.65625V13.3438C0 13.7074 0.297222 14 0.666667 14H10C10.3694 14 10.6667 13.7074 10.6667 13.3438V9.625H5.77778C5.53333 9.625 5.33333 9.42813 5.33333 9.1875Z"
                fill="#475F4A"
              />
            </g>
            <defs>
              <clipPath id="clip0_189_1101">
                <rect width="16" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="text-xs"> Export to PDF </span>
        </button>
      </div>
    </div>
    <div class="stats-table">
      <table>
        <thead>
          <tr>
            <th>GPA</th>
            <th>SAT</th>
            <th>ACT</th>
            <th>Attendance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="score">{{ gpa }}</td>
            <td class="score">--</td>
            <td class="score">--</td>
            <td class="score">{{ attendance }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="w-full max-w-3xl mx-auto grid grid-cols-2 gap-5 p-5 py-10">
      <div class="card">
        <span class="card-title">GPA</span>
        <span>{{ gpa }}</span>
      </div>
      <div class="card">
        <span class="card-title">Attendance</span>
        <span>{{ attendance }}%</span>
      </div>
    </div> -->
  </div>
</template>
<script>
  import { getAssociatedData } from "../../../AssociatedData.js";

  import { useStudentDemo } from "../../../store/StudentDemoStates.js";

  export default {
    data() {
      return {
        currentLink: null,
        userName: "Student",
        resume: [],
        daName: "",
        attendance: "",
        group: {
          name: "",
          description: "",
        },
        gpa: "",
        sat: "",
        modal: false,
        allGroups: [],
        showGroups: false,
        surveys: [],
        surveyPercentage: "",
        store: null,
      };
    },
    created() {
      this.currentLink = this.$route.path;
    },
    methods: {
      goToSurvey(link) {
        this.$router.push(link);
        this.currentLink = this.$route.path;
      },
      logoutNow() {
        localStorage.clear();
        window.location.href = "/";
      },
      async fetchSurveys() {
        const response = await this.$request("/v1/surveys", "GET");
        this.surveys = await response.data.data.items;
        const assocData = await getAssociatedData();
        const surveysTaken = assocData && assocData.surveys && assocData.surveys.length ? assocData.surveys.length : 0;
        if (surveysTaken > 0 && this.surveys.length) {
          this.surveyPercentage = (surveysTaken / this.surveys.length) * 100;
        }
      },
      async getUserDetails() {
        if (this.store.isStudentDemo) {
          this.attendance = parseFloat(70).toFixed(2);
          this.gpa = parseFloat(3.2).toFixed(2);
        } else {
          const user = JSON.parse(localStorage.getItem("user"));
          const resume = JSON.parse(localStorage.getItem("resume"));
          const edlink = JSON.parse(localStorage.getItem("edlink"));
          this.resume = resume;
          this.daName = user?.first_name;
          this.attendance = parseFloat(edlink.properties.custom.attendance).toFixed(2);
          this.gpa = parseFloat(edlink.properties.custom.gpa).toFixed(2);
          this.currentLink = this.$route.path;
          await this.fetchSurveys();
        }
      },

      getUser() {
        const usrData = localStorage.getItem("user");
        if (usrData) {
          const usr = JSON.parse(usrData);
          this.userName = usr.first_name;
        } else {
        }
      },
    },

    mounted() {
      this.store = useStudentDemo();
      this.getUser();
      this.getUserDetails();
    },
  };
</script>

<style scoped>
  .card {
    @apply w-full h-40 text-3xl flex flex-col items-center justify-center gap-2 text-gray-800 bg-primary/20 rounded-xl uppercase font-semibold;
  }
  .card-title {
    @apply text-base text-gray-600 font-normal;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 14px;
  }
  .single_btn {
    color: #475f4a;
    background: #f0f4f0;
    padding: 5px 20px;
    @apply flex items-center justify-center rounded-full gap-2.5 text-base py-1.5 px-5;
  }
  .stats-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #e0e0e0;
    border-radius: 40px;
    overflow: hidden; /* To apply border radius to the entire table */
  }

  table {
    width: 100%;
    text-align: center;
  }

  th {
    color: #9e9e9e;
    padding: 15px 0;
    font-weight: normal;
  }

  td {
    padding: 15px 0;
  }

  .score {
    color: #259b35;
    font-size: 24px;
  }
</style>
