export const broadcasts = [
  {
    id: 1,
    provider_name: "Asad Khan",
    created_at: "09/08/2023",
    content: "Reminder: Your midterm exams start next week. Please check your schedule and prepare accordingly.",
    provider_email: "asad.khan@university.com",
    path: "/educator/studentDemo/messages",
  },
  {
    id: 2,
    provider_name: "Dr. Maria Smith",
    created_at: "09/10/2023",
    content:
      "New lecture materials for the Biology 101 course have been uploaded. Please review them before our next class.",
    provider_email: "maria.smith@university.com",
    path: "/educator/studentDemo/messages",
  },
  {
    id: 3,
    provider_name: "John Doe",
    created_at: "09/12/2023",
    content:
      "Campus event: Join us for the annual science fair on 09/15. All students are welcome to present their projects.",
    provider_email: "john.doe@university.com",
    path: "/educator/studentDemo/messages",
  },
  {
    id: 4,
    provider_name: "Emily Johnson",
    created_at: "09/13/2023",
    content:
      "Reminder: The deadline to submit your research paper for History 201 is 09/20. No extensions will be granted.",
    provider_email: "emily.johnson@university.com",
    path: "/educator/studentDemo/messages",
  },
  {
    id: 5,
    provider_name: "Prof. Michael Lee",
    created_at: "09/14/2023",
    content: "New assignment: Complete the calculus worksheet and submit it by 09/19.",
    provider_email: "michael.lee@university.com",
    path: "/educator/studentDemo/messages",
  },
  {
    id: 6,
    provider_name: "Career Services",
    created_at: "09/15/2023",
    content:
      "Career workshop: Resume building and interview skills. Join us on 09/20 for valuable insights into job hunting.",
    provider_email: "careers@university.com",
    path: "/educator/studentDemo/messages",
  },
  {
    id: 7,
    provider_name: "Library",
    created_at: "09/16/2023",
    content: "New eBooks available: Check out the latest additions to our digital library collection.",
    provider_email: "library@university.com",
    path: "/educator/studentDemo/messages",
  },
  {
    id: 8,
    provider_name: "Student Affairs",
    created_at: "09/17/2023",
    content:
      "Announcement: Student counseling services are available. Reach out to us for any academic or personal concerns.",
    provider_email: "student.affairs@university.com",
    path: "/educator/studentDemo/messages",
  },
  {
    id: 9,
    provider_name: "Prof. Sarah Brown",
    created_at: "09/18/2023",
    content:
      "Course update: The Economics 301 class on 09/20 has been rescheduled to 09/22 due to unforeseen circumstances.",
    provider_email: "sarah.brown@university.com",
    path: "/educator/studentDemo/messages",
  },
  {
    id: 10,
    provider_name: "IT Department",
    created_at: "09/19/2023",
    content: "Maintenance notice: The online student portal will be down for maintenance on 09/21 from 1 AM to 3 AM.",
    provider_email: "it.support@university.com",
    path: "/educator/studentDemo/messages",
  },
];
