<template>
  <div class="rounded-[50px] p-8 bg-white shadow-3xl space-y-4 mb-6 mt-5">
    <h3 class="text-xl font-medium text-black">Undergraduate Majors</h3>
    <div class="p-4">
      <div class="">
        <div class="text-[15px] p-3 text-[#B4ACAC]">Major</div>
        <ul class="divide-y">
          <li v-for="i in 5" class="font-[500] text-[15px] p-3">{{ 'Major ' + i}}</li>
        </ul>
      </div>
    </div>
  </div>
  
</template>
<script>
export default{
    props:[
        "collegeData"
    ]
}
</script>
<style scoped>
.item{
    @apply w-full flex items-center justify-between px-5 py-4 border-b border-gray-200;
}
.item-heading{
    @apply bg-gray-200;
}
</style>