<template>
  <div class="custom-select" :style="{ width: mainWidth }" v-click-outside="closeSelect">
    <div class="select-box" :style="{ width: ifWidth }" @click="toggleDropdown">
      <span class="mr-4 elipse">{{
        modelValue || (data.length && selectedOption != null ? selectedOption?.label : selectedOption) || placeholder
      }}</span>
      <div class="flex items-center justify-center">
        <div v-if="clearable && selectedOption" v-on:click.stop="clearSelect" class="clear-icon"><plus v-tooltip:right="'clear'" :width="18"/></div>
        <span class="arrow" :class="{ open: isOpen }"></span>
      </div>
    </div>
    <ul v-if="isOpen" class="options scrollable-options border-r-4 p-2">
      <li
        v-if="options.length > 0"
        v-for="option in options"
        :key="option"
        @click="selectOption(option)"
        ref="options"
        class="wrap-ellipsis"
      >
        {{ option }}
      </li>
      <li v-if="data.length" v-for="d in data" :key="d" @click="selectOption(d)" class="wrap-ellipsis">
        {{ d.label }}
      </li>
    </ul>
  </div>
</template>

<script>
  import Plus from '../../icons/plus.vue';

  export default {
    name: "CustomSelect",
    components: {Plus},
    props: {
      options: {
        type: Array,
        default: [],
      },
      data: {
        type: Array,
        default: [],
      },
      placeholder: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        default: "",
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      width: { type: String, default: "" },
    },
    emits: ["update:modelValue", "select"],
    data() {
      return {
        isOpen: false,
        selectedOption: null,
        selectBoxWidth: "auto",
        showTooltip: false,
      };
    },
    computed: {
      ifWidth() {
        if (this.width) {
          return this.width;
        } else if (this.width === "full") {
          return "100%";
        } else {
          return this.selectBoxWidth;
        }
      },
      mainWidth() {
        if (this.width === "full") {
          return "100%";
        } else {
          return "auto";
        }
      },
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      closeSelect() {
        this.isOpen = false;
      },
      selectOption(option) {
        this.selectedOption = option;
        this.isOpen = false;
        this.$emit("select", option);
        this.$emit("update:modelValue", option);
      },
      updateSelectBoxWidth() {
        this.$nextTick(() => {
          const optionElements = this.$refs.options;
          let maxWidth = 0;

          if (optionElements?.length) {
            optionElements.forEach((el) => {
              const width = el.scrollWidth;
              if (width > maxWidth) {
                maxWidth = width;
              }
            });
          }

          this.selectBoxWidth = `${maxWidth + 20}px`;
        });
      },
      clearSelect() {
        this.selectOption(null);
      },
      reset(e) {
        this.selectedOption = null;
      },
    },
    mounted() {
      this.updateSelectBoxWidth();
    },
    watch: {
      options() {
        this.updateSelectBoxWidth();
      },
      modelValue() {
        this.selectedOption = this.modelValue;
      },
    },
  };
</script>

<style scoped>
  .custom-select {
    position: relative;
    display: inline-block;
  }

  .scrollable-options {
    max-height: 500px;
    /* Adjust this value as needed */
    overflow-y: auto;
    border: 1px solid #ccc;
    /* Optional: add a border for better visibility */
    margin: 0;
    /* Remove default margin */
    list-style: none;
    /* Remove default list styling */
  }

  .select-box {
    min-width: 170px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(71, 95, 74, 0.151);
    transition: box-shadow 0.3s ease;
    cursor: pointer;

    .elipse {
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
      color: #28a745;
    }
  }

  .arrow {
    border: solid #28a745;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }

  .clear-icon{
    margin-right: 10px;
    & > svg {
      transform: rotate(45deg);
    }
  }

  .arrow.open {
    transform: rotate(-135deg);
  }

  .options {
    list-style: none;
    margin: 0;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    z-index: 1;
  }

  .options li {
    padding: 10px;
    cursor: pointer;
  }

  .options li:hover {
    background-color: #f0f0f0;
  }

  .wrap-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .custom-tooltip {
    position: relative;
  }

  .custom-tooltip .tooltip-text {
    min-width: 120px;
    font-size: 14px;
    line-height: 20px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
  }
</style>
