<template>
  <div class="accordion">
    <div
      v-for="(item, index) in data"
      :key="index"
      class="info_one"
    >
      <div
        class="main"
        @click="setCurrent(index)"
      >
        <h1 class="my_title">
          {{ item.title }}
        </h1>
        <svg
          v-if="current !== index"
          xmlns="http://www.w3.org/2000/svg"
          width="47"
          height="18"
          viewBox="0 0 47 18"
          fill="none"
        >
          <g clip-path="url(#clip0_781_2405)">
            <path
              d="M22.7196 15.6469L12.0712 4.60315C11.6397 4.16252 11.6397 3.45002 12.0712 3.0094L12.7229 2.34377C13.1544 1.90315 13.8521 1.90315 14.2835 2.34377L23.4999 11.9344L32.7163 2.35315C33.1478 1.91252 33.8454 1.91252 34.2769 2.35315L34.9286 3.01877C35.3601 3.4594 35.3601 4.1719 34.9286 4.61252L24.2802 15.6563C23.8487 16.0875 23.1511 16.0875 22.7196 15.6469Z"
              fill="#259B35"
            />
          </g>
          <defs>
            <clipPath id="clip0_781_2405">
              <rect
                width="18"
                height="47"
                fill="white"
                transform="matrix(0 1 -1 0 47 0)"
              />
            </clipPath>
          </defs>
        </svg>

        <svg
          v-else-if="current === index"
          xmlns="http://www.w3.org/2000/svg"
          width="47"
          height="18"
          viewBox="0 0 47 18"
          fill="none"
          class="rotate"
        >
          <g clip-path="url(#clip0_781_2405)">
            <path
              d="M22.7196 15.6469L12.0712 4.60315C11.6397 4.16252 11.6397 3.45002 12.0712 3.0094L12.7229 2.34377C13.1544 1.90315 13.8521 1.90315 14.2835 2.34377L23.4999 11.9344L32.7163 2.35315C33.1478 1.91252 33.8454 1.91252 34.2769 2.35315L34.9286 3.01877C35.3601 3.4594 35.3601 4.1719 34.9286 4.61252L24.2802 15.6563C23.8487 16.0875 23.1511 16.0875 22.7196 15.6469Z"
              fill="#259B35"
            />
          </g>
          <defs>
            <clipPath id="clip0_781_2405">
              <rect
                width="18"
                height="47"
                fill="white"
                transform="matrix(0 1 -1 0 47 0)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div :class="[current === index ? 'block' : 'hidden', 'decription']">
        <p>
          {{ item.description }}
        </p>
        <!-- Information -->
        <div v-if="item.title === 'Personal Info'" class="my-4">
          <div class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Goals
            </p>
            <p class="text-black text-[15px] font-[400px]">
              {{ assocData && assocData?.goal ? assocData?.goal : 'None' }}
            </p>
          </div>
          <div class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Future Plans
            </p>
            <p class="text-black text-[15px] font-[400px]">
              {{ assocData && assocData?.future_plans ? assocData?.future_plans : 'None' }}
            </p>
          </div>
          <div class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              School
            </p>
            <p class="text-black text-[15px] font-[400px]">
              {{ assocData && assocData?.schools ? assocData?.schools : 'None' }}
            </p>
          </div>
          <div class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
             Graduation Year
            </p>
            <p class="text-black text-[15px] font-[400px]">
               {{ assocData && assocData?.gradYear ? assocData?.gradYear : 'None' }}
            </p>
          </div>
          <div class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Show Email Address
            </p>

            <label
              class="relative my-3 inline-flex items-center cursor-pointer"
            >
              <input
                v-model="showEmail"
                type="checkbox"
                class="sr-only peer"
                @change="showMeCheck('email')"
              >
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#259B35] dark:peer-focus:ring-[#259B35] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#259B35]"
              />
            </label>
          </div>
          <div class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Show Phone Number
            </p>
            <label
              class="relative my-3 inline-flex items-center cursor-pointer"
            >
              <input
                v-model="showPhone"
                type="checkbox"
                class="sr-only peer"
                @change="showMeCheck('phone')"
              >
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#259B35] dark:peer-focus:ring-[#259B35] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#259B35]"
              />
            </label>
          </div>
          <div v-if="resume && resume.length && resume?.linkedin" class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Website
            </p>
            <p class="text-black text-[15px] font-[400px]">
              {{ resume && 'linkedin' in resume ? resume.linkedin : 'None' }}
            </p>
          </div>
          <div v-if="resume && resume.length && resume.instagram" class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Instagram
            </p>
            <p class="text-black text-[15px] font-[400px]">
              {{ resume && resume[0]?.instagram ? resum[0].instagram : 'None' }}
            </p>
          </div>
          <div v-if="resume && resume.length && resume?.twitter" class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Twitter
            </p>
            <p class="text-black text-[15px] font-[400px]" />{{ resume && resume[0]?.twitter ? resume[0]?.twitter : 'None' }}
          </div>
        </div>
 
        <!-- Academic -->
        <div v-if="index === 2" class="my-4">
          {{ resume && resume[0] ? resume[0]?.academic_titles : '-' }}
          <div class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Show SAT
            </p>

            <label
              class="relative my-3 inline-flex items-center cursor-pointer"
            >
              <input
                v-model="showSAT"
                type="checkbox" 
                class="sr-only peer"
                @change="showMeCheck('sat')"
              >
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#259B35] dark:peer-focus:ring-[#259B35] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#259B35]"
              />
            </label>
          </div>
          <div class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Show ACT
            </p>

            <label
              class="relative my-3 inline-flex items-center cursor-pointer"
            >
              <input
                v-model="showACT"
                type="checkbox"
                class="sr-only peer"
                @change="showMeCheck('act')"
              >
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#259B35] dark:peer-focus:ring-[#259B35] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#259B35]"
              />
            </label>
          </div>
          <div class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Show GPA
            </p>

            <label
              class="relative my-3 inline-flex items-center cursor-pointer"
            >
              <input
                v-model="showGPA"
                type="checkbox"
                class="sr-only peer"
                @change="showMeCheck('gpa')"
              >
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#259B35] dark:peer-focus:ring-[#259B35] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#259B35]"
              />
            </label>
          </div>
          <div class="my-3">
            <p class="text-[#A5AC9D] text-[15px] font-[500px]">
              Show ASVAB
            </p>

            <label
              class="relative my-3 inline-flex items-center cursor-pointer"
            >
              <input
                v-model="showASVAB"
                type="checkbox"
                class="sr-only peer"
                @change="showMeCheck('asvab')"
              >
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#259B35] dark:peer-focus:ring-[#259B35] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#259B35]"
              />
            </label>
          </div>

          <div>
            <label class="text-[#A5AC9D] text-[15px] font-[500px]">Select Academics</label>
            <multiselect
              v-model="value"
              :options="options"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Pick some"
              label="name"
              track-by="name"
              :preselect-first="true"
            />
          </div>

        </div>

        <!-- Athletics -->
        <div
          v-if="index === 3"
          class="my-4"
        >
          <div v-for="athletic in allAthletics" :key="athletic" class="my-3">
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Sport Title
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ athletic.title }}
              </p>
            </div>
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Description/ Position
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ athletic.description }}
              </p>
            </div>
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Start Date
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ athletic.date }}
              </p>
            </div>
          </div>
          <div
            class="flex items-center gap-2 cursor-pointer"
            @click="showModal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.7805 0.83733 6.79048C0.00476615 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9969 8.08359 20.8369 5.28753 18.7747 3.22531C16.7125 1.16309 13.9164 0.00315432 11 0ZM14.6667 11.9167H11.9167V14.6667C11.9167 14.9098 11.8201 15.1429 11.6482 15.3148C11.4763 15.4868 11.2431 15.5833 11 15.5833C10.7569 15.5833 10.5237 15.4868 10.3518 15.3148C10.1799 15.1429 10.0833 14.9098 10.0833 14.6667V11.9167H7.33334C7.09022 11.9167 6.85707 11.8201 6.68516 11.6482C6.51325 11.4763 6.41667 11.2431 6.41667 11C6.41667 10.7569 6.51325 10.5237 6.68516 10.3518C6.85707 10.1799 7.09022 10.0833 7.33334 10.0833H10.0833V7.33333C10.0833 7.09022 10.1799 6.85706 10.3518 6.68515C10.5237 6.51324 10.7569 6.41667 11 6.41667C11.2431 6.41667 11.4763 6.51324 11.6482 6.68515C11.8201 6.85706 11.9167 7.09022 11.9167 7.33333V10.0833H14.6667C14.9098 10.0833 15.1429 10.1799 15.3149 10.3518C15.4868 10.5237 15.5833 10.7569 15.5833 11C15.5833 11.2431 15.4868 11.4763 15.3149 11.6482C15.1429 11.8201 14.9098 11.9167 14.6667 11.9167Z"
                fill="#259B35"
              />
            </svg>
            <span class="text-[#259B35]">Add New</span>
          </div>
        </div>

        <!-- Club and Activities -->
        <div
          v-if="index === 4"
          class="my-4"
        >
          <div
            v-for="club in clubsAndActivities"
            :key="club"
            class="my-3"
          >
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Club Title
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ club.title }}
              </p>
            </div>
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Description/ Position
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ club.description }}
              </p>
            </div>
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Start Date
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ club.date}}
              </p>
            </div>
          </div>
          <div
            class="flex items-center gap-2 cursor-pointer"
            @click="showModal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.7805 0.83733 6.79048C0.00476615 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9969 8.08359 20.8369 5.28753 18.7747 3.22531C16.7125 1.16309 13.9164 0.00315432 11 0ZM14.6667 11.9167H11.9167V14.6667C11.9167 14.9098 11.8201 15.1429 11.6482 15.3148C11.4763 15.4868 11.2431 15.5833 11 15.5833C10.7569 15.5833 10.5237 15.4868 10.3518 15.3148C10.1799 15.1429 10.0833 14.9098 10.0833 14.6667V11.9167H7.33334C7.09022 11.9167 6.85707 11.8201 6.68516 11.6482C6.51325 11.4763 6.41667 11.2431 6.41667 11C6.41667 10.7569 6.51325 10.5237 6.68516 10.3518C6.85707 10.1799 7.09022 10.0833 7.33334 10.0833H10.0833V7.33333C10.0833 7.09022 10.1799 6.85706 10.3518 6.68515C10.5237 6.51324 10.7569 6.41667 11 6.41667C11.2431 6.41667 11.4763 6.51324 11.6482 6.68515C11.8201 6.85706 11.9167 7.09022 11.9167 7.33333V10.0833H14.6667C14.9098 10.0833 15.1429 10.1799 15.3149 10.3518C15.4868 10.5237 15.5833 10.7569 15.5833 11C15.5833 11.2431 15.4868 11.4763 15.3149 11.6482C15.1429 11.8201 14.9098 11.9167 14.6667 11.9167Z"
                fill="#259B35"
              />
            </svg>
            <span class="text-[#259B35]">Add New</span>
          </div>
        </div>


        <!-- Experience -->
        <div
          v-if="index === 5"
          class="my-4"
        >
          <div
            v-for="experience in allExperience"
            :key="experience"
            class="my-3"
          >
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                experience Title
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ experience.title}}
              </p>
            </div>
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Description/ Position
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ experience.description}}
              </p>
            </div>
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Start Date
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ experience.date }}
              </p>
            </div>
          </div>
          <div
            class="flex items-center gap-2 cursor-pointer"
            @click="showModal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.7805 0.83733 6.79048C0.00476615 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9969 8.08359 20.8369 5.28753 18.7747 3.22531C16.7125 1.16309 13.9164 0.00315432 11 0ZM14.6667 11.9167H11.9167V14.6667C11.9167 14.9098 11.8201 15.1429 11.6482 15.3148C11.4763 15.4868 11.2431 15.5833 11 15.5833C10.7569 15.5833 10.5237 15.4868 10.3518 15.3148C10.1799 15.1429 10.0833 14.9098 10.0833 14.6667V11.9167H7.33334C7.09022 11.9167 6.85707 11.8201 6.68516 11.6482C6.51325 11.4763 6.41667 11.2431 6.41667 11C6.41667 10.7569 6.51325 10.5237 6.68516 10.3518C6.85707 10.1799 7.09022 10.0833 7.33334 10.0833H10.0833V7.33333C10.0833 7.09022 10.1799 6.85706 10.3518 6.68515C10.5237 6.51324 10.7569 6.41667 11 6.41667C11.2431 6.41667 11.4763 6.51324 11.6482 6.68515C11.8201 6.85706 11.9167 7.09022 11.9167 7.33333V10.0833H14.6667C14.9098 10.0833 15.1429 10.1799 15.3149 10.3518C15.4868 10.5237 15.5833 10.7569 15.5833 11C15.5833 11.2431 15.4868 11.4763 15.3149 11.6482C15.1429 11.8201 14.9098 11.9167 14.6667 11.9167Z"
                fill="#259B35"
              />
            </svg>
            <span class="text-[#259B35]">Add New</span>
          </div>
        </div>

        <!-- Accomplishment -->
        <div
          v-if="index === 6"
          class="my-4"
        >
          <div
            v-for="accomplishment in allAccomplishments"
            :key="accomplishment"
            class="my-3"
          >
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Accomplishment Title
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ accomplishment.title }}
              </p>
            </div>
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Description/ Position
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ accomplishment.description }}
              </p>
            </div>
            <div class="my-3">
              <p class="text-[#A5AC9D] text-[15px] font-[500px]">
                Start Date
              </p>
              <p class="text-black text-[15px] font-[400px]">
                {{ accomplishment.date }}
              </p>
            </div>
          </div>
          <div
            class="flex items-center gap-2 cursor-pointer"
            @click="showModal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.7805 0.83733 6.79048C0.00476615 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9969 8.08359 20.8369 5.28753 18.7747 3.22531C16.7125 1.16309 13.9164 0.00315432 11 0ZM14.6667 11.9167H11.9167V14.6667C11.9167 14.9098 11.8201 15.1429 11.6482 15.3148C11.4763 15.4868 11.2431 15.5833 11 15.5833C10.7569 15.5833 10.5237 15.4868 10.3518 15.3148C10.1799 15.1429 10.0833 14.9098 10.0833 14.6667V11.9167H7.33334C7.09022 11.9167 6.85707 11.8201 6.68516 11.6482C6.51325 11.4763 6.41667 11.2431 6.41667 11C6.41667 10.7569 6.51325 10.5237 6.68516 10.3518C6.85707 10.1799 7.09022 10.0833 7.33334 10.0833H10.0833V7.33333C10.0833 7.09022 10.1799 6.85706 10.3518 6.68515C10.5237 6.51324 10.7569 6.41667 11 6.41667C11.2431 6.41667 11.4763 6.51324 11.6482 6.68515C11.8201 6.85706 11.9167 7.09022 11.9167 7.33333V10.0833H14.6667C14.9098 10.0833 15.1429 10.1799 15.3149 10.3518C15.4868 10.5237 15.5833 10.7569 15.5833 11C15.5833 11.2431 15.4868 11.4763 15.3149 11.6482C15.1429 11.8201 14.9098 11.9167 14.6667 11.9167Z"
                fill="#259B35"
              />
            </svg>
            <span class="text-[#259B35]">Add New</span>
          </div>
        </div>
      </div>
    </div>
    <!--Modal-->
    <div
      v-if="showAthleticsModal"
      class="h-screen flex items-center justify-center w-full bg-gray-800/40 top-0 left-0 fixed"
    >
      <div
        v-if="showAthleticsModal"
        class="w-96 rounded-2xl h-64 bg-white inset-1/2 transform flex flex-col items-center py-4 top-[40%] px-4"
      >
        <div
          v-if="current === 3"
          class="w-full"
        >
          <h1 class="inter mt-4 text-[20px] font-medium">
            Add Athletics
          </h1>
          <div class="w-full">
            <div
              class="w-full mt-4 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="athletics.title"
                placeholder="Sport Title"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
            <div
              class="w-full mt-4 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="athletics.description"
                placeholder="Description"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
            <div
              class="w-full mt-4 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="athletics.date"
                type="date"
                placeholder="Start Date"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
          </div>

          <div class="flex gap-3">
            <button
              class="px-5 mx-auto mt-4 py-2 bg-gray-400 text-white inter rounded-full"
              @click="showModal"
            >
              Close
            </button>
            <button
              class="px-5 mx-auto mt-4 py-2 bg-[#259B35] text-white inter rounded-full"
              @click="saveAthleticForm"
            >
              Save
            </button>
          </div>
        </div>

        <div
          v-else-if="current === 4"
          class="w-full"
        >
          <h1 class="inter mt-4 text-[20px] font-medium">
            Add Club
          </h1>
          <div class="w-full">
            <div
              class="w-full mt-4 h-6 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="club.title"
                placeholder="Club Title"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
            <div
              class="w-full mt-4 h-6 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="club.description"
                placeholder="Description"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
            <div
              class="w-full mt-4 h-6 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="club.date"
                type="date"
                placeholder="Start Date"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
          </div>

          <div class="flex gap-3">
            <button
              class="px-5 mx-auto mt-4 py-2 bg-gray-400 text-white inter rounded-full"
              @click="showModal"
            >
              Close
            </button>
            <button
              class="px-5 mx-auto mt-4 py-2 bg-[#259B35] text-white inter rounded-full"
              @click="saveClubForm"
            >
              Save
            </button>
          </div>
        </div>

        <div
          v-else-if="current === 5"
          class="w-full"
        >
          <h1 class="inter mt-4 text-[20px] font-medium">
            Add Experience
          </h1>
          <div class="w-full">
            <div
              class="w-full mt-4 h-6 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="experience.title"
                placeholder="experience Title"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
            <div
              class="w-full mt-4 h-6 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="experience.description"
                placeholder="Description"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
            <div
              class="w-full mt-4 h-6 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="experience.date"
                type="date"
                placeholder="Start Date"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
          </div>

          <div class="flex gap-3">
            <button
              class="px-5 mx-auto mt-4 py-2 bg-gray-400 text-white inter rounded-full"
              @click="showModal"
            >
              Close
            </button>
            <button
              class="px-5 mx-auto mt-4 py-2 bg-[#259B35] text-white inter rounded-full"
              @click="saveExperienceForm"
            >
              Save
            </button>
          </div>
        </div>
        
        <div
          v-else-if="current === 6"
          class="w-full"
        >
          <h1 class="inter mt-4 text-[20px] font-medium">
            Add Accomplishments
          </h1>
          <div class="w-full">
            <div
              class="w-full mt-4 h-6 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="accomplishmen.title"
                placeholder="experience Title"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
            <div
              class="w-full mt-4 h-6 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="accomplishmen.description"
                placeholder="Description"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
            <div
              class="w-full mt-4 h-6 flex items-center mx-auto rounded-full px-4 h-8 bg-[#259B35]/10"
            >
              <input
                v-model="accomplishmen.date"
                type="date"
                placeholder="Start Date"
                class="w-full inter text-[#259B35] pl-2 h-full bg-transparent"
              >
            </div>
          </div>

          <div class="flex gap-3">
            <button
              class="px-5 mx-auto mt-4 py-2 bg-gray-400 text-white inter rounded-full"
              @click="showModal"
            >
              Close
            </button>
            <button
              class="px-5 mx-auto mt-4 py-2 bg-[#259B35] text-white inter rounded-full"
              @click="saveAccomplishmentForm"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { ref, computed, watch,  onMounted } from 'vue';
import {useAccordion} from '../store/AccordionStates';
import Multiselect from "vue-multiselect";

export default {
  name: "ScholarPathAccordion",
  props:{
    assocData: Object,

    clubsAndActivities:{
      type:Array,
    },
    allExperience:{
      type:Array,
    },
    allAccomplishments:{
      type:Array,
    },
    allAthletics:{
      type:Array,
    }
  },
  emits: ['update-clubs-and-activities','update-experience','update-athletics','update-accomplishments','update-academics'],
  setup(props, { emit }) {

    const store = useAccordion();
    const showEmail = ref(false);
    const showPhone = ref(false);
    const showGPA = ref(false);
    const showACT = ref(false);
    const showSAT = ref(false);
    const showASVAB = ref(false); 
    const value = ref([]);
    const options = ref([
      { id: "1", name: "Acting" },
      { id: "2", name: "Film" },
      { id: "3", name: "Literature" },
      { id: "4", name: "Musical Theatre" },
      { id: "5", name: "Performing Arts" },
      { id: "6", name: "Singing" },
      { id: "7", name: "Theatre/Theatre Arts" },
      { id: "8", name: "Vocal Performance" },
    ]);
    
    const resume = ref([]);
  
    const show = ref(true);
    const showAthleticsModal = ref(false);
    const current = ref(null);

    const athletics = ref({
      title: "",
      description: "",
      date: "",
    });
    const club = ref({
      title: "",
      description: "",
      date: "",
    });
    const experience = ref({
      title: "",
      description: "",
      date: "",
    });
    const accomplishmen = ref({
      title: "",
      description: "",
      date: "",
    });

    const data = ref([
      {
        title: "Styles",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium ducimus adipisci ullam, eos aliquam animi fuga voluptas consequuntur, rem nisi quidem impedit in. Eum quos quisquam similique maiores vero perspiciatis?",
      },
      {
        title: "Personal Info",
        desc: "",
      },
      {
        title: "Academics",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium ducimus adipisci ullam, eos aliquam animi fuga voluptas consequuntur, rem nisi quidem impedit in. Eum quos quisquam similique maiores vero perspiciatis?",
      },
      {
        title: "Athletics",
        desc: "",
      },
      {
        title: "Clubs & Activities",
        desc: "",
      },
      {
        title: "Experience",
        desc: "",
      },
      {
        title: "Accomplishments",
        desc: "",
      },
    ]);

    const showEmailComputed = computed(() => {
      return resume.value && resume.value.showEmail === 1;
    });

    const showPhoneComputed = computed(() => {
      return resume.value && resume.value.showPhone === 1;
    });

    // Methods
    const saveForm = (e, formData, allDataProp, resetData, emitEvent) => {
      e.preventDefault();
      showAthleticsModal.value = !showAthleticsModal.value;
      const newData = [...props[allDataProp], { ...formData.value }];
      emit(emitEvent, newData);
      // Clear the form fields
      formData.value = resetData;
    };

    const saveAthleticForm = (e) => {
      saveForm(
        e,
        athletics,
        'allAthletics',
        { title: "", description: "", date: "" },
        'update-athletics'
      );
    };

    const saveClubForm = (e) => {
      saveForm(
        e,
        club,
        'clubsAndActivities',
        { title: "", description: "", date: "" },
        'update-clubs-and-activities'
      );
    };

    const saveExperienceForm = (e) => {
      saveForm(
        e,
        experience,
        'allExperience',
        { title: "", description: "", date: "" },
        'update-experience'
      );
    };

    const saveAccomplishmentForm = (e) => {
      saveForm(
        e,
        accomplishmen,
        'allAccomplishments',
        { title: "", description: "", accomplishmentStartDate: "" },
        'update-accomplishments'
      );
    };

    const showModal = (e) => {
      showAthleticsModal.value = !showAthleticsModal.value;
    };

    const showMeCheck = (item) => {
      if(item === 'sat'){
        emit('update-academics', { type: 'showSAT', value: showSAT.value });
        store.setSAT(showSAT.value);
      }else if(item === 'act'){
        emit('update-academics', { type: 'showACT', value: showACT.value });
        store.setACT(showACT.value);
      }else if(item === 'gpa'){
        emit('update-academics', { type: 'showGPA', value: showGPA.value });
        store.setGPA(showGPA.value);
      }else if(item === 'asvab'){ 
        emit('update-academics', { type: 'showASVAB', value: showASVAB.value });
        store.setASVAB(showASVAB.value);
      }else if(item === 'email'){ 
        emit('update-academics', { type: 'showEmail', value: showEmail.value });
        store.setEmail(showEmail.value);
      }else if(item === 'phone'){ 
        emit('update-academics', { type: 'showPhone', value: showPhone.value });
        store.setPhone(showPhone.value);
      }
    };

    const setCurrent = (index) => {
      if (index === current.value) {
        current.value = null;
      } else {
        current.value = index;
      }
    };

    watch(() => store.isEmail, (newValue) => { showEmail.value = newValue; });
    watch(() => store.isPhone, (newValue) => { showPhone.value = newValue; });
    watch(() => store.isGPA, (newValue) => { showGPA.value = newValue; });
    watch(() => store.isACT, (newValue) => { showACT.value = newValue; });
    watch(() => store.isSAT, (newValue) => { showSAT.value = newValue; });
    watch(() => store.isASVAB, (newValue) => { showASVAB.value = newValue; });


    return {
      value,
      options,
      showASVAB,
      showEmailComputed,
      showPhoneComputed,
      showGPA,
      showACT,
      showSAT,
      show,
      showAthleticsModal,
      current,
      athletics,
      club,
      experience,
      accomplishmen,
      data,
      saveAthleticForm,
      saveClubForm,
      saveExperienceForm,
      saveAccomplishmentForm,
      showModal,
      showMeCheck,
      setCurrent,
      Multiselect,
      showEmail,
      showPhone,
    };
  },
};
</script>




<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.main {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  height: 50px;
  cursor: pointer;
  padding: 0px 10px;
  justify-content: space-between;
}
.show {
  display: flex;
}

.hidden {
  display: none;
}

.info_one {
  width: 100%;
  display: flex;
  min-height: 50px;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 1px solid rgba(37, 155, 53, 0.13);
}
.accordion {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.main .my_title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.decription {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.rotate {
  transform: rotate(180deg);
}
.inter {
  font-family: Inter;
}
</style>
