<template>
    <div class="w-full">
        <div class="mb-10">
            <h3 class="font-medium text-lg mb-5">MEAN WAGES</h3>
            <div class="w-full h-min grid grid-cols-2 gap-5 px-5 mb-5">
                <div class="enr-card">
                    <h4 class="enr-title">National</h4>
                    <div class="w-full grid grid-cols-2 gap-2.5">
                        <div class="flex flex-col items-center">
                            <h3 class="mb-2 font-medium">Hourly</h3>
                            <p class="enr-text"><span class="dollar-sign">$</span>{{ numberWithCommas(job["H_MEAN"]) }}</p>
                        </div>
                        <div class="flex flex-col items-center">
                            <h3 class="mb-2 font-medium">Annually</h3>
                            <p class="enr-text"><span class="dollar-sign">$</span>{{ numberWithCommas(job["A_MEAN"]) }}</p>
                        </div>
                    </div>
                </div>
                <div class="enr-card">
                    <h4 class="enr-title">Missouri</h4>
                    <div class="w-full grid grid-cols-2 gap-2.5">
                        <div class="flex flex-col items-center">
                            <h3 class="mb-2 font-medium">Hourly</h3>
                            <p class="enr-text">{{ numberWithCommas(0) }}</p>
                        </div>
                        <div class="flex flex-col items-center">
                            <h3 class="mb-2 font-medium">Annually</h3>
                            <p class="enr-text">{{ numberWithCommas(0) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <h3 class="font-medium text-lg mb-5">TOTAL EMPLOYMENT</h3>
            <div class="w-full h-min grid grid-cols-2 gap-5 px-5 mb-5">
                <div class="enr-card">
                    <h4 class="enr-title">National</h4>
                    <p class="enr-text">{{ job["TOT_EMP"] }}</p>
                </div>
                <div class="enr-card">
                    <h4 class="enr-title">Missouri</h4>
                    <p class="enr-text">{{ numberWithCommas(0) }}</p>
                </div>
            </div>
        </div>
        <div>
            <h3 class="font-medium text-lg mb-5">Projected Job Openings</h3>
            <div class="w-full h-min grid grid-cols-2 gap-5 px-5 mb-5">
                <div class="enr-card">
                    <h4 class="enr-title">National</h4>
                    <p class="enr-text">{{ numberWithCommas(0) }}</p>
                </div>
                <div class="enr-card">
                    <h4 class="enr-title">Missouri</h4>
                    <p class="enr-text">{{ numberWithCommas(0) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LabourStat from "../../assets/js/labor_stats/BureauLaborStatistics.json"
export default{
    props:["OnetCode"],
    computed:{
        job(){
            return LabourStat.find(el => this.OnetCode.includes(el["OCC_CODE"]))
        }
    },
    methods:{
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>
<style scoped>
.enr-card{
  @apply w-full h-40 max-h-60 bg-white flex flex-col items-center justify-center shadow-normal rounded-md  relative;
}
.enr-title{
  @apply font-medium absolute left-5 top-4 text-gray-500 w-full ;
}
.enr-text{
  @apply font-bold text-2xl text-black flex items-center gap-1
}
.dollar-sign{
    @apply font-normal text-xl
}
</style>