<template>
    <div class="w-full border border-gray-200 p-5 rounded-2xl">
        <div class="w-full flex items-center justify-between">
            <div class="flex gap-3">
                <router-link :to="`/portfolio/${post.id}`">
                  <img :src="post.hero_img" alt="" class="w-14 h-14 rounded-full" v-if="post.hero_img">
                  <img src="../assets/images/user.png" alt="" class="w-14 h-14 rounded-full" v-else>
                </router-link>
                <div>
                    <h3 class="text-lg">
                        {{ post.provider_name || 'Provider Name' }}
                    </h3>
                    <p class="text-[#a1a1a1] text-xs" @click="goToProviderPage">Posted {{ moment(post.created_at).fromNow() }}</p>
                </div>
            </div>
            <div class="flex items-center gap-5">
                <router-link :to="getPath()" @click.native="setProviderData(post)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    >
                    <path
                        d="M22.5643 8.57109C22.7395 8.43184 23 8.56211 23 8.78223V17.9688C23 19.1592 22.0342 20.125 20.8438 20.125H2.15625C0.96582 20.125 0 19.1592 0 17.9688V8.78672C0 8.56211 0.256055 8.43633 0.435742 8.57559C1.44199 9.35723 2.77617 10.35 7.3582 13.6787C8.30606 14.3705 9.90527 15.826 11.5 15.817C13.1037 15.8305 14.7344 14.3436 15.6463 13.6787C20.2283 10.35 21.558 9.35273 22.5643 8.57109ZM11.5 14.375C12.5422 14.393 14.0426 13.0633 14.7973 12.5152C20.7584 8.18926 21.2121 7.81191 22.5867 6.73379C22.8473 6.53164 23 6.21719 23 5.88477V5.03125C23 3.84082 22.0342 2.875 20.8438 2.875H2.15625C0.96582 2.875 0 3.84082 0 5.03125V5.88477C0 6.21719 0.152734 6.52715 0.413281 6.73379C1.78789 7.80742 2.2416 8.18926 8.20273 12.5152C8.95742 13.0633 10.4578 14.393 11.5 14.375Z"
                        fill="#259B35"
                    />
                  </svg>
                </router-link>
                <button @click="$emit('save', post.id)">
                    <img src="../assets/images/heart-filled.png" alt="heart filled" class="w-5" v-if="saved">
                    <img src="../assets/images/heart-outline.png" alt="heart outline" class="w-5" v-else>
                </button>
            </div>
        </div>
        <div class="py-5"  v-html="post.content">
        </div>
    </div>
</template>
<script>
import { toRefs } from "vue";
import { mutations } from '../store';
import moment from 'moment';
export default {
  props: {
    post: Object,
    saved: Boolean
  },
  data() {
    return {
      moment: moment
    };
  },
  emits: ['save'],
  setup(props, { emit }) {
    const { post } = toRefs(props);

    const emitSave = (id) => {
      emit('save', id);
    };

    const getTimeDiffFromNow=(d)=>{
      const date = new Date(d);
      const today = new Date();
      const millisecond = today - date;
      const second = millisecond/1000;
      const minutes = second/60;
      const hours = minutes/60;
      const days = hours/24;
      const month = days/30;
      const year = month/12;

      if(year && year>0){
          return Math.floor(year)+' years ago';
      }else if(month && month>0){
          return Math.floor(month)+' months ago';
      }else if(days && days>0){
          return Math.floor(days)+' days ago';
      }else if(hours && hours>0){
          return Math.floor(hours)+' hours ago';
      }else if(minutes && minutes>0){
          return Math.floor(minutes)+' minutes ago';
      }else if(second && second>0){
          return Math.floor(second)+' seconds ago';
      }else{
          return ''
      }
    };

    return {
      post,
      emitSave,
      getTimeDiffFromNow
    };
  },
  methods: {
    setProviderData(post) {
      mutations.setProviderData({
        providerId: post.provider_id,
        providerName: post.provider_name,
        providerEmail: post.provider_email,
        userId: post.user_id,
        avatar: post.hero_img
      });
    },
    getPath() {
      return this.post?.path ? this.post?.path : '/messages';
    },
  }
}




</script>
