<template>
  <div class="career-profile-frame">
    <div class="frame-2357">
      <router-link to="/careersDefault">
        <div class="frame-2354-1">
          <img
            class="h-8"
            src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/angle-right-1-2.svg"
            alt="angle-right 1"
          />
          <div class="career-profile">{{ careerProfile }}</div>
        </div>
      </router-link>
      <div class="frame-2356">
        <div class="radar-chart inter-light-black-8px">
          <div class="flex-col-10">
            <div class="conventional">{{ conventional }}</div>
            <div class="enterprising">{{ enterprising }}</div>
          </div>
          <div class="flex-col-11">
            <div class="realistic">{{ realistic }}</div>
            <div class="overlap-HomeFeedStudentDefaultgroup1-1">
              <div class="overlap-group-6">
                <img
                  class="polygon-4"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-4.svg"
                  alt="Polygon 4"
                />
                <img
                  class="polygon-5"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-5.svg"
                  alt="Polygon 5"
                />
                <img
                  class="polygon-6"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-6.svg"
                  alt="Polygon 6"
                />
                <img
                  class="polygon-7"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-7.svg"
                  alt="Polygon 7"
                />
                <img
                  class="polygon-8"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-8.svg"
                  alt="Polygon 8"
                />
                <img
                  class="vector-23"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-23.svg"
                  alt="Vector 23"
                />
                <img
                  class="vector-24"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-24.svg"
                  alt="Vector 24"
                />
                <img
                  class="vector-25"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-25.svg"
                  alt="Vector 25"
                />
                <img
                  class="vector-26"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-26.svg"
                  alt="Vector 26"
                />
                <img
                  class="vector-27"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-27.svg"
                  alt="Vector 27"
                />
                <img
                  class="vector-28"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-28.svg"
                  alt="Vector 28"
                />
                <img
                  class="vector-31"
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-31.svg"
                  alt="Vector 31"
                />
                <div class="investigative">{{ investigative }}</div>
                <div class="ellipse-40"></div>
                <div class="ellipse-44"></div>
                <div class="ellipse-45"></div>
                <div class="ellipse-46"></div>
                <div class="ellipse-47"></div>
                <div class="number valign-text-middle inter-light-white-8px">
                  {{ number1 }}
                </div>
                <div class="number-1 valign-text-middle inter-light-white-8px">
                  {{ number2 }}
                </div>
                <div class="number-2 valign-text-middle inter-light-white-8px">
                  {{ number3 }}
                </div>
                <div class="number-3 valign-text-middle inter-light-white-8px">
                  {{ number4 }}
                </div>
                <div class="number-4 valign-text-middle inter-light-white-8px">
                  {{ number5 }}
                </div>
              </div>
              <div class="artistic">{{ artistic }}</div>
            </div>
            <div class="social">{{ social }}</div>
          </div>
        </div>
        <div class="frame-2346">
          <div class="group-2346">
            <div class="flex-row-10 mb-4" v-if="isInputTrue === false">
              <div class="suggested-careers inter-normal-black-20px">
                {{ suggestedCareers }}
              </div>
              <div @click="setInput" class="ch-icon-button cursor-pointer">
                <img
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/search-2-6.svg"
                  alt="search 2"
                  class="search-2"
                />
              </div>
            </div>
            <div
              v-else
              class="w-full mb-8 h-6 flex items-center rounded-full px-2 py-1 bg-[#259B35]/10"
            >
              <img
                src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/search-2-6.svg"
                alt="search 2"
                class="search-2"
              />
              <input
                class="w-[80%] text-[#259B35] pl-2 h-full bg-transparent"
              />
              <svg
                @click="setInput"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 cursor-pointer"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>

            <div class="opportunity-list">
              <div class="group-6">
                <div class="flex-col-12">
                  <div class="technical-education inter-light-black-15px">
                    {{ technicalEducation }}
                  </div>
                  <div class="x60000-annually inter-light-black-10px">
                    {{ x60000Annually1 }}
                  </div>
                </div>
                <div class="rectangle-10"></div>
              </div>
              <div class="group-7">
                <div class="flex-col-13">
                  <div class="adult-basic-secondary-ed inter-light-black-15px">
                    {{ adultBasicSecondaryEd }}
                  </div>
                  <div class="x60000-annually-1 inter-light-black-10px">
                    {{ x60000Annually2 }}
                  </div>
                </div>
                <div class="rectangle-10-1"></div>
              </div>
            </div>
          </div>
          <div class="group-2345">
            <div class="flex-row-10 mb-4" v-if="isInputTrue1 === false">
              <div class="suggested-careers inter-normal-black-20px">
                {{ opportunities }}
              </div>
              <div @click="setInput1" class="ch-icon-button cursor-pointer">
                <img
                  src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/search-2-6.svg"
                  alt="search 2"
                  class="search-2"
                />
              </div>
            </div>
            <div
              v-else
              class="w-full mb-8 h-6 flex items-center rounded-full px-2 py-1 bg-[#259B35]/10"
            >
              <img
                src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/search-2-6.svg"
                alt="search 2"
                class="search-2"
              />
              <input
                class="w-[80%] text-[#259B35] pl-2 h-full bg-transparent"
              />
              <svg
                @click="setInput1"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 cursor-pointer"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>

            <div class="opportunity-list">
              <div class="group-8">
                <div class="flex-col-14">
                  <div class="scholar-path-1 inter-light-black-15px">
                    {{ scholarpath }}
                  </div>
                  <div class="st-peters-missouri inter-light-black-10px">
                    {{ stPetersMissouri }}
                  </div>
                </div>
                <img class="mask-group" :src="maskGroup" alt="Mask group" />
              </div>
              <HomeFeedStudentDefaultGroup9
                :rectangle10="HomeFeedStudentDefaultGroup91Props.rectangle10"
              />
              <HomeFeedStudentDefaultGroup9
                :rectangle10="HomeFeedStudentDefaultGroup92Props.rectangle10"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeFeedStudentDefaultGroup2343 from "./HomeFeedStudentDefaultGroup2343.vue";
import HomeFeedStudentDefaultGroup9 from "./HomeFeedStudentDefaultGroup9.vue";
export default {
  name: "CareerProfileFrame",
  components: {
    HomeFeedStudentDefaultGroup2343,
    HomeFeedStudentDefaultGroup9,
  },
  props: [
    "careerProfile",
    "conventional",
    "enterprising",
    "realistic",
    "investigative",
    "number1",
    "number2",
    "number3",
    "number4",
    "number5",
    "artistic",
    "social",
    "suggestedCareers",
    "technicalEducation",
    "x60000Annually1",
    "adultBasicSecondaryEd",
    "x60000Annually2",
    "opportunities",
    "scholarpath",
    "stPetersMissouri",
    "maskGroup",
    "HomeFeedStudentDefaultGroup23431Props",
    "HomeFeedStudentDefaultGroup23432Props",
    "HomeFeedStudentDefaultGroup91Props",
    "HomeFeedStudentDefaultGroup92Props",
  ],

  data() {
    return {
      isInputTrue: false,
      isInputTrue1: false,
    };
  },
  methods: {
    setInput() {
      this.isInputTrue = !this.isInputTrue;
    },
    setInput1() {
      this.isInputTrue1 = !this.isInputTrue1;
    },
  },
};
</script>

<style scoped>
.career-profile-frame {
  align-items: flex-start;
  display: flex;
  min-width: 291px;
  padding: 0 1px;
}

@media all and (max-width: 1400px) {
  .career-profile-frame {
    display: none;
  }
}

.frame-2357 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 30px;
  box-shadow: 0px 0px 15px #0000000d;
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 30px 14.7px;
  width: 289px;
}

.frame-2354-1 {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  gap: 30px;
  margin-left: 15.34px;
  position: relative;
}

.angle-right-1 {
  height: 47px;
  position: relative;
  width: 18px;
}

.career-profile {
  color: #141414;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-2356 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 46px;
  min-height: 680px;
  width: 259px;
}

.radar-chart {
  align-items: center;
  display: flex;
  gap: 1px;
  height: 212px;
  min-width: 281px;
}

.flex-col-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 84px;
  margin-top: 5.76px;
  min-height: 107px;
  width: 56px;
}

.conventional {
  letter-spacing: 0;
  line-height: normal;
  min-height: 12px;
  width: 56px;
}

.enterprising {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.15px;
  min-height: 12px;
  width: 52px;
}

.flex-col-11 {
  align-items: center;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  min-height: 212px;
  width: 202px;
}

.realistic {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 53.92px;
  min-height: 12px;
  text-align: center;
  width: 37px;
}

.overlap-HomeFeedStudentDefaultgroup1-1 {
  height: 180px;
  margin-top: 4px;
  position: relative;
  width: 202px;
}

.overlap-group-6 {
  height: 180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 202px;
}

.polygon-4 {
  height: 171px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 147px;
}

.polygon-5 {
  height: 137px;
  left: 14px;
  position: absolute;
  top: 27px;
  width: 120px;
}

.polygon-6 {
  height: 104px;
  left: 30px;
  position: absolute;
  top: 43px;
  width: 88px;
}

.polygon-7 {
  height: 67px;
  left: 44px;
  position: absolute;
  top: 61px;
  width: 60px;
}

.polygon-8 {
  height: 36px;
  left: 59px;
  position: absolute;
  top: 77px;
  width: 30px;
}

.vector-23 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 52px;
  width: 74px;
}

.vector-24 {
  height: 84px;
  left: 74px;
  position: absolute;
  top: 11px;
  width: 1px;
}

.vector-25 {
  height: 44px;
  left: 75px;
  position: absolute;
  top: 52px;
  width: 71px;
}

.vector-26 {
  height: 44px;
  left: 1px;
  position: absolute;
  top: 94px;
  width: 74px;
}

.vector-27 {
  height: 84px;
  left: 74px;
  position: absolute;
  top: 95px;
  width: 2px;
}

.vector-28 {
  height: 44px;
  left: 75px;
  position: absolute;
  top: 94px;
  width: 71px;
}

.vector-31 {
  height: 100px;
  left: 19px;
  position: absolute;
  top: 40px;
  width: 114px;
}

.investigative {
  left: 147px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 39px;
  width: 55px;
}

.ellipse-40 {
  background-color: var(--bud);
  border-radius: 6.5px;
  height: 13px;
  left: 67px;
  position: absolute;
  top: 72px;
  width: 13px;
}

.ellipse-44 {
  background-color: var(--bud);
  border-radius: 6.5px;
  height: 13px;
  left: 67px;
  position: absolute;
  top: 54px;
  width: 13px;
}

.ellipse-45 {
  background-color: var(--bud);
  border-radius: 6.5px;
  height: 13px;
  left: 67px;
  position: absolute;
  top: 35px;
  width: 13px;
}

.ellipse-46 {
  background-color: var(--bud);
  border-radius: 6.5px;
  height: 13px;
  left: 67px;
  position: absolute;
  top: 19px;
  width: 13px;
}

.ellipse-47 {
  background-color: var(--bud);
  border-radius: 7.5px;
  height: 15px;
  left: 66px;
  position: absolute;
  top: 0;
  width: 15px;
}

.number {
  height: 10px;
  left: 68px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 74px;
}

.number-1 {
  height: 10px;
  left: 68px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 56px;
}

.number-2 {
  height: 10px;
  left: 69px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 36px;
}

.number-3 {
  height: 10px;
  left: 69px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
}

.number-4 {
  height: 10px;
  left: 66px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 3px;
}

.artistic {
  left: 152px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 135px;
  width: 31px;
}

.social {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 52.77px;
  margin-top: 5px;
  min-height: 12px;
  text-align: center;
  width: 27px;
}

.frame-2346 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-left: 3px;
  min-height: 367px;
  width: 253px;
}

.group-2346 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 139px;
  width: 255px;
}

.flex-row-10 {
  align-items: center;
  display: flex;
  gap: 36px;
  height: 33px;
  margin-left: 2px;
  min-width: 251px;
  position: relative;
}

.suggested-careers {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 1px;
  min-height: 24px;
  min-width: 182px;
  white-space: nowrap;
}

.opportunity-list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 253px;
}

.group-6 {
  height: 37px;
  position: relative;
  width: 236px;
}

.flex-col-12 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 40px;
  min-height: 37px;
  position: absolute;
  top: 0;
  width: 192px;
}

.technical-education {
  letter-spacing: 0;
  line-height: normal;
  min-height: 18px;
  width: 192px;
}

.x60000-annually {
  letter-spacing: 0;
  line-height: normal;
  min-height: 12px;
  white-space: nowrap;
}

.rectangle-10 {
  background-color: var(--x259b35);
  border-radius: 2px;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}

.group-7 {
  height: 33px;
  margin-right: -5px;
  position: relative;
  width: 258px;
}

.flex-col-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  left: 40px;
  min-height: 33px;
  position: absolute;
  top: 0;
  width: 214px;
}

.adult-basic-secondary-ed,
.scholar-path-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 16px;
  white-space: nowrap;
}

.x60000-annually-1,
.st-peters-missouri {
  letter-spacing: 0;
  line-height: normal;
  min-height: 11px;
  white-space: nowrap;
}

.rectangle-10-1 {
  background-color: var(--x259b35);
  border-radius: 2px;
  height: 27px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}

.group-2345 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 198px;
  width: 255px;
}

.flex-row-11 {
  align-items: center;
  display: flex;
  gap: 88px;
  height: 33px;
  margin-left: 2px;
  min-width: 251px;
  position: relative;
}

.opportunities {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 1px;
  min-height: 24px;
  min-width: 130px;
  white-space: nowrap;
}

.group-8 {
  height: 33px;
  margin-right: -24px;
  position: relative;
  width: 277px;
}

.flex-col-14 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  left: 40px;
  min-height: 33px;
  position: absolute;
  top: 0;
  width: 233px;
}

.mask-group {
  height: 27px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}
</style>
