<template>
  <div class="w-full h-full fixed top-0 left-0  z-[999] flex justify-center items-center p-5">
      <div class="w-full h-full fixed top-0 left-0 bg-black z-50 opacity-40" @click="$emit('close')"></div>
      <div class="min-h-[400px] w-full  max-w-lg z-50 rounded-lg bg-white px-5 py-10">
        <h3 class="text-lg font-semibold uppercase mb-5 text-center">Job Details</h3>

        <ul class="w-full">
          <li class="jobItem">
            <span class="item-heading">COMMPANY :</span> 
            <span class="item-text">{{ job.company_name }}</span> 
          </li>
          <li class="jobItem">
            <span class="item-heading">job title : </span> 
            <span class="item-text">{{ job.job_title }}</span> 
          </li>
          <li class="jobItem">
            <span class="item-heading">location : </span> 
            <span class="item-text">{{ job.location }}</span> 
          </li>
          <li class="jobItem">
            <span class="item-heading">salary : </span> 
            <span class="item-text">{{ job.salary }}</span> 
          </li>
          
          <li class="jobItem">
            <span class="item-heading">DATE : </span> 
            <span class="item-text">{{ job.date }}</span> 
          </li>
          <li class="jobItem">
            <span class="item-heading">summary : </span> 
            <span class="item-text">{{ job.summary }}</span> 
          </li>
          <br>
          <li class="jobItem">
            <span class="item-heading">CURRENT PAGE # : </span> 
            <span class="item-text">{{ job.current_page_num }}</span> 
          </li>
          <li class="jobItem">
            <span class="item-heading">has next page : </span> 
            <span class="item-text">{{ job.has_next_page }}</span> 
          </li> 
          <li class="jobItem">
            <span class="item-heading">next page url : </span> 
            <a :href="job.next_page_url" target="_blank" class="item-text text-blue-500">{{ job.next_page_url.substring(-1,30) }}...</a> 
          </li>
          <li class="jobItem">
            <span class="item-heading">url : </span> 
            <a :href="job.url" target="_blank" class="item-text text-blue-500">{{ job.url.substring(-1,30) }}...</a> 
          </li>
        </ul>

        <div class="flex items-center justify-center">
          <button class="mx-auto text-white bg-[#259b35] px-8 py-1 text-sm rounded-full mt-5" @click="$emit('close')">Close</button>
        </div>

      </div>
    </div>
</template>

<script>
export default {
    props:['job']
}
</script>

<style scoped>
.jobItem{
  font-size: 13px;
  @apply flex gap-4 w-full  my-1
}
.jobItem .item-heading{
  @apply font-semibold uppercase min-w-max
}
.jobItem .item-text{
  @apply  w-fit
}
a{
  @apply  text-blue-500
}
</style>