// store.js
import { reactive } from 'vue';

export const state = reactive({
  providerId: '',
  providerName: '',
  providerEmail: '',
  userId: '',
  avatar: ''
});

export const mutations = {
  setProviderData(data) {
    state.providerId = data.providerId;
    state.providerName = data.providerName;
    state.providerEmail = data.providerEmail;
    state.userId = data.userId;
    state.avatar = data.avatar;
  }
};
