<template>
    <div class="w-full h-full relative flex flex-col">
        <div class="grid-header-row rounded-t-lg sticky top-0 left-0">
            <div class="grid-header-item">Knowledge</div>
            <div class="grid-header-item">Skills</div>
            <div class="grid-header-item">Abilities</div>
        </div>
        <div class="w-full h-full overflow-y-scroll rounded-b-lg no-scrollbar">
            <div class="grid-row">
                <div class="grid-item bg-gray-50">
                    <ul class="list-disc space-y-2 ml-5" v-if="knowledge && knowledge.length>0">
                        <li v-for="el in knowledge" :key="JSON.stringify(el)">{{ el["Element Name"] }} ({{ el["Scale Name"] }})</li>
                    </ul>
                    <p class="p-10 flex items-center justify-center w-full text-sm font-medium text-gray-700" v-else>
                        No Data Found !!
                    </p>
                </div>
                <div class="grid-item bg-gray-100">
                    <ul class="list-disc space-y-2 ml-5" v-if="skills && skills.length>0">
                        <li v-for="el in skills" :key="JSON.stringify(el)">{{ el["Element Name"] }} ({{ el["Scale Name"] }})</li>
                    </ul>
                    <p class="p-10 flex items-center justify-center w-full text-sm font-medium text-gray-700" v-else>
                        No Data Found !!
                    </p>
                </div>
                <div class="grid-item bg-gray-50">
                    <ul class="list-disc space-y-2 ml-5" v-if="abilities && abilities.length>0">
                        <li v-for="el in abilities" :key="JSON.stringify(el)">{{ el["Element Name"] }} ({{ el["Scale Name"] }})</li>
                    </ul>
                    <p class="p-10 flex items-center justify-center w-full text-sm font-medium text-gray-700" v-else>
                        No Data Found !!
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import knowledge from "../../assets/js/filtered_occupation/knowlege_skills/abilities.json";
import skills from "../../assets/js/filtered_occupation/knowlege_skills/skills.json";
import abilities from "../../assets/js/filtered_occupation/knowlege_skills/abilities.json";
export default {
    props:["OnetCode"],
    computed:{
        knowledge(){
            return knowledge.filter(el => el["O*NET-SOC Code"] === this.OnetCode)
        },
        skills(){
            return skills.filter(el => el["O*NET-SOC Code"] === this.OnetCode)
        },
        abilities(){
            return abilities.filter(el => el["O*NET-SOC Code"] === this.OnetCode)
        }
    }
}
</script>

<style>
.grid-header-row{
    @apply w-full grid grid-cols-3 bg-primary
}
.grid-row{
    @apply w-full grid grid-cols-3
}
.grid-header-item{
    @apply text-lg text-center p-3 text-white
}
.grid-item{
    @apply text-sm px-2.5 py-5
}

</style>