<template>
    <studentDemoLayout>
        <div class="w-full h-screen">
            <div class="fixed top-0 left-0 w-full h-screen flex items-center justify-center z-[999] p-5"
                v-if="showSurvey">
                <div class="absolute top-0 left-0 w-full h-screen bg-black/40" @click="closeSurvey()" />
                <div class="w-full h-full max-w-5xl max-h-[980px] bg-white rounded-md relative z-50">
                    <img src="@/assets/images/close.png" alt="" class="w-5 cursor-pointer absolute z-50 right-5 top-5"
                        @click="closeSurvey()" />
                    <iframe :src="surveyLink" frameborder="0" class="w-full h-full rounded-md"></iframe>
                </div>
            </div>
            <div>
                <div
                    class="w-full h-full border-gray-400 bg-white rounded-3xl flex flex-col items-start justify-start p-5 px-10">
                    <div class="mb-5 text-base">
                        <p>
                            <span class="text-2xl font-bold text-gray-700 mr-5">Questionaire</span> Your forms are based
                            on your
                            initial questionnaire.
                        </p>
                    </div>
                    <div class="w-full mb-5">
                        <div class="w-full h-4 rounded-full bg-gray-100 overflow-hidden mb-1">
                            <div class="h-full bg-primary rounded-full"
                                :style="{ width: `${this.surveysCompletedPercentage}%` }"></div>
                        </div>
                        <p class="w-full text-right text-gray-600">{{ this.surveysCompletedPercentage }}%</p>
                    </div>
                    <div class="w-full max-w-2xl h-12 mx-auto rounded-full bg-gray-100 grid grid-cols-3">
                        <div class="tab" v-for="(t, i) in tabs" :key="JSON.stringify(t)"
                            :class="selectedTab === i ? 'active-tab' : ''" @click="selectedTab = i">
                            {{ t.name }}
                        </div>
                    </div>
                    <div class="w-full" v-if="selectedTab === 0">
                        <div class="w-full grid grid-cols-4 gap-10 p-5" v-if="surveys.length > 0">
                            <!-- Survey Item -->
                            <div v-for="survey in surveys" :key="survey.id" class="survey-item">
                                <div>
                                    <div class="flex gap-2 mb-5">
                                        <img src="../../../img/survey_hero.png" alt="hero" class="w-10 h-10" />
                                        <div>
                                            <p class="font-medium text-[#475F4A]">{{ survey.title }}</p>
                                            <p class="text-xs text-gray-400">{{ getTime(survey.last_updated_at) }}</p>
                                        </div>
                                    </div>
                                    <p class="font-light text-sm">
                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Distinctio, explicabo.
                                    </p>
                                </div>

                                <!-- if Not Started -->
                                <div class="flex items-center justify-between" v-if="!this.surveysCompleted[survey.id]">
                                    <button class="start-btn" @click="startSurvey(survey._links.display)">
                                        Start
                                        <img src="../../../img/play.png" alt="tick" class="w-3" />
                                    </button>
                                </div>

                                <!-- if Completed -->
                                <div class="flex items-center justify-between" v-if="this.surveysCompleted[survey.id]">
                                    <div class="complete-btn">
                                        Complete
                                        <img src="../../../img/tick.png" alt="tick" class="w-3" />
                                    </div>
                                    <p class="text-sm text-gray-400">{{ new
                                        Date(survey.last_updated_at).toLocaleDateString() }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="w-full p-10 flex items-center justify-center text-primary" v-else>No Survey Found !!
                        </div>
                    </div>
                    <div class="w-full" v-if="selectedTab === 1">
                        <div class="w-full p-10 flex items-center justify-center text-primary">No Survey Found !!</div>
                    </div>
                    <div class="w-full" v-if="selectedTab === 2">
                        <div class="w-full p-10 flex items-center justify-center text-primary">No Survey Found !!</div>
                    </div>
                </div>
            </div>
        </div>
    </studentDemoLayout>
</template>

<script>
import studentDemoLayout from "../../layouts/studentDemoLayout.vue";

import ellipse from "../../../img/survey_hero.png";
import { getAssociatedData } from "../../AssociatedData.js";
import { useStudentDemo } from "../../store/StudentDemoStates.js";

export default {
    name: "surveyDefaultForStudentDemByEducator",
    components: {
        studentDemoLayout,
    },
    props: [],
    data() {
        return {
            surveyLink: "",
            showSurvey: false,
            ellipse,
            surveys: [],
            surveyAnswers: [],
            surveysCompleted: {},
            surveysCompletedPercentage: 0,
            selectedTab: 0,
            tabs: [{ name: "All Forms" }, { name: "Interests" }, { name: "Assessments" }],
            store: null,
        };
    },
    async mounted() {
        this.store = useStudentDemo();
    },
    methods: {
        activateTab(index) {
            this.tabs.forEach((tab, tabIndex) => {
                tab.active = index === tabIndex;
            });
        },

        startSurvey(link) {
            this.surveyLink = link;
            this.showSurvey = true;
        },
        closeSurvey() {
            this.showSurvey = false;
        },
        getTime(time) {
            const now = new Date();
            const prev = new Date(time);
            const t = now - prev;
            let text = "";
            if (t < 2000) {
                text = "just now";
            } else if (t > 2000) {
                let seconds = Math.floor(t / 1000);
                let minutes = 0;
                let hours = 0;
                let days = 0;
                let months = 0;
                let years = 0;
                if (seconds >= 60) {
                    minutes = Math.floor(seconds / 60);
                }
                if (minutes >= 60) {
                    hours = Math.floor(minutes / 60);
                }
                if (hours >= 24) {
                    days = Math.floor(hours / 24);
                }
                if (days > 30) {
                    months = Math.floor(days / 30);
                }
                if (months >= 12) {
                    years = Math.floor(months / 12);
                }

                if (years > 0) {
                    text = years + " years ago";
                } else if (months > 3) {
                    text = months + " months ago";
                } else {
                    if (days > 0) {
                        text = days + " days ago";
                    } else if (hours > 0) {
                        text = hours + " hours ago";
                    } else if (minutes > 0) {
                        text = minutes + " minutes ago";
                    } else if (seconds > 0) {
                        text = seconds + " seconds ago";
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.tab {
    @apply w-full h-full flex items-center justify-center rounded-full text-gray-500 text-base cursor-pointer transition-all duration-150;
}

.active-tab {
    box-shadow: 0 0 10px #00000020;
    @apply bg-white text-primary font-semibold text-lg;
}

.survey-item {
    box-shadow: 0 0 5px #00000020;
    @apply w-full h-[240px] rounded-xl bg-white p-5 flex flex-col justify-between;
}

.complete-btn {
    @apply w-28 h-8 rounded-full bg-primary flex items-center justify-center gap-2 text-white text-sm transition-all duration-150 hover:bg-primary/50;
}

.start-btn {
    @apply w-28 h-8 rounded-full bg-white flex items-center justify-center gap-5 text-primary text-sm border border-primary hover:bg-primary/80 cursor-pointer hover:text-white transition-none duration-150;
}
</style>
