<template>
    <div class="home-page-educator-side">
        <div class="top-filter-container">
            <div class="top-filter-item">
                <p>Filter by date</p>
                <div class="select">
                    <select>
                        <option disabled value="">Please select date</option>
                        <option>Year-to-Date</option>
                        <option>B</option>
                        <option>C</option>
                    </select>
                </div>
            </div>
            <div class="top-filter-item">
                <p>Filter by location</p>
                <div class="select">
                    <select>
                        <option disabled value="">Please select location</option>
                        <option>North Carolina</option>
                        <option>B</option>
                        <option>C</option>
                    </select>
                </div>
            </div>
            <div class="top-filter-item">
                <p>Filter by group</p>
                <div class="select">
                    <select>
                        <option disabled value="">Please select a group</option>
                        <option>Algebra 01</option>
                        <option>B</option>
                        <option>C</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="statistics">
            <div class="statistics-item">
                <div class="header-container">
                    <p class="header">41% </p>
                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.20711 0.292892C7.81658 -0.0976315 7.18342 -0.0976314 6.79289 0.292892L0.428932 6.65685C0.0384074 7.04738 0.0384074 7.68054 0.428932 8.07107C0.819456 8.46159 1.45262 8.46159 1.84315 8.07107L7.5 2.41421L13.1569 8.07107C13.5474 8.46159 14.1805 8.46159 14.5711 8.07107C14.9616 7.68054 14.9616 7.04738 14.5711 6.65685L8.20711 0.292892ZM8.5 17L8.5 1L6.5 1L6.5 17L8.5 17Z"
                            fill="#4C9702" />
                    </svg>
                </div>
                <p class="support-text">Survey completion rate</p>
                <p class="comparison-text">VS. 150 Last week</p>
            </div>
            <div class="statistics-item">
                <div class="header-container">
                    <p class="header">200</p>
                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.79289 16.7071C7.18342 17.0976 7.81658 17.0976 8.20711 16.7071L14.5711 10.3431C14.9616 9.95262 14.9616 9.31946 14.5711 8.92893C14.1805 8.53841 13.5474 8.53841 13.1569 8.92893L7.5 14.5858L1.84315 8.92893C1.45262 8.53841 0.819457 8.53841 0.428932 8.92893C0.0384078 9.31946 0.0384078 9.95262 0.428932 10.3431L6.79289 16.7071ZM6.5 0L6.5 16H8.5L8.5 0L6.5 0Z"
                            fill="#DA2700" />
                    </svg>

                </div>
                <p class="support-text">Total Students</p>
                <p class="comparison-text">VS. 250 Last month</p>
            </div>
        </div>
        <div class="body-content">
            <div class="student-item">
                <p class="student-header">My Students</p>
                <div class="table">
                    <table>
                        <thead>
                            <th class="table-header">Student Name</th>
                            <th class="table-header">Surveys Completed</th>
                            <th class="table-header">Grade Level</th>
                        </thead>
                        <tbody>
                            <!-- Loop through student items -->
                            <td class="table-data">Joe Johnson</td>
                            <td class="table-data">14 out of 20</td>
                            <td class="table-data">11th Grade</td>
                            <!-- Repeat for each student item -->
                        </tbody>
                    </table>
                </div>
            </div>


            <div class="survey-item">
                <p class="survey-header">Top Surveys</p>
                <div class="table">
                    <table>
                        <thead>
                                <th class="table-header">Student Name</th>
                                <th class="table-header">Surveys Completed</th>
                                <th class="table-header">Grade Level</th>
                        </thead>
                        <tbody>
                            <!-- Loop through student items -->
                                <td class="table-data">Joe Johnson</td>
                                <td class="table-data">14 out of 20</td>
                                <td class="table-data">11th Grade</td>
                            <!-- Repeat for each student item -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopEducatorNavigationBar from './TopNavigationBar.vue'
export default {
    components: {
        TopEducatorNavigationBar,
    },
    data() {
        return {
            currentLink: null,
        };
    },
    created() {
        this.currentLink = this.$router.currentRoute.path;
    },
    mounted() {
        this.currentLink = this.$router.currentRoute.path;
    },
    methods: {
        goToSurvey(link) {
            this.$router.push(link);
            this.currentLink = this.$router.currentRoute.path;

        },
    },
    name: "EducatorHomeFeed",
    props: [
        "jj",
        "leftNavigationBarProps",
        "topNavigationBarProps",
        "broadcastFeedProps",
        "careerProfileFrameProps",
        "userObject",
    ],
};
</script>

<style scoped>
.home-page-educator-side {
    align-items: center;
    background-color: #f3f7f8;
    box-sizing: border-box;
}

.statistics {
    margin-left: 43px;
    margin-top: 43px;
    display: flex;
    background: #fff;
    width: fit-content;
    padding: 30px;
    gap: 30px;
    border-radius: 50px;
    font-family: inter;

    .statistics-item {
        text-align: center;

        .header-container {
            display: flex;
            justify-content: center;
            gap: 10px;

            >svg {
                margin-left: 4px;
                margin-top: 8px;
            }
        }

        .header {
            color: #000;
            font-size: 30px;
            font-weight: 500;
        }

        .support-text {
            font-size: 12px;
            color: #000;
            font-weight: 500;
            margin-top: 8px;
        }

        .comparison-text {
            font-size: 10px;
            color: #475F4A;
            font-weight: 500;
            margin-top: 8px;
        }
    }
}

.body-content {
    margin-left: 43px;
    margin-top: 43px;
    display: flex;
    gap: 30px;
    font-family: inter;

    .student-item {
        background: #fff;
        padding: 30px;
        border-radius: 50px;
        font-family: inter;

        .student-header {
            color: #475F4A;
            margin-bottom: 16px;
        }

        .item-header {
            display: flex;
            gap: 20px;
            justify-content: space-between;
            font-size: 12px;
            color: #A5AC9D;
            font-weight: 500;
            margin-top: 24px;
            margin-bottom: 16px;
        }

        .item-content {
            display: flex;
            gap: 20px;
        }
    }

    .survey-item {
        background: #fff;
        padding: 30px;
        border-radius: 50px;
        font-family: inter;

        .survey-header {
            color: #475F4A;
            margin-bottom: 16px;
        }

        .item-header {
            display: flex;
            gap: 20px;
            justify-content: space-between;
            font-size: 12px;
            color: #A5AC9D;
            font-weight: 500;
            margin-top: 24px;
            margin-bottom: 16px;
        }

        .item-content {
            display: flex;
            gap: 20px;
        }
    }
}

.table {
    .table-header {
        color: #A5AC9D;
        font-family: inter;
        font-weight: 500;
        font-size: 12px;
        padding-right: 32px;
    }
    .table-data {
        color: #000;
        font-size: 15px;
        font-weight: 400;
        font-family: inter;
        line-height: 18.5px;
        padding-top: 16px;
    }
}

.top-filter-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-family: inter;

    .select {
        height: 43px;
        background: #FFF;
        display: flex;
        border-radius: 10px;
        padding: 10px;
        color: #259b35 !important;
    }
}
</style>
