<template>
    <div class="w-full px-5">
        <div class="mb-10">
            <h3 class="font-semibold text-lg mb-3">Description</h3>
            <p class="text-black/80">
                {{ description }}
            </p>
        </div>
        <div class="w-full grid grid-cols-2 gap-20">
            <div class="mb-5" v-if="tasks.length>0">
                <h3 class="font-semibold text-lg mb-3">Job Tasks Samples</h3>
                <p class="text-black/80">
                    <ul class="list-disc ml-5 space-y-1">
                        <li v-for="el in tasks" :key="JSON.stringify(el)">{{ el["Task"] }}</li>
                    </ul>
                </p>
            </div>
            <div class="mb-5">
                <h3 class="font-semibold text-lg mb-3">Reported Titles</h3>
                <ul class="list-disc ml-5 text-black/80 space-y-1">
                    <li v-for="el in titles" :key="JSON.stringify(el)">{{ el["Reported Job Title"] }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import OccupationData from '../../assets/js/filtered_occupation/OccupationData.json'
import ReportTitles from '../../assets/js/filtered_occupation/ReportTitles.json'
import TaskStatement from '../../assets/js/filtered_occupation/TaskStatement.json'
export default{
    props:["OnetCode"],
    data(){
        return{}
    },
    computed:{
        description(){
            const res = OccupationData.find(el => el["O*NET-SOC Code"] === this.OnetCode)
            return res?res["Description"]:"";
        },
        titles(){
            return ReportTitles.filter(el => el["O*NET-SOC Code"] === this.OnetCode)
        },
        tasks(){
            return TaskStatement.filter(el => el["O*NET-SOC Code"] === this.OnetCode)
        }
    }
}
</script>