<template>   
  <div>
    <apexchart
      type="bar"
      :height="chartHeight"
      :width="width"
      :options="chartOptions"
      :series="chartSeries"
    ></apexchart>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';

// Props definition
const props = defineProps({
  width: {
    type: String,
    default: '500'
  },
  chartHeight: {
    type: String,
    default: '400px' 
  },
  chartOptions: {
    type: Object,
    required: true
  },
  series: {
    type: Array,
    required: true
  }, 
});
const chartSeries = ref(props.series);

</script>

<style scoped>

</style>