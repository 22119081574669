<template>
  <div class="wrapper">
    <div class="fixed top-0 left-0 w-full h-screen bg-black/5 z-50" v-if="showMore" @click="showMore = false"></div>
    <div class="w-full flex items-center justify-between">
      <div class="flex items-center flex-col md:flex-row gap-5 py-5 relative">
        <CustomSelect :options="['State', ...states]" :placeholder="statePlaceholder"
          @select="handleSelect('state', $event)" ref="stateSelect" />
        <CustomSelect :options="['School Size', ...schoolSize]" :placeholder="sizePlaceholder"
          @select="handleSelect('schoolsize', $event)" ref="sizeSelect" />
        <CustomSelect :options="['Setting', ...settings.map((_) => _.key)]" :placeholder="settingPlaceholder"
          @select="handleSelect('setting', $event)" ref="settingSelect" />
        <CustomSelect :options="['Tution', ...tuition]" :placeholder="tutionPlaceHolder"
          @select="handleSelect('tuition', $event)" ref="tutionSelect" />

        <button
          class="flex items-center justify-between w-40 py-1.5 px-5 text-sm rounded-md relative select-box text-[#259B35]"
          @click.prevent="showMore = true">
          More Filters
          <span class="arrow" :class="{ open: showMore }"></span>
          <div class="w-2 h-2 bg-primary rounded-full absolute right-1 top-1" v-if="moreFilters"></div>
        </button>

        <div
          class="w-full max-w-3xl p-5 py-10 bg-white border border-gray-300 rounded-lg absolute top-20 z-50 shadow-md"
          v-if="showMore">
          <div class="mb-5 hidden">
            <h3 class="font-semibold">School Type</h3>
            <div class="flex items-center gap-10 py-3">
              <div class="flex items-center gap-3 cursor-pointer">
                <input type="radio" name="school" id="fourYear" />
                <label for="fourYear" class="cursor-pointer">4 Year</label>
              </div>
              <div class="flex items-center gap-3 cursor-pointer">
                <input type="radio" name="school" id="twoYear" />
                <label for="twoYear" class="cursor-pointer">2 Year</label>
              </div>
            </div>
          </div>

          <div class="w-full grid grid-cols-3 gap-5 mb-5">
            <CustomSelect :options="['gpa', ...gpa]" :placeholder="gpaPlaceholder" @select="handleSelect('gpa', $event)"
              ref="gpaSelect" />

            <CustomSelect :options="['act', ...act]" :placeholder="actPlaceholder" @select="handleSelect('act', $event)"
              ref="actSelect" />

            <CustomSelect :options="['sat', ...sat]" :placeholder="satPlaceholder" @select="handleSelect('sat', $event)"
              ref="satSelect" />
          </div>

          <div class="flex items-center justify-end gap-5">
            <button class="white-btn" @click.prevent="reset()">Reset all</button>
            <button class="view-btn" @click.prevent="showMore = false">Done</button>
          </div>
        </div>
      </div>
      <div class="py-5">
        <button
          class="bg-white py-3 px-6 shadow-[0_0_10px_0_rgba(71,95,74,0.151)] text-[15px] text-[#259B35] ml-10 rounded-xl"
          @click.prevent="showSavedColleges">
          Saved Colleges
        </button>
      </div>
    </div>

    <div class="h-full max-h-full p-6 border border-gray-300 rounded-xl overflow-y-scroll">
      <table class="w-full">
        <thead>
          <tr>
            <th
              class="w-96 2xl:w-[400px] text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pb-2">
              College Name
            </th>
            <th
              class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pb-2">
              State
            </th>
            <th
              class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pb-2">
              State Tuition
            </th>
            <th
              class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pb-2">
              Non Resident Tuition
            </th>
            <th
              class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pb-2">
              Total Enrollment
            </th>
            <th
              class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pb-2">
            </th>
          </tr>
        </thead>
        <tbody v-if="collegeData.length > 0">
          <!-- <pre>{{fileredData}}</pre> -->
          <tr v-for="(college, index) in collegeData" :key="college.id" @click="navigateTo(college.INUN_ID)">
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              <span class="inline-block cursor-pointer" @click.stop="changeIcon(index, college)">
                <img
                  :src="savedColleges === true ? bookmarkedIcon : isBookmarked[index] ? bookmarkedIcon : bookmarkIcon"
                  class="mr-2" />
              </span>
              {{ truncate(college.School_Name, 45) }}
            </td>
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ college.STATE_CODE }}
            </td>
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              ${{ numberWithCommas(college.TUIT_AREA_FT_D) }}
            </td>
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              ${{ numberWithCommas(college.TUIT_NRES_FT_D) }}
            </td>
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ college.EN_TOT_N }}
            </td>
            <td
              class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap py-1.5 flex justify-center items-center">
              <img src="../../assets/images/rightgreenarrow.png" alt="" class="w-5 small-img" />
            </td>
          </tr>
        </tbody>
        <div class="w-full h-full max-h-full overflow-y-scroll p-10 flex flex-col items-center justify-center gap-5"
          v-else>
          <p class="text-xl font-medium">No College Found</p>
          <button class="view-btn" @click.prevent="reset()">Go Back</button>
        </div>
      </table>
    </div>
    <div class="w-full p-5"></div>
  </div>
</template>

<script>
//import collegeJSON from "../../assets/js/peterson/_2024_INUN_9250_9499.json";
import collegeJSON from "../../assets/js/peterson/UG_EXPENSE_ASGNS_2024_INUN_5250_10499.json";
import bookmarkIcon from "../../assets/images/bookmark.png";
import bookmarkedIcon from "../../assets/images/bookmarked.png";
import CustomSelect from "../Atoms/CustomSelect.vue";

import { getAssociatedData, updateAssociatedData, saveAssociatedData } from "../../AssociatedData.js";

import { useStudentDemo } from "../../store/StudentDemoStates";
export default {
  components: {
    CustomSelect,
  },
  props: {
    searchKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isBookmarked: [],
      bookmarkIcon,
      savedColleges: false,
      bookmarkedIcon,
      saved: false,
      selectedSubFilter: -1,
      subfilters: [
        "school type",
        "state",
        "house space",
        "tuition",
        "non resident tuition",
        "non residents",
        "enrollment",
      ],
      colleges: collegeJSON,
      showMore: false,

      selectedGPA: "",
      selectedState: "",

      selectedSetting: "",
      selectedSchoolSize: "",
      selectedTuition: "",
      selectedSAT: "",
      selectedACT: "",
      statePlaceholder: "State",
      sizePlaceholder: "Size",
      settingPlaceholder: "Setting",
      tutionPlaceHolder: "Tution",
      gpaPlaceholder: "GPA",
      actPlaceholder: "ACT",
      satPlaceholder: "SAT",
      stateMap: {
        "AL": "Alabama",
        "AK": "Alaska",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "FL": "Florida",
        "GA": "Georgia",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PA": "Pennsylvania",
        "PR": "Puerto Rico",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming",
        "Alabama": "AL",
        "Alaska": "AK",
        "Arizona": "AZ",
        "Arkansas": "AR",
        "California": "CA",
        "Colorado": "CO",
        "Connecticut": "CT",
        "Delaware": "DE",
        "Florida": "FL",
        "Georgia": "GA",
        "Hawaii": "HI",
        "Idaho": "ID",
        "Illinois": "IL",
        "Indiana": "IN",
        "Iowa": "IA",
        "Kansas": "KS",
        "Kentucky": "KY",
        "Louisiana": "LA",
        "Maine": "ME",
        "Maryland": "MD",
        "Massachusetts": "MA",
        "Michigan": "MI",
        "Minnesota": "MN",
        "Mississippi": "MS",
        "Missouri": "MO",
        "Montana": "MT",
        "Nebraska": "NE",
        "Nevada": "NV",
        "New Hampshire": "NH",
        "New Jersey": "NJ",
        "New Mexico": "NM",
        "New York": "NY",
        "North Carolina": "NC",
        "North Dakota": "ND",
        "Ohio": "OH",
        "Oklahoma": "OK",
        "Oregon": "OR",
        "Pennsylvania": "PA",
        "Puerto Rico": "PR",
        "Rhode Island": "RI",
        "South Carolina": "SC",
        "South Dakota": "SD",
        "Tennessee": "TN",
        "Texas": "TX",
        "Utah": "UT",
        "Vermont": "VT",
        "Virginia": "VA",
        "Washington": "WA",
        "West Virginia": "WV",
        "Wisconsin": "WI",
        "Wyoming": "WY"
      },

      states: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      gpa: ["4.0", "3.7 - 4.0", "3.4 - 3.7", "3.0 - 3.3", "Below 3.0"],

      settings: [
        { key: "urban", name: "Urban" },
        { key: "Subrb", name: "Suburban" },
        { key: "rural", name: "Rural" },
        { key: "small", name: "Small Town" },
      ],
      schoolSize: [
        "Less than 2k students",
        "2k-7k students",
        "8k-13k students",
        "14k-20k students",
        "More than 20k students",
      ],
      tuition: ["Less than $10,000", "$10,000 - $25,000", "$25,000 - $50,000", "More than $50,000"],
      sat: ["Below 800", "800 - 999", "1000 - 1200", "1210 - 1390", "1400 and above"],
      act: ["Below 6", "6 - 11", "12 - 17", "18 - 23", "24 - 29", "30 and above"],
      store: null,
    };
  },
  computed: {
    collegeData() {
      let result = collegeJSON.filter((el) => el.CMPS_METRO_T.toLowerCase().includes(this.searchKey.toLowerCase()));
      if (this.savedColleges) {
        result = collegeJSON.filter((data, index) => this.isBookmarked[index]);
      }

      // Filter by State
      if (this.selectedState) {
        if (this.selectedState.toLowerCase() === "state") {
          return result;
        } else {
          result = result.filter((el) => el.STATE_CODE === this.stateMap[this.selectedState]);
        }
      }

      // Filter by GPA
      if (this.selectedGPA) {
        if (this.selectedGPA.toLowerCase() === "gpa") {
          return result;
        } else if (this.selectedGPA === "4.0") {
          result = result.sort((a, b) => {
            const n1 = a.EN_FRSH_GPA_1_P ? parseFloat(a.EN_FRSH_GPA_1_P) : 0;
            const n2 = b.EN_FRSH_GPA_1_P ? parseFloat(b.EN_FRSH_GPA_1_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedGPA === "3.7 - 4.0") {
          result = result.sort((a, b) => {
            const n1 = a.EN_FRSH_GPA_2_P ? parseFloat(a.EN_FRSH_GPA_2_P) : 0;
            const n2 = b.EN_FRSH_GPA_2_P ? parseFloat(b.EN_FRSH_GPA_2_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedGPA === "3.4 - 3.7") {
          result = result.sort((a, b) => {
            const n1 = a.EN_FRSH_GPA_3_P ? parseFloat(a.EN_FRSH_GPA_3_P) : 0;
            const n2 = b.EN_FRSH_GPA_3_P ? parseFloat(b.EN_FRSH_GPA_3_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedGPA === "3.0 - 3.3") {
          result = result.sort((a, b) => {
            const n1 = a.EN_FRSH_GPA_5_P ? parseFloat(a.EN_FRSH_GPA_5_P) : 0;
            const n2 = b.EN_FRSH_GPA_5_P ? parseFloat(b.EN_FRSH_GPA_5_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedGPA === "Below 3.0") {
          result = result.sort((a, b) => {
            const n1 = a.EN_FRSH_GPA_6_P ? parseFloat(a.EN_FRSH_GPA_6_P) : 0;
            const n2 = b.EN_FRSH_GPA_6_P ? parseFloat(b.EN_FRSH_GPA_6_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        }
      }

      // Filter by Setting
      if (this.selectedSetting) {
        if (this.selectedSetting.toLowerCase() === "setting") {
          return result;
        } else {
          result = result.filter((el) => el.CMPS_SETTING.toLowerCase() === this.selectedSetting.toLowerCase());
        }
      }

      // Filter by School Size
      if (this.selectedSchoolSize) {
        if (this.selectedSchoolSize.toLowerCase() === "schoolsize") {
          return result;
        } else if (this.selectedSchoolSize === "Less than 2k students") {
          result = result.filter((el) => {
            const size = el.HOUS_SPACES_OCCUP ? parseInt(el.HOUS_SPACES_OCCUP) : 0;
            return size < 2000;
          });
        } else if (this.selectedSchoolSize === "2k-7k students") {
          result = result.filter((el) => {
            const size = el.HOUS_SPACES_OCCUP ? parseInt(el.HOUS_SPACES_OCCUP) : 0;
            return size >= 2000 && size <= 7000;
          });
        } else if (this.selectedSchoolSize === "8k-13k students") {
          result = result.filter((el) => {
            const size = el.HOUS_SPACES_OCCUP ? parseInt(el.HOUS_SPACES_OCCUP) : 0;
            return size > 7000 && size <= 13000;
          });
        } else if (this.selectedSchoolSize === "14k-20k students") {
          result = result.filter((el) => {
            const size = el.HOUS_SPACES_OCCUP ? parseInt(el.HOUS_SPACES_OCCUP) : 0;
            return size > 14000 && size <= 20000;
          });
        } else if (this.selectedSchoolSize === "More than 20k students") {
          result = result.filter((el) => {
            const size = el.HOUS_SPACES_OCCUP ? parseInt(el.HOUS_SPACES_OCCUP) : 0;
            return size > 20000;
          });
        }
      }

      // Filter by Tuition Fee
      if (this.selectedTuition) {
        if (this.selectedTuition.toLowerCase() === "tuition") {
          return result;
        } else if (this.selectedTuition === "Less than $10,000") {
          result = result.filter((el) => {
            const fee = el.TUIT_AREA_FT_D ? parseInt(el.TUIT_AREA_FT_D) : 0;
            return fee <= 10000;
          });
        } else if (this.selectedTuition === "$10,000 - $25,000") {
          result = result.filter((el) => {
            const fee = el.TUIT_AREA_FT_D ? parseInt(el.TUIT_AREA_FT_D) : 0;
            return fee > 10000 && fee <= 25000;
          });
        } else if (this.selectedTuition === "$25,000 - $50,000") {
          result = result.filter((el) => {
            const fee = el.TUIT_AREA_FT_D ? parseInt(el.TUIT_AREA_FT_D) : 0;
            return fee > 25000 && fee <= 50000;
          });
        } else if (this.selectedTuition === "More than $50,000") {
          result = result.filter((el) => {
            const fee = el.TUIT_AREA_FT_D ? parseInt(el.TUIT_AREA_FT_D) : 0;
            return fee > 50000;
          });
        }
      }

      //Filter by SAT
      if (this.selectedSAT) {
        if (this.selectedSAT.toLowerCase() === "sat") {
          return result;
        } else if (this.selectedSAT === "Below 800") {
          result = result.sort((a, b) => {
            const n1 = a.SAT1_COMP_600_P ? parseFloat(a.SAT1_COMP_600_P) : 0;
            const n2 = b.SAT1_COMP_600_P ? parseFloat(b.SAT1_COMP_600_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedSAT === "800 - 999") {
          result = result.sort((a, b) => {
            const n1 = a.SAT1_COMP_800_P ? parseFloat(a.SAT1_COMP_800_P) : 0;
            const n2 = b.SAT1_COMP_800_P ? parseFloat(b.SAT1_COMP_800_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedSAT === "1000 - 1200") {
          result = result.sort((a, b) => {
            const n1 = a.SAT1_COMP_1000_P ? parseFloat(a.SAT1_COMP_1000_P) : 0;
            const n2 = b.SAT1_COMP_1000_P ? parseFloat(b.SAT1_COMP_1000_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedSAT === "1210 - 1390") {
          result = result.sort((a, b) => {
            const n1 = a.SAT1_COMP_1200_P ? parseFloat(a.SAT1_COMP_1200_P) : 0;
            const n2 = b.SAT1_COMP_1200_P ? parseFloat(b.SAT1_COMP_1200_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedSAT === "1400 and above") {
          result = result.sort((a, b) => {
            const n1 = a.SAT1_COMP_1400_P ? parseFloat(a.SAT1_COMP_1400_P) : 0;
            const n2 = b.SAT1_COMP_1400_P ? parseFloat(b.SAT1_COMP_1400_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        }
      }

      //Filter by ACT
      if (this.selectedACT) {
        if (this.selectedACT.toLowerCase() === "act") {
          return result;
        } else if (this.selectedACT === "Below 6") {
          result = result.sort((a, b) => {
            const n1 = a.ACT_1_P ? parseFloat(a.ACT_1_P) : 0;
            const n2 = b.ACT_1_P ? parseFloat(b.ACT_1_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedACT === "6 - 11") {
          result = result.sort((a, b) => {
            const n1 = a.ACT_2_P ? parseFloat(a.ACT_2_P) : 0;
            const n2 = b.ACT_2_P ? parseFloat(b.ACT_2_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedACT === "12 - 17") {
          result = result.sort((a, b) => {
            const n1 = a.ACT_3_P ? parseFloat(a.ACT_3_P) : 0;
            const n2 = b.ACT_3_P ? parseFloat(b.ACT_3_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedACT === "18 - 23") {
          result = result.sort((a, b) => {
            const n1 = a.ACT_4_P ? parseFloat(a.ACT_4_P) : 0;
            const n2 = b.ACT_4_P ? parseFloat(b.ACT_4_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedACT === "24 - 29") {
          result = result.sort((a, b) => {
            const n1 = a.ACT_5_P ? parseFloat(a.ACT_5_P) : 0;
            const n2 = b.ACT_5_P ? parseFloat(b.ACT_5_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        } else if (this.selectedACT === "30 and above") {
          result = result.sort((a, b) => {
            const n1 = a.ACT_6_P ? parseFloat(a.ACT_6_P) : 0;
            const n2 = b.ACT_6_P ? parseFloat(b.ACT_6_P) : 0;
            return n1 > n2 ? -1 : n1 < n2 ? 1 : 0;
          });
        }
      }

      return result;
    },
    filterApplied() {
      return (
        this.selectedState ||
        this.selectedSetting ||
        this.selectedTuition ||
        this.selectedSchoolSize ||
        this.selectedGPA ||
        this.selectedACT ||
        this.selectedSAT
      );
    },
    moreFilters() {
      return this.selectedGPA || this.selectedACT || this.selectedSAT;
    },
  },
  mounted() {
    const store = useStudentDemo();
    this.store = store;
    if (!this.store.isStudentDemo) {
      this.fetchAssociatedData();
    }
  },

  methods: {
    reset() {
      this.selectedState = "";
      this.selectedSetting = "";
      this.selectedSchoolSize = "";
      this.selectedTuition = "";
      this.resetMore();

      this.$refs.stateSelect.reset();
      this.$refs.sizeSelect.reset();
      this.$refs.settingSelect.reset();
      this.$refs.tutionSelect.reset();
      this.$refs.gpaSelect.reset();
      this.$refs.actSelect.reset();
      this.$refs.satSelect.reset();
    },

    resetMore() {
      this.selectedGPA = "";
      this.selectedSAT = "";
      this.selectedACT = "";
    },
    truncate(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      }
      return text;
    },
    updateData(i) {
      if (this.selectedSubFilter === i) {
        this.selectedSubFilter = -1;
      } else {
        this.selectedSubFilter = i;
      }
    },
    compareFn(a, b) {
      if (a.CMPS_METRO_T < b.CMPS_METRO_T) {
        return -1;
      } else if (a.CMPS_METRO_T > b.CMPS_METRO_T) {
        return 1;
      }
      return 0;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    openLink(link) {
      window.open(link);
    },
    navigateTo(id) {
      this.$router.push("/college/" + id + "?tab=0");
    },

    async changeIcon(index, college) {

      if (this.store.isStudentDemo) {
        this.isBookmarked[index] = !this.isBookmarked[index];
      } else {

        const ad = await getAssociatedData();
        if (ad != null) {
          let isSaved =
            ad.exploreColleges != null &&
            ad.exploreColleges.length > 0 &&
            ad.exploreColleges?.some(
              (el) =>
                el.college === college.School_Name &&
                el.state == college.STATE_CODE &&
                el.stateTuition === Number(college.TUIT_AREA_FT_D)
            );

          if (isSaved) {
            ad.exploreColleges = ad.exploreColleges.filter(
              (el) =>
                el.college !== college.School_Name &&
                el.state !== college.STATE_CODE &&
                el.stateTuition !== Number(college.TUIT_AREA_FT_D)
            );
          } else {
            ad.exploreColleges != null && ad.exploreColleges.length > 0
              ? ad.exploreColleges.push({
                college: college.School_Name,
                state: college.STATE_CODE,
                stateTuition: Number(college.TUIT_AREA_FT_D),
                nonResidentTuition: Number(college.TUIT_NRES_FT_D),
                totalEnrollment: Number(college.EN_TOT_N),
                saved: true,
              })
              : (ad.exploreColleges = [
                {
                  college: college.School_Name,
                  state: college.STATE_CODE,
                  stateTuition: Number(college.TUIT_AREA_FT_D),
                  nonResidentTuition: Number(college.TUIT_NRES_FT_D),
                  totalEnrollment: Number(college.EN_TOT_N),
                  saved: true,
                },
              ]);
          }
          const res = await updateAssociatedData(ad);

          if (res?.status === 200 && isSaved) {
            this.isBookmarked[index] = false;
          }

          if (res?.status === 200 && !isSaved) {
            this.isBookmarked[index] = true;
          }
        } else {
          const ad = {};
          ad.exploreColleges = [
            {
              college: college.School_Name,
              state: college.STATE_CODE,
              stateTuition: Number(college.TUIT_AREA_FT_D),
              nonResidentTuition: Number(college.TUIT_NRES_FT_D),
              totalEnrollment: Number(college.EN_TOT_N),
              saved: true,
            },
          ];
          const res = await saveAssociatedData(ad);

          if (res?.status === 200) {
            this.isBookmarked[index] = true;
          }
        }
      }
    },
    async fetchAssociatedData() {
      try {
        const associatedData = await getAssociatedData();
        this.updateBookmarks(associatedData?.exploreColleges || []);
      } catch (error) {
      }
    },
    updateBookmarks(asd) {
      this.isBookmarked = this.collegeData.map((college) => {
        return asd.some(
          (data) =>
            data.college === college.School_Name &&
            data.state === college.STATE_CODE &&
            data.stateTuition === Number(college.TUIT_AREA_FT_D)
        );
      });
    },
    showSavedColleges() {
      this.savedColleges = !this.savedColleges;
    },

    handleSelect(type, value) {
      switch (type) {
        case "gpa":
          this.selectedGPA = value;
          break;
        case "state":
          this.selectedState = value;
          break;
        case "setting":
          this.selectedSetting = value;
          break;
        case "schoolsize":
          this.selectedSchoolSize = value;
          break;
        case "tuition":
          this.selectedTuition = value;
          break;
        case "gpa":
          this.selectedGPA = value;
          break;
        case "sat":
          this.selectedSAT = value;
          break;
        case "act":
          this.selectedACT = value;
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.selectInput {
  background-image: url("../../../img/down.png");
  background-size: 24px;
  appearance: none;
  @apply w-40 bg-white border border-gray-300 p-3 py-1.5 text-sm rounded-md bg-right bg-no-repeat hover:border-primary focus:border-primary;
}

.wrapper {
  width: 100%;
  border-radius: 12px;
  @apply h-full relative z-50 flex flex-col;
}

.grid-table {
  min-width: 600px;
  width: 100%;
  border: 2px solid #e4e4e4;
  border-radius: 20px;
  @apply relative h-full max-h-full flex flex-col items-center bg-white overflow-y-scroll;
}

.grid-row-header {
  height: 60px;
  display: grid;
  background: rgb(37, 155, 53);
  font-size: 12px;
  border-radius: 16px 12px 0 0;
  @apply text-white w-full sticky top-0 grid-flow-col auto-cols-fr;
}

.grid-row {
  display: grid;
  font-size: 12px;
  @apply bg-white grid-flow-col auto-cols-fr;
}

.grid-row:nth-child(even) {
  @apply bg-black/5;
}

.grid-item {
  min-height: 80px;
  /* border-right: 2px solid #E4E4E4; */
  padding: 10px;
  @apply overflow-scroll flex px-5 justify-center;
}

.wrapper::-webkit-scrollbar,
.grid-item::-webkit-scrollbar {
  display: none;
}

.grid-row-header .grid-item:last-child,
.grid-row .grid-item:last-child {
  border-right: 2px solid transparent;
}

.grid-row:hover {
  @apply bg-slate-50 cursor-pointer;
}

.grid-row-header .grid-item {
  font-weight: 600;
  text-transform: uppercase;
  @apply text-center text-xs;
}

.grid-row .grid-item {
  @apply flex items-center;
}

a {
  @apply text-blue-500;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.view-btn {
  @apply w-full h-10 min-w-max max-w-[150px] bg-primary py-1.5 rounded-md font-bold text-white text-center flex items-center justify-center hover:bg-primary/80;
}

.white-btn {
  @apply w-full h-10 min-w-max max-w-[150px] bg-white py-1.5 rounded-md font-bold text-black text-center flex items-center justify-center;
}

.small-img {
  width: 10px;
  height: 100%;
}

.select-box {
  min-width: 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(71, 95, 74, 0.151);
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.arrow {
  border: solid #28a745;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(-135deg);
}
</style>
