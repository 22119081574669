<template>
  <svg :width="props.width" :height="props.height" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.16477 17.5701C8.94511 17.3504 8.94511 16.9942 9.16477 16.7745L24.3124 1.62689L24.2793 1.59375L20.0625 1.60978C19.7519 1.60978 19.5 1.35792 19.5 1.04728V0.578578C19.5 0.267891 19.7518 0.0160781 20.0625 0.0160781L26.4375 0C26.7481 0 27 0.251813 27 0.5625L26.9839 6.9375C26.9839 7.24814 26.7321 7.5 26.4214 7.5H25.9527C25.6421 7.5 25.3902 7.24814 25.3902 6.9375L25.4062 2.72072L25.3731 2.68758L10.2255 17.8352C10.0058 18.0549 9.64964 18.0549 9.42994 17.8352L9.16477 17.5701ZM20.0398 10.2748L19.6648 10.6498C19.5593 10.7552 19.5 10.8983 19.5 11.0475V21.75C19.5 22.1642 19.1642 22.5 18.75 22.5H2.25C1.83581 22.5 1.5 22.1642 1.5 21.75V5.25C1.5 4.83577 1.83581 4.5 2.25 4.5H18.1864C18.3356 4.5 18.4786 4.44075 18.5841 4.33523L18.9591 3.96023C19.3135 3.60586 19.0625 3 18.5614 3H2.25C1.00734 3 0 4.00734 0 5.25V21.75C0 22.9927 1.00734 24 2.25 24H18.75C19.9927 24 21 22.9927 21 21.75V10.6725C21 10.1714 20.3941 9.92044 20.0398 10.2748Z" fill="black"/>
  </svg>

</template>

<script setup>
const props = defineProps({
  height: {
    type: Number,
    default: 18,
  },
  width: {
    type: Number,
    default: 18,
  },
});
</script>

<style scoped>

</style>