<template>
  <div class="w-full h-screen">
    <MainLayout>
      <div class="frame-2342">
        <!--Chat-->
        <div class="chat_main">
          <div class="chat">
            <div class="chat_users">
              <div class="chat_user_heading">
                <h1>Messages</h1>
              </div>

              <CustomSelect width="full" :data="providers" placeholder="Select a provider"
                @select="handleSelect($event)" />

              <div class="scrollable">
                <div class="user_heading" v-for="user in users" :key="user" v-on:click="handleSelect(user)">
                  <div class="mt-3">
                  <img
                    src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80"
                    alt="" />
                  </div>
                  
                  <div>
                    <h1 class="mt-3 text-[20px] font-[500]">{{ user.label.slice(0,10) }}</h1>
                    <p class="mt-3 text-[15px] font-[300]">{{ user?.lastMessage?.slice(0,15) }}...</p>
                  </div>
                </div>
                <div class="flex items-center justify-center mt-5">
                  <span v-if="users.length < 1">You have no messages yet</span>
                </div>
              </div>
            </div>
            <div class="chat_body">
              <div v-if="providerName == ''" class="w-full">
                <div class="flex flex-col justify-center items-center mt-16">
                  <span class="font-normal">Begin messaging with providers from your broadcast feed</span>
                  <router-link to="/homeFeed">
                    <button class="bg-[#259B35] w-[330px] h-[42px] mt-4 rounded-[100px] text-[#fff]">
                      Return to Broadcast Feed
                    </button>
                  </router-link>
                </div>
              </div>
              <div v-else>
                <div class="chat_body_user_heading">
                  <img :src="avatar" alt="user image" />

                  <div class="">
                    <h1 class="text-[20px] font-[500]">{{ providerName }}</h1>
                    <p class="mt-1 text-[15px] font-[300]">{{ new Date().toLocaleString() }}</p>
                    <p class="mt-1 text-[15px] font-[500] underline">View Profile ></p>
                  </div>
                </div>

                <div v-if="stage === 0" class="bg-[#f3f7f8] rounded-[40px]">
                  <div class="chat_body_main flex items-center justify-center flex-col">
                    <p class="inter my-2">Provider Messaging</p>
                    <p class="inter my-2 font-light">
                      Select a topic you want to inquire about with
                      <span v-if="providerName != ''">
                        <strong class="font-bold">{{ providerName }} </strong>
                      </span>
                      <span v-else>this provider</span>
                    </p>
                    <div class="single_message" v-for="custom in customQuestions" :key="custom"
                      @click="setCurrentQuestion(custom)">
                      <div
                        class="message_div_pre_message flex items-center justify-between w-96 mx-auto cursor-pointer">
                        <p class="text-base">{{ custom }}</p>
                        <div id="message_icon"
                          class="h-6 w-6 flex items-center justify-center bg-white rounded-full">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 23 23"
                            fill="none">
                            <path
                              d="M7.65332 21.2197C7.65332 21.3575 7.72985 21.4838 7.85154 21.5482C7.97313 21.6126 8.12098 21.6043 8.23464 21.5265L10.7944 19.7787L7.65337 18.2816V21.2197H7.65332Z"
                              fill="#259B35" />
                            <path
                              d="M22.8805 1.50743C22.8102 1.44261 22.7194 1.40869 22.6277 1.40869C22.5724 1.40869 22.5172 1.42096 22.4651 1.44661L0.367267 12.2862C0.141403 12.3972 -0.00152111 12.6278 1.22156e-05 12.8801C0.00154554 13.1321 0.146886 13.3612 0.374376 13.4697L6.19831 16.2448L17.8432 6.67357L7.64126 16.9323L14.8964 20.3892C14.9856 20.4319 15.0829 20.4531 15.1791 20.4531C15.2613 20.4531 15.3426 20.4382 15.4201 20.4077C15.5891 20.3409 15.7234 20.2071 15.7904 20.0381L22.9736 1.91697C23.0305 1.77437 22.9934 1.61132 22.8805 1.50743Z"
                              fill="#259B35" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--if there are messages present go straight to chat-->
                <div v-if="stage === 1" class="rounded-[40px] px-4 py-2">
                  <div class="chat_body_main">
                    <div v-if="messagesPresent === false" class="single_message_right">
                      <div class="message_div">
                        <p>{{ currentQuestion }}</p>
                      </div>
                      <img
                        src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80" />
                    </div>
                    <p v-if="gotPermission === false"
                      class="text-xs inter text-center mt-4 max-w-lg mx-auto font-light">
                      You’ll be notified when the provider replies to your message. To view responses, please have your
                      parent/guardian give permission to share information.
                    </p>
                    <div v-if="messagesPresent === false"
                      :class="gotPermission === true ? 'single_message' : 'single_message blur-sm'">
                      <img
                        src="https://images.unsplash.com/photo-1688314846356-ccc51cd0bcfd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2450&q=80" />
                      <div class="message_div">
                        <p>I’d like to discuss: Available Scholarships</p>
                      </div>
                    </div>
                    <p v-if="gotPermission === false"
                      class="text-xs inter text-center mt-4 max-w-lg mx-auto font-light">
                      To view message, you need your parent/guardian’s permission to share your information.
                    </p>
                    <div v-if="gotPermission === false" class="flex mt-4">
                      <p type="button" @click="setPermission"
                        class="text-[#259B35] inter mx-auto underline text-center text-xs cursor-pointer">
                        Send Permission Reminder
                      </p>
                    </div>

                    <div v-if="messagesPresent" class="chat_body_main">
                      <div v-for="(message, index) in messages" :key="index"
                        :class="message.sender === this.sender ? 'single_message_right' : 'single_message'">
                        <div class="message_div">
                          <p>{{ message.message }}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="gotPermission === true" class="chat_form">
                    <input v-model="messageToSend" @keyup.enter="sendSimpleMessage"
                      placeholder="Your message here" />
                    <div class="send_message">
                      <button @click="sendSimpleMessage">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                          <path
                            d="M7.65332 21.2197C7.65332 21.3575 7.72985 21.4838 7.85154 21.5482C7.97313 21.6126 8.12098 21.6043 8.23464 21.5265L10.7944 19.7787L7.65337 18.2816V21.2197H7.65332Z"
                            fill="#259B35" />
                          <path
                            d="M22.8805 1.50743C22.8102 1.44261 22.7194 1.40869 22.6277 1.40869C22.5724 1.40869 22.5172 1.42096 22.4651 1.44661L0.367267 12.2862C0.141403 12.3972 -0.00152111 12.6278 1.22156e-05 12.8801C0.00154554 13.1321 0.146886 13.3612 0.374376 13.4697L6.19831 16.2448L17.8432 6.67357L7.64126 16.9323L14.8964 20.3892C14.9856 20.4319 15.0829 20.4531 15.1791 20.4531C15.2613 20.4531 15.3426 20.4382 15.4201 20.4077C15.5891 20.3409 15.7234 20.2071 15.7904 20.0381L22.9736 1.91697C23.0305 1.77437 22.9934 1.61132 22.8805 1.50743Z"
                            fill="#259B35" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="p-5 w-full"></div> -->
      </div>

    </MainLayout>
  </div>
</template>

<script>
  import { SERVER_PUBLIC_KEY_PEM } from "../config";
  import MainLayout from "../layouts/MainLayout.vue";
  import { state } from "../store";
  import { encryptWithPublicKey } from "../services/cryptoService";
  import CustomSelect from "./Atoms/CustomSelect.vue";
  import file from "../../../k.json";

  export default {
    name: "MessagesComponent",
    components: {
      MainLayout,
      CustomSelect,
    },
    props: ["jj", "leftNavigationBarProps", "topNavigationBarProps", "broadcastFeedProps", "careerProfileFrameProps"],
    setup() {
      const store = state;
      return { store };
    },

    data() {
      return {
        ws: null, // WebSocket instance,
        messageToSend: "", //The msg the user types to send
        messagesPresent: false,
        messages: [], //msgs we get from the backend.
        surveys: [],
        providers: [],
        tabs: [
          { name: "All Forms", active: true },
          { name: "Skill Forms", active: false },
          { name: "Personality Forms", active: false },
        ],
        modal: false,
        users: [],
        singleUser: "",
        customQuestions: [
          "Inquire about tuition costs",
          "Discuss majors that fit my interests",
          "Discuss available scholarships",
          "Get advice on courses",
        ],
        stage: 0,
        currentQuestion: null,
        gotPermission: false,
        sender: "",
        receiver: "",
        selectedProvider: "",
        providerName: "",
      };
    },
    watch: {
      // "$route.query": {
      //   handler() {
      //     this.initializeProviderData();
      //   },
      //   immediate: true,
      // },
    },
    async mounted() {
      this.sender = localStorage.getItem("user.id");
      this.receiver = this.providerId;
      await this.getProviders();
      await this.getConversations();
      if (this.providerId) {
        this.receiver = this.providerId;
        await this.getMessages(this.sender, this.receiver);
      }
    },
    methods: {
      async handleSelect(e) {
        if (e.value) {
          this.providerId = e.value;
          this.receiver = e.value;
          this.providerName = e.label;
          await this.getMessages(this.sender, this.receiver);
        }
      },

      async getMessages(sender, receiver) {
        const endpoint = "/v1/messages/" + sender + "/" + receiver;
        const resp = await this.$request(endpoint, "GET");

        if (resp.status === 200 && resp?.data?.data && resp?.data?.data?.length > 0) {
          this.messages = resp.data.data;
          this.stage = 1;
          this.gotPermission = true;
          this.messagesPresent = true;
        } else {
          this.messages = [];
          this.stage = 0;
          this.gotPermission = false;
          this.messagesPresent = false;
        }
      },

      async getProviders() {
        try {
          const resp = await this.$request("/v1/providers");

          // const resp = { data: file };

          if (resp && resp.data && Array.isArray(resp.data.data)) {
            const filteredResponse = resp.data.data.filter((provider) => {
              return provider.studentID !== this.sender;
            });

            this.providers = filteredResponse.map((provider) => ({
              label: provider.name_orig,
              value: provider.id,
            }));
          } else {
            this.providers = [];
          }
        } catch (error) {
          this.providers = [];
        }
      },
      async getConversations() {
        try {
          const resp = await this.$request(`/v1/conversations/${this.sender}`);
          // const resp = {data:file};

          const conversationsData = await Promise.all(
            resp.data.data.map(async (conver) => {

              const data = {
                label: conver.name_orig,
                value: conver.id,
              };
              const endpoint = "/v1/messages/" + this.sender + "/" + conver.id;
              const msg = await this.$request(endpoint, "GET");

              if (msg?.data?.data?.length > 0) {
                data.lastMessage = msg?.data?.data.slice(-1)[0]?.message;
              }
              return data;
            })
          );


          this.users = conversationsData;
        } catch (error) {
          this.users = [];
        }
      },


      async sendSimpleMessage() {
        if (this.messageToSend.trim() === "") return;
        const payload = {
          sender: this.sender,
          receiver: this.receiver,
          message: this.messageToSend,
        };
        const resp = await this.$request("/v1/messages", "POST", payload);
        if (resp.status === 201) {
          this.messageToSend = "";
          await this.getMessages(this.sender, this.receiver);
        }
      },
      setPermission() {
        this.gotPermission = true;
      },
      async setCurrentQuestion(question) {
        this.currentQuestion = question;
        this.stage = 1;
        this.messageToSend = question;
        await this.sendSimpleMessage();
      },

      initializeProviderData() {
        this.providerId = state.providerId || "";
        this.providerName = state.providerName || "";
        this.providerEmail = state.providerEmail || "";
        this.userId = state.userId || "";
        this.avatar = state.avatar || "";
      },
      async generateKeys() {
        try {
          const keyPair = await window.crypto.subtle.generateKey(
            {
              name: "RSA-OAEP",
              modulusLength: 2048,
              publicExponent: new Uint8Array([1, 0, 1]),
              hash: "SHA-256",
            },
            true,
            ["encrypt", "decrypt"]
          );

          // Store the keyPair in data so that it can be accessed throughout the component
          this.keyPair = keyPair;

          // Now you can use this.keyPair.publicKey and this.keyPair.privateKey
        } catch (error) {}
      },
      async exportKey(key) {
        try {
          const exportedKey = await window.crypto.subtle.exportKey("spki", key);
          // 'exportedKey' contains the exported key data

          // If you need to convert the exported key to a string or some other format, you can do so here.
          // For example, you can use TextEncoder to convert to a base64-encoded string:
          const exportedKeyString = btoa(String.fromCharCode.apply(null, new Uint8Array(exportedKey)));

          return exportedKeyString; // Return the exported key as a string
        } catch (error) {}
      },
      // activateTab(index) {
      //   this.tabs.forEach((tab, tabIndex) => {
      //     tab.active = index === tabIndex;
      //   });
      // },
      async sendPublicKey(username) {
        try {
          // Export public key to spki format using Web Crypto API
          const exported = await window.crypto.subtle.exportKey("spki", this.keyPair.publicKey);
          // Convert exported key to PEM string
          const pemKey = `-----BEGIN PUBLIC KEY-----\n${Buffer.from(exported).toString(
            "base64"
          )}\n-----END PUBLIC KEY-----`;
          const chatServer = "localhost";

          const chatServerPort = "8282";

          const payload = {
            username: username,
            pub_key: pemKey,
          };

          // Send encrypted, encoded data over HTTP
          const response = await fetch("http://" + chatServer + ":" + chatServerPort + "/register", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const responseData = await response.json();
        } catch (error) {}
      },
      async connect() {
        // Connect using a socket
        const chatServer = process.env.CHAT_MESSAGE_SERVER || "localhost";
        const chatServerPort = process.env.CHAT_MESSAGE_PORT || "8181";

        try {
          this.ws = new WebSocket("wss://" + chatServer + ":" + chatServerPort);

          this.ws.onopen = async () => {
            const publicKey = await imporServerPublicKey(SERVER_PUBLIC_KEY_PEM);
            const encryptedPayload = await encryptWithPublicKey(publicKey, {
              type: "msg",
              message: "123",
            });
            const dataLength = encryptedPayload.length;

            const lengthBuffer = new Uint8Array(4); // For a 32-bit length prefix
            lengthBuffer[0] = (dataLength >>> 24) & 0xff;
            lengthBuffer[1] = (dataLength >>> 16) & 0xff;
            lengthBuffer[2] = (dataLength >>> 8) & 0xff;
            lengthBuffer[3] = dataLength & 0xff;

            const payload = new Uint8Array(4 + dataLength);
            payload.set(lengthBuffer);
            payload.set(encrypted, 4);
            ws.send(payload.buffer);
          };
          this.ws.onmessage = (event) => {
            let message = { id: Date.now(), text: event.data };
            this.messages.push(message);
          };
          this.ws.onerror = (error) => {};
          this.ws.onclose = () => {};
        } catch (error) {}
      }, //end connect
      disconnect() {
        if (this.ws) {
          this.ws.close();
        }
      },
      sendMessage() {
        try {
          if (this.ws && this.messageToSend.trim() !== "") {
            this.ws.send(this.messageToSend);
            this.messageToSend = "";
          }
        } catch (error) {}
      },
      beforeDestroy() {
        this.diconnect();
      },
    },
  };
</script>

<style scoped>
  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    height: 100vh;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 1512px;
    width: 100%;
  }

  @media all and (max-width: 900px) {
    .home-feed-student-default {
      align-items: flex-start;
      background-color: #f3f7f8;
      border: 1px none;
      display: flex;
      gap: 25px;
      height: 982px;
      overflow: hidden;
      padding: 0 18px;
      position: relative;
      max-width: 100vw !important;
    }
  }

  .flex-col-or {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
    min-height: 1579px;
    position: relative;
    width: 100%;
  }

  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    min-height: 927px;
    position: relative;
    right: 0%;
    max-width: 291px;
  }

  @media all and (max-width: 1400px) {
    .frame-container {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 30px;
      min-height: 927px;
      position: absolute;
      right: 0%;
      max-width: 291px;
    }
  }

  @media all and (max-width: 600px) {
    .frame-container {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 120px;
      min-height: 927px;
      position: absolute;
      right: 0%;
      max-width: 291px;
    }
  }

  .frame-2354 {
    align-items: center;
    display: flex;
    margin-right: 12px;
    min-width: 109px;
  }

  .bell-1 {
    height: 20px;
    width: 20px;
  }

  .cog-1 {
    height: 20px;
    margin-left: 10px;
    width: 20px;
  }

  .overlap-group {
    align-items: flex-start;

    border: 1px solid;
    border-color: var(--celeste);
    border-radius: 23px;
    display: flex;
    height: 46px;
    margin-left: 13px;
    min-width: 46px;
    padding: 10px 11px;
  }

  .jj {
    color: var(--x475f4a);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-m);
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    min-height: 24px;
    white-space: nowrap;
  }

  /* .frame-2342 {
  
  } */

  .posts-feeed::-webkit-scrollbar {
    display: none;
  }

  .chat {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }

  .chat_users {
    width: 30%;
    overflow: hidden;
    background: white;
    height: calc(100vh - 140px);
    border-radius: 30px;
    padding: 10px 10px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  }

  .chat_main {
    width: 100%;
    overflow: hidden;
  }

  .chat_user_heading {
    height: 50px;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .chat_user_heading h1 {
    color: #000;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    gap: 8px;
    line-height: normal;
  }

  .user_heading {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    margin-top: 30px;
    gap: 8px;
    padding-bottom: 10px;
    border-bottom: 1px solid #259b3521;
    cursor: pointer;
  }

  .user_heading img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  .user_heading h1 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .user_heading p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .scrollable {
    margin-top: 10px;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 100px
  }

  .chat_body {
    width: 70%;
    overflow: hidden;
    height: calc(100vh - 140px);
    border-radius: 30px;
    background: white;
    padding: 10px 10px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05); 
  }

  .chat_body_user_heading {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-top: 30px;
    gap: 8px;
    padding-bottom: 10px;
  }

  .chat_body_user_heading img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .chat_body_user_heading h1 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .chat_body_user_heading p {
    color: #ccc;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .chat_body_user_heading p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .chat_body_main {
    border-radius: 30px;
    background: #f3f7f8;
    width: 100%;
    height: calc(100vh - 345px);
    overflow: hidden;
    overflow-y: scroll;
    padding: 0px 20px;
  }

  .chat_form {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 100px;
    background: #f3f7f8;
 
  }

  .chat_form input {
    background: #f3f7f8;
    width: 90%;
    height: 100%;
    border-radius: 100px;
    padding: 0px 20px;
    border: 1px solid transparent;
  }

  .chat_form .send_message {
    height: 100%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chat_form .send_message button {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    flex-shrink: 0;
    justify-content: center;

    align-items: center;
    border: 1px solid transparent;
  }

  .single_message {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    margin-top: 15px;
  }

  .single_message img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .single_message .message_div_pre_message {
    padding: 0px 20px;
    height: 100%;
    display: flex;
    align-items: center;
    display: inline-flex;
    color: #475f4a;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: red;
    border-radius: 100px;
    background: rgba(37, 155, 53, 0.13);
  }

  .single_message .message_div {
    padding: 0px 20px;
    height: 100%;
    display: flex;
    align-items: center;
    display: inline-flex;
    color: #475f4a;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: red;
    border-radius: 100px;
    background: rgba(37, 155, 53, 0.13);
  }


  .single_message_right {
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    height: 40px;
  }

  .single_message_right img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .single_message_right .message_div {
    padding: 0px 20px;
    height: 100%;
    display: flex;
    align-items: center;
    display: inline-flex;
    color: #475f4a;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-radius: 100px;
    background: #e4e4e4;
  }

  .message_div_pre_message:hover {
    background-color: #259b35;
    color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 2px solid white;
  }

  .message_div_pre_message:hover #message_icon {
    display: flex;
  }

  .message_div:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .message_div:hover #message_icon {
    display: flex;
  }

  .inter {
    font-family: Inter;
  }
</style>
