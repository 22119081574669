<template>
  <div class="rounded-[50px] p-8 bg-white shadow-3xl space-y-4 mb-6 mt-5">
    <h3 class="text-xl font-medium text-black">Athletics</h3>
    <div class="p-4">
      <div class="">
        <div class="text-[15px] p-3 text-[#B4ACAC]">{{title}}</div>
        <ul class="divide-y">
          <li v-for='item in listItems' class="font-[500] text-[15px] p-3">{{item}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        listItems: ["Basketball", "Baseball", "Tennis" ,"Golf",  "Swim", "Polo"],
        title: "Sport Name"
      };
    },
  };
</script>
<style scoped>
</style>