<template>
    <div class="w-full p-5">
        <h2 class="text-lg font-semibold mb-5">Related Occupations</h2>
        <ul class="w-full list-disc grid grid-cols-2 gap-x-10 gap-y-5 text-sm" v-if="relatedOccupations && relatedOccupations.length>0">

            <li v-for="el in relatedOccupations" :key="el['O*NET-SOC Code']" >
                <a :href="'/#/occupation/'+el['Related O*NET-SOC Code']+'?tab=0'" target="_blank" class="hover:font-medium hover:underline hover:text-brand-blue cursor-pointer transition-all duration-150">
                    {{ el["Related Title"] }}
                </a>
            </li>
        </ul>
        <p class="p-10 flex items-center justify-center w-full text-lg font-medium text-gray-700" v-else>
            No Data Found !!
        </p>
    </div>
</template>
<script>
import relatedOCC from "../../assets/js/filtered_occupation/related_Occ/relatedOCC.json"
export default{
    props:["OnetCode"],
    computed:{
        relatedOccupations(){
            return relatedOCC.filter(el => el["O*NET-SOC Code"] === this.OnetCode)
        }
    }

}
</script>