<template>
  <div class="careers">
    <div class="main">
      <!-- Phase 1 -->
      <div class="top_links">
        <div class="headings">
          
          <router-link to="/homeFeed">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="47"
              viewBox="0 0 18 47"
              fill="none"
            >
              <path
                d="M2.35312 24.2803L13.3969 34.9287C13.8375 35.3602 14.55 35.3602 14.9906 34.9287L15.6562 34.277C16.0969 33.8455 16.0969 33.1479 15.6562 32.7164L6.06562 23.5L15.6469 14.2836C16.0875 13.8522 16.0875 13.1545 15.6469 12.7231L14.9812 12.0713C14.5406 11.6398 13.8281 11.6398 13.3875 12.0713L2.34375 22.7197C1.9125 23.1512 1.9125 23.8488 2.35312 24.2803Z"
                fill="#A5AC9D"
              />
            </svg>
            Career Profile
        </router-link>

          <button class="share">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <g clip-path="url(#clip0_190_1456)">
                <path
                  d="M17.34 7.59L13.29 11.775C13.14 11.925 12.945 12 12.75 12C12.555 12 12.375 11.9325 12.225 11.79C11.925 11.505 11.9175 11.025 12.21 10.7325L16.065 6.7575H9.75C8.5125 6.7575 7.5 7.77 7.5 9.0075V12.7575C7.5 13.17 7.1625 13.5075 6.75 13.5075C6.3375 13.5075 6 13.17 6 12.7575V9.0075C6 6.9375 7.68 5.2575 9.75 5.2575H16.065L12.21 1.275C11.925 0.974996 11.925 0.502496 12.225 0.217496C12.525 -0.0675042 12.9975 -0.0675042 13.2825 0.232496L17.34 4.425C18.21 5.295 18.21 6.72 17.3325 7.5975L17.34 7.59ZM11.1825 12.8625C10.905 12.5925 9.78 11.1 9 10.05C9 10.05 9 11.55 9 12.66C9 13.77 8.265 14.745 7.2 14.955C5.76 15.2325 4.5 14.1375 4.5 12.75V9C4.5 7.53 5.1075 6.2025 6.0825 5.25H3.75C1.68 5.25 0 6.93 0 9V14.25C0 16.32 1.68 18 3.75 18H9C11.07 18 12.75 16.32 12.75 14.25V13.5C12.165 13.5 11.6025 13.275 11.1825 12.8625Z"
                  fill="#475F4A"
                />
              </g>
              <defs>
                <clipPath id="clip0_190_1456">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Share
          </button>

          <button class="share">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
            >
              <g clip-path="url(#clip0_190_1460)">
                <path
                  d="M10.6667 3.3332C10.6667 3.16094 10.5972 2.99414 10.4722 2.87109L7.75278 0.191406C7.62778 0.0683594 7.45833 0 7.28056 0H7.11111V3.5H10.6667V3.3332ZM15.8611 8.42188L13.2028 5.78594C12.9222 5.50977 12.4417 5.70391 12.4417 6.09492V7.875H10.6639V9.625H12.4417V11.4078C12.4417 11.7988 12.9222 11.993 13.2028 11.7168L15.8611 9.07812C16.0444 8.89766 16.0444 8.60234 15.8611 8.42188ZM5.33333 9.1875V8.3125C5.33333 8.07187 5.53333 7.875 5.77778 7.875H10.6667V4.375H6.88889C6.52222 4.375 6.22222 4.07969 6.22222 3.71875V0H0.666667C0.297222 0 0 0.292578 0 0.65625V13.3438C0 13.7074 0.297222 14 0.666667 14H10C10.3694 14 10.6667 13.7074 10.6667 13.3438V9.625H5.77778C5.53333 9.625 5.33333 9.42813 5.33333 9.1875Z"
                  fill="#475F4A"
                />
              </g>
              <defs>
                <clipPath id="clip0_190_1460">
                  <rect width="16" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Export
          </button>
        </div>
        <div class="reset">
          <button>Reset Reset to Default</button>
        </div>
      </div>

      <!-- Phase 2 -->
      <div class="phase_2">
        <div class="three_cards">
          <div class="map">
            <div class="radar-chart inter-light-black-8px">
              <div class="flex-col-10">
                <div class="conventional">Conventional</div>
                <div class="enterprising">Enterprising</div>
              </div>
              <div class="flex-col-11">
                <div class="realistic">Realistic</div>
                <div class="overlap-HomeFeedStudentDefaultgroup1-1">
                  <div class="overlap-group-6">
                    <img
                      class="polygon-4"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-4.svg"
                      alt="Polygon 4"
                    />
                    <img
                      class="polygon-5"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-5.svg"
                      alt="Polygon 5"
                    />
                    <img
                      class="polygon-6"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-6.svg"
                      alt="Polygon 6"
                    />
                    <img
                      class="polygon-7"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-7.svg"
                      alt="Polygon 7"
                    />
                    <img
                      class="polygon-8"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/polygon-8.svg"
                      alt="Polygon 8"
                    />
                    <img
                      class="vector-23"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-23.svg"
                      alt="Vector 23"
                    />
                    <img
                      class="vector-24"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-24.svg"
                      alt="Vector 24"
                    />
                    <img
                      class="vector-25"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-25.svg"
                      alt="Vector 25"
                    />
                    <img
                      class="vector-26"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-26.svg"
                      alt="Vector 26"
                    />
                    <img
                      class="vector-27"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-27.svg"
                      alt="Vector 27"
                    />
                    <img
                      class="vector-28"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-28.svg"
                      alt="Vector 28"
                    />
                    <img
                      class="vector-31"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/vector-31.svg"
                      alt="Vector 31"
                    />
                    <div class="investigative">Investigative</div>
                    <div class="ellipse-40"></div>
                    <div class="ellipse-44"></div>
                    <div class="ellipse-45"></div>
                    <div class="ellipse-46"></div>
                    <div class="ellipse-47"></div>
                    <div
                      class="number valign-text-middle inter-light-white-8px"
                    >
                      20
                    </div>
                    <div
                      class="number-1 valign-text-middle inter-light-white-8px"
                    >
                      40
                    </div>
                    <div
                      class="number-2 valign-text-middle inter-light-white-8px"
                    >
                      60
                    </div>
                    <div
                      class="number-3 valign-text-middle inter-light-white-8px"
                    >
                      80
                    </div>
                    <div
                      class="number-4 valign-text-middle inter-light-white-8px"
                    >
                      100
                    </div>
                  </div>
                  <div class="artistic">Artistic</div>
                </div>
                <div class="social">Social</div>
              </div>
            </div>
          </div>
          <div class="education">
            <p>Career Cluster</p>
            <h1>Education</h1>
            <h2>78% Match</h2>
            <button>New 4 Year Pathway</button>
          </div>
          <div class="education">
            <p>Pathway</p>
            <h1>Training</h1>
            <h2>88% Match</h2>
          </div>
        </div>
        <div class="single_card">
          <label for="">Plan of Action</label>
          <div class="input_field">
            <div class="main_check">
              <input type="checkbox" class="custom-checkbox" name="" id="" />
              <label for="">Direct to workforce</label>
            </div>
            <div class="main_check">
              <input type="checkbox" name="" id="" />
              <label for="">Military</label>
            </div>
            <div class="main_check">
              <input type="checkbox" name="" id="" />
              <label for="">Tech School/ Career Center</label>
            </div>
            <div class="main_check">
              <input type="checkbox" name="" id="" />
              <label for="">Vocational Training</label>
            </div>
            <div class="main_check">
              <input type="checkbox" name="" id="" />
              <label for="">2 Year College</label>
            </div>
            <div class="main_check">
              <input type="checkbox" name="" id="" />
              <label for="">4 Year College or University</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Phase 3 -->

      <div class="main_heading">
        <h1>Occupations</h1>
      </div>
      <div class="posts-feeed_two">
        <div class="line_one">
        <!-- Cards here -->
          <div class="p-4 parent_survey">
            <div
              class="recent-survey px-3 py-2 ring-1 ring-gray-300 inline-flex rounded-sm"
            >
              <div class="recent-survey-main">
                <div class="recent-survey-heading flex items-start gap-2">
                  <img
                    class="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1682685797332-e678a04f8a64?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                    alt=""
                  />
                  <h1>Technical Education</h1>
                </div>
                <div class="recent-survey-body">
                  <div class="recent-survey-para">
                    <p>
                      Lorem ipsum dolor sit amet, consec sectetur adipiscing
                      elit,
                    </p>
                  </div>
                  <div class="completed_btn">
                    <h1>50% Match</h1>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_724_1163)">
                          <path
                            d="M1.74726 2.20428C3.66328 0.57303 6.52851 0.843734 8.30742 2.67889L9 3.39608L9.69257 2.68241C11.1269 1.19881 14.0203 0.305843 16.2527 2.20428C18.4605 4.08866 18.5766 7.47069 16.6008 9.50975L9.79804 16.534C9.58007 16.759 9.29179 16.875 9.00351 16.875C8.71523 16.875 8.42695 16.7625 8.20898 16.534L1.40625 9.50975C-0.576567 7.47069 -0.46055 4.08866 1.74726 2.20428ZM2.20781 8.72928L8.98593 15.7535L15.7922 8.72928C17.1422 7.33709 17.4234 4.6828 15.5215 3.06209C13.5949 1.41678 11.3309 2.60858 10.5012 3.46639L9 5.01678L7.49882 3.46639C6.6832 2.62264 4.39804 1.42733 2.47851 3.06209C0.580074 4.67928 0.857807 7.33358 2.20781 8.72928Z"
                            fill="#475F4A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_724_1163">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="matrix(-1 0 0 1 18 0)"
                            />
                          </clipPath>
                        </defs></svg
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Phase 4 -->
      <div class="main_heading">
        <h1>Opportunities</h1>
      </div>
      <div class="posts-feeed_two">
        <div class="line_one">
        <!-- Cards here -->
          <div class="p-4 parent_survey">
            <div
              class="recent-survey px-3 py-2 ring-1 ring-gray-300 inline-flex rounded-sm"
            >
              <div class="recent-survey-main">
                <div class="recent-survey-heading flex items-start gap-2">
                  <img
                    class="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1682685797332-e678a04f8a64?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                    alt=""
                  />
                  <h1>Technical Education</h1>
                </div>
                <div class="recent-survey-body">
                  <div class="recent-survey-para">
                    <p>
                      Lorem ipsum dolor sit amet, consec sectetur adipiscing
                      elit,
                    </p>
                  </div>
                  <div class="completed_btn">
                    <h1>50% Match</h1>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_724_1163)">
                          <path
                            d="M1.74726 2.20428C3.66328 0.57303 6.52851 0.843734 8.30742 2.67889L9 3.39608L9.69257 2.68241C11.1269 1.19881 14.0203 0.305843 16.2527 2.20428C18.4605 4.08866 18.5766 7.47069 16.6008 9.50975L9.79804 16.534C9.58007 16.759 9.29179 16.875 9.00351 16.875C8.71523 16.875 8.42695 16.7625 8.20898 16.534L1.40625 9.50975C-0.576567 7.47069 -0.46055 4.08866 1.74726 2.20428ZM2.20781 8.72928L8.98593 15.7535L15.7922 8.72928C17.1422 7.33709 17.4234 4.6828 15.5215 3.06209C13.5949 1.41678 11.3309 2.60858 10.5012 3.46639L9 5.01678L7.49882 3.46639C6.6832 2.62264 4.39804 1.42733 2.47851 3.06209C0.580074 4.67928 0.857807 7.33358 2.20781 8.72928Z"
                            fill="#475F4A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_724_1163">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="matrix(-1 0 0 1 18 0)"
                            />
                          </clipPath>
                        </defs></svg
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 
export default {
  name: "ScholarPathCareerDefault",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style scoped>
.careers {
  width: 100%;
  min-height: 100vh;
  background-color: #f9fafb;
}
.main {
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 10px;
}

.top_links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.headings {
  display: flex;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}
.headings a {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #141414;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.headings .share {
  border-radius: 100px;
  background: #f0f4f0;
  display: inline-flex;
  padding: 5px 20px;
  align-items: flex-start;
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 10px;
  border: 1px solid transparent;
}

.reset button {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  border: 1px solid transparent;

  font-style: normal;
  display: inline-flex;
  border-radius: 100px;
  background: #f0f4f0;
  padding: 5px 20px;
  align-items: flex-start;
  gap: 10px;
  font-weight: 500;
  line-height: normal;
}

.phase_2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 70px;
}
.three_cards {
  width: 50%;
  height: 180px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.three_cards .education {
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.three_cards .education {
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.map {
  width: 33%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.three_cards .education p {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.three_cards .education h1 {
  color: #259b35;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.three_cards .education h2 {
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.three_cards .education button {
  border-radius: 100px;
  background: #259b35;
  width: 191px;

  color: #fff;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid transparent;
  line-height: normal;
  height: 36px;
  flex-shrink: 0;
}

.single_card {
  width: 678px;
  height: 191px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0.5px solid #475f4a;
  
  flex-shrink: 0;
  padding: 20px 40px;
}

@media all and (max-width: 1000px) {
  .three_cards {
    width: 100% !important;
    height: 180px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .single_card {
    width: 100%;
    height: 191px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 0.5px solid #475f4a;
    background: #fff;
    flex-shrink: 0;
    padding: 20px 40px;
  }

  .phase_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 70px;
    gap: 100px;
  }
}

.single_card label {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.input_field {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}

.main_check {
  display: flex;
  align-items: center;
  gap: 8px;
}
.main_check label {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media all and (max-width: 600px) {
  .three_cards {
    width: 100% !important;
    height: 500px;
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .single_card {
    width: 100%;
    height: 280px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 0.5px solid #475f4a;
    background: #fff;
    flex-shrink: 0;
    padding: 20px 40px;
  }

  .phase_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 70px;
    gap: 100px;
  }
}

.posts-feeed_two {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  margin-top: 40px;

  flex-wrap: wrap;

  overflow-x: scroll;
  position: relative;
  width: 100%;
}

.feed_card {
  border-radius: 20px;
  border: 0.5px solid #ccc;
  background: #fff;
  padding: 30px 20px;
  width: 100%;
}

.headings_card {
  display: flex;
  align-items: center;
  gap: 8px;
}

.heading_image img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.heading_texts h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.heading_texts p {
  color: #ccc;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 2px;
  line-height: normal;
}

.paragraph_card {
  margin-top: 20px;
  margin-bottom: 30px;
}
.paragraph_card p {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.card_buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card_buttons .link {
  padding: 10px 20px !important;
  border-radius: 100px;
  background: #259b35 !important;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  display: block;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  margin-left: auto;
}

.card_social {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
  justify-content: flex-end;
}

 
/* Base Styles */
.p-4 {
  padding: 20px 10px;
  border-radius: 10px;
}

.recent-survey {
  padding: 0.5rem 0.75rem;

  display: inline-flex;
}

.recent-survey-main {
  display: flex;
  flex-direction: column;
}

.recent-survey-heading {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.recent-survey-heading img {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.recent-survey-heading h1 {
  margin: 0;
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-xs {
  font-size: 0.75rem;
}

.recent-survey-body {
  margin-top: 0.5rem;
}

.recent-survey-para p {
  color: #000;
  font-family: Inter;
  margin-top: 15px;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.recent-survey-buttons {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.ml-auto {
  margin-left: auto;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.w-full {
  width: 100%;
}

.h-5 {
  height: 1.25rem;
}

.overflow-hidden {
  overflow: hidden;
}

.bg-red-500 {
}

.bg-red-900 {
}

.parent_survey {
  min-width: 300px;
  min-height: 200px;
  flex-shrink: 1;
  display: inline-flex;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.main_percentage {
  border-radius: 20px !important;
  overflow: hidden;
  width: 100%;
  height: 16px;
  background-color: #f3f7f8;
}

.main_percentage_one {
  border-radius: 20px !important;
  background-color: #259b35;
  height: 100%;
  width: 65%;
}
.time {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.flex_col_btn {
  flex-direction: column;
}
.percentage_num {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.top_heading {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.line_one {
  display: flex;
  gap: 30px;
}

.top_heading_btn_top {
  border-radius: 100px;
  border: 1px solid #259b35;
  background: #fff;
  color: #259b35;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.top_heading_btn_top_survey {
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tab_main {
  width: 100%;

  border-radius: 20px;
}
.tabs {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  height: 34px;

  border-radius: 100px;
  background: #f3f7f8;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: space-between;
}
.one {
  text-align: center;
  width: 100%;
  border-radius: 100px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  color: #259b35;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.inactive {
  text-align: center;
  width: 100%;
  border-radius: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #a5ac9d;
  font-family: Inter;
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.completed_btn {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
}
.completed_btn h1 
{
  color:  #475F4A;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
 
.main_heading {
  margin-top: 120px;
}
.main_heading h1 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
