<script setup>
  const props = defineProps({
    height: {
      type: Number,
      default: 26,
    },
    width: {
      type: Number,
      default: 26,
    },
  });
</script>

<template>
  <svg :width="props.width" :height="props.width" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1952_4105)">
      <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.00563272 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98954 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9963 9.55333 24.6254 6.24889 22.1883 3.81173C19.7511 1.37456 16.4467 0.00372784 13 0ZM17.3333 14.0833H14.0833V17.3333C14.0833 17.6207 13.9692 17.8962 13.766 18.0994C13.5629 18.3025 13.2873 18.4167 13 18.4167C12.7127 18.4167 12.4371 18.3025 12.234 18.0994C12.0308 17.8962 11.9167 17.6207 11.9167 17.3333V14.0833H8.66667C8.37936 14.0833 8.1038 13.9692 7.90064 13.766C7.69748 13.5629 7.58334 13.2873 7.58334 13C7.58334 12.7127 7.69748 12.4371 7.90064 12.234C8.1038 12.0308 8.37936 11.9167 8.66667 11.9167H11.9167V8.66667C11.9167 8.37935 12.0308 8.1038 12.234 7.90063C12.4371 7.69747 12.7127 7.58333 13 7.58333C13.2873 7.58333 13.5629 7.69747 13.766 7.90063C13.9692 8.1038 14.0833 8.37935 14.0833 8.66667V11.9167H17.3333C17.6207 11.9167 17.8962 12.0308 18.0994 12.234C18.3025 12.4371 18.4167 12.7127 18.4167 13C18.4167 13.2873 18.3025 13.5629 18.0994 13.766C17.8962 13.9692 17.6207 14.0833 17.3333 14.0833Z" fill="#259B35"/>
    </g>
    <defs>
      <clipPath id="clip0_1952_4105">
        <rect width="26" height="26" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>