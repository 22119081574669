<template>
  <div class="wrapper">
    <div class="h-full max-h-full p-6 border border-gray-100 shadow rounded-[50px] overflow-y-scroll">
      <table class="w-full">
        <thead>
          <tr>
            <th
              v-for="(col, index) in colNames"
              :key="index"
              class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pb-2"
            >
              {{ col }}
            </th>
          </tr>
        </thead>
        <tbody v-if="onetData.length > 0">
          <tr v-for="d in onetJobs" :key="d" @click="$emit('update', d)">
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ d["O*NET-SOC Code"] }}
            </td>
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ d["Title"] }}
            </td>
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ d["Job Zone"] }}
            </td>
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ d["Domain Source"] }}
            </td>
          </tr>
        </tbody>
        <div class="w-full p-5 flex items-center justify-center font-semibold" v-else>No Job Found !</div>
      </table>
    </div>
    <div class="w-full p-5"></div>
  </div>
</template>

<script setup>
  import onetData from "../../assets/js/onet_job_zones/job_zones.json";
  import { getAssociatedData } from "../../AssociatedData.js";
  import { onMounted, ref } from "vue";
  const rawCodes = ref([]);
  const jobcodes = ref([]);
  const onetJobs = ref([]);
  const colNames = ref(["Onet Code", "Title", "Job Zone", "Domain Source"]);

  const getJobCode = (s, separator) => {
    const words = s.split(separator);
    const code = words[0];
    return code;
  };

  const truncateJobCodes = (codes) => {
    return codes.map((code) => getJobCode(code, "."));
  };

  const getJobs = (jobcodes) => {
    return onetData.filter((element) => jobcodes.includes(getJobCode(element["O*NET-SOC Code"], "-")));
  };

  onMounted(async () => {
    const assocData = await getAssociatedData();
    if (assocData) {
      rawCodes.value = assocData.jobCodes;
      jobcodes.value = truncateJobCodes(rawCodes.value);
      onetJobs.value = getJobs(jobcodes.value);
    }
  });
</script>
<style scoped>
  /* @import url("./table.css"); */

  .curved-button {
    background-color: #259b35;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 5px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .curved-button:hover {
    background-color: #1f7a2d;
  }
</style>
