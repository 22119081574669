<template>
  <svg :width="props.width" :height="props.height" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.830174 8.40254L9.41976 0.24629C9.76247 -0.0841791 10.3166 -0.084179 10.6593 0.24629L11.1771 0.745508C11.5198 1.07598 11.5198 1.61035 11.1771 1.94082L3.71767 9.00019L11.1698 16.0596C11.5125 16.39 11.5125 16.9244 11.1698 17.2549L10.6521 17.7541C10.3093 18.0846 9.75518 18.0846 9.41247 17.7541L0.822882 9.59785C0.487466 9.26738 0.487466 8.73301 0.830174 8.40254Z" fill="black"/>
  </svg>
</template>

<script setup>
const props = defineProps({
  height: {
    type: Number,
    default: 18,
  },
  width: {
    type: Number,
    default: 18,
  },
});
</script>

<style scoped>

</style>