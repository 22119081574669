<template>
  <div class="bg-[#F3F7F8]">
    <component :is="$route.meta.layout || 'div'"></component>
    <Footer />
    <CustomAlert :message="alertMessage" v-if="isAlertVisible" @closeAlert="closeAlert" />
  </div>
</template>

<script setup>
  import { onBeforeUnmount, onMounted, ref } from "vue";
  import Footer from "./components/Footer.vue";
  import CustomAlert from "./components/Atoms/CustomAlert.vue";

  const isAlertVisible = ref(false);
  const alertMessage = ref("");

  const handleShowAlert = (event) => {
    alertMessage.value = event.detail.message;
    isAlertVisible.value = true;
  };

  const closeAlert = () => {
    isAlertVisible.value = false;
  };

  onMounted(() => {
    const app = document.querySelector("#app");
    if (app) {
      app.addEventListener("show-alert", handleShowAlert);
    }
  });

  onBeforeUnmount(() => {
    const app = document.querySelector("#app");
    if (app) {
      app.removeEventListener("show-alert", handleShowAlert);
    }
  });
</script>
