<script setup>
import { ref, defineEmits } from 'vue';


const showPopup = ref(true);
const step = ref(1);


const emit = defineEmits(['welcomeMessageClosed']);


const changeStep = (val) => step.value = val;


const closeModal = (settings) => {
  console.log({ settings });
  emit("welcomeMessageClosed"); 
}

import SPModal from '../common/SPModal.vue';
import Welcome from './Welcome.vue';
import MessageSettings from './MessageSettings.vue';
</script>

<template>
  <SPModal v-model="showPopup" @close="$emit('welcomeMessageClosed')">
    <div class="w-[95vw] md:w-[70vw] p-[20px]">
      <div v-if="step === 1">
        <div class="flex justify-center text-[28px] text-[#259B35]">
          WELCOME TO SCHOLARPATH
        </div>
        <Welcome @continue="changeStep(2)" />
      </div>
      <div v-else-if="step === 2">
        <div class="flex justify-center text-[28px] text-[#259B35]">
          MESSAGING
        </div>
        <MessageSettings @continue="closeModal" />
      </div>
    </div>
  </SPModal>
</template>

<style lang="css" scoped>
.welcome-detail {
  @apply mt-5 text-[18px] text-[#475F4A] font-light;
}
</style>