import { defineStore } from "pinia";

export const useStudentDemo = defineStore("studentDemo", {
  state: () => ({
    isStudentDemo: false,
    isProviderBusiness: false,
  }),
  actions: {
    setIsStudentDemo(value) {
      this.isStudentDemo = value;
    },
    setIsProviderBusiness(value) {
      this.isProviderBusiness = value;
    },
  },
});
