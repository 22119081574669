<script setup>
import { computed } from 'vue';

const props = defineProps({
  totalRecord: Number,
  currentPage: Number,
  itemsPerPage: {
    type: Number,
    default: 10,
  },
});

const emit = defineEmits(['update:currentPage']);

const totalPages = computed(() => Math.ceil(props.totalRecord / props.itemsPerPage));

const range = computed(() => {
  const delta = 2;
  const range = [];
  const start = Math.max(1, props.currentPage - delta);
  const end = Math.min(totalPages.value, props.currentPage + delta);

  for (let i = start; i <= end; i++) {
    range.push(i);
  }

  if (start > 1) {
    range.unshift(1);
    if (start > 2) {
      range.splice(1, 0, '...');
    }
  }

  if (end < totalPages.value) {
    range.push(totalPages.value);
    if (end < totalPages.value - 1) {
      range.splice(range.length - 1, 0, '...');
    }
  }

  return range;
});

const isPaginationValid = computed(() => {
  return props.totalRecord > 0 && props.currentPage > 0 && props.itemsPerPage > 0;
});

const changePage = (page) => {
  if (page !== '...' && page !== props.currentPage) {
    emit('update:currentPage', page);
  }
};

const previousPage = () => {
  if (props.currentPage > 1) {
    emit('update:currentPage', props.currentPage - 1);
  }
};

const nextPage = () => {
  if (props.currentPage < totalPages.value) {
    emit('update:currentPage', props.currentPage + 1);
  }
};
</script>

<template>
  <div class="flex justify-end items-center space-x-2">
    <button @click="previousPage" :disabled="currentPage === 1" class="px-4 py-1 rounded-full bg-primary text-white font-medium" :class="{ 'bg-primary-light !text-gray-400': currentPage === 1 || !isPaginationValid }">Previous</button>
    <div class="flex items-center space-x-2">
      <button v-for="page in range" :key="page" @click="changePage(page)" class="px-4 py-1 rounded-full bg-primary text-white font-light" :class="{ 'bg-primary-light !text-gray-400': page === currentPage || !isPaginationValid || page === '...' }">{{ page }}</button>
    </div>
    <button @click="nextPage" :disabled="currentPage === totalPages.value" class="px-4 py-1 rounded-full bg-primary text-white font-medium" :class="{ 'bg-primary-light !text-gray-400': currentPage === totalPages.value || !isPaginationValid }">Next</button>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>