<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/attributes-order -->
<!-- Checkbox.vue -->
<template>
  <div class="flex items-center">
    <input
      type="checkbox"
      :id="id"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
      class="hidden"
    />
    <label :for="id" class="flex items-center cursor-pointer">
      <div class="w-6 h-6 rounded-full border-2 border-green-500 flex items-center justify-center">
        <div v-if="modelValue" class="w-3 h-3 bg-green-500 rounded-full"></div>
      </div>
      <span :class="computedClass">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script>
  export default {
    name: "Checkbox",
    props: {
      weight: { type: Number, default: 700 },
      modelValue: {
        type: Boolean,
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: () => `checkbox-${Math.random().toString(36).substr(2, 9)}`,
      },
    },
    computed: {
      computedClass() {
        return `ml-4 text-gray-${this.weight}`;
      },
    },
  };
</script>

<style scoped>
  /* Custom styles for the checkbox */
</style>
