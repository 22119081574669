<template v-if="isDataFetched">
  <div class="w-full flex rounded-2xl p-2 main_div">
    <!-- Popup End -->
    <div v-if="skillModal" class="backdrop fixed inset-0 bg-black/50 z-40" @click="setSkillModel(null)"></div>
    <div v-if="skillModal" class="modal-container flex justify-center items-center">
      <div class="modal-content w-96 h-44 bg-white rounded-2xl flex flex-col items-center py-4 px-4">
        <div v-if="modelNum === 1" class="w-full flex flex-col items-center">
          <h1 class="inter mt-4 text-[20px] font-medium">Skills</h1>
          <div class="w-full my-4 h-8 flex items-center mx-auto rounded-full px-4 bg-[#259B35]/10">
            <input v-model="skillModel" class="w-full inter text-[#259B35] pl-2 h-full bg-transparent" />
          </div>
          <button
            class="px-5 mx-auto py-2 bg-[#259B35] text-white inter rounded-full"
            @click="setHobbiesAndSkills('skills')"
          >
            Save
          </button>
        </div>
        <div v-else-if="modelNum === 2" class="w-full flex flex-col items-center">
          <h1 class="inter mt-4 text-[20px] font-medium">Hobbies / Interests</h1>
          <div class="w-full my-4 h-8 flex items-center mx-auto rounded-full px-4 bg-[#259B35]/10">
            <input v-model="hobbyModel" class="w-full inter text-[#259B35] pl-2 h-full bg-transparent" />
          </div>
          <button
            class="px-5 mx-auto py-2 bg-[#259B35] text-white inter rounded-full"
            @click="setHobbiesAndSkills('hobbies')"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <!-- Popup End -->

    <!-- left side bar start-->
    <div class="w-1/5 flex-none overflow-auto border-r-2 pb-3 first_div">
      <h1>{{ name ? name : "None" }}</h1>
      <div class="personal_info">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path
              d="M29.4316 11.1797C29.6602 10.998 30 11.168 30 11.4551V23.4375C30 24.9902 28.7402 26.25 27.1875 26.25H2.8125C1.25977 26.25 0 24.9902 0 23.4375V11.4609C0 11.168 0.333984 11.0039 0.568359 11.1855C1.88086 12.2051 3.62109 13.5 9.59766 17.8418C10.834 18.7441 12.9199 20.6426 15 20.6309C17.0918 20.6484 19.2188 18.709 20.4082 17.8418C26.3848 13.5 28.1191 12.1992 29.4316 11.1797ZM15 18.75C16.3594 18.7734 18.3164 17.0391 19.3008 16.3242C27.0762 10.6816 27.668 10.1895 29.4609 8.7832C29.8008 8.51953 30 8.10938 30 7.67578V6.5625C30 5.00977 28.7402 3.75 27.1875 3.75H2.8125C1.25977 3.75 0 5.00977 0 6.5625V7.67578C0 8.10938 0.199219 8.51367 0.539062 8.7832C2.33203 10.1836 2.92383 10.6816 10.6992 16.3242C11.6836 17.0391 13.6406 18.7734 15 18.75Z"
              fill="#259B35"
            />
          </svg>
          <p v-if="store.isEmail" class="text-[13px]">
            {{ email }}
          </p>
          <p v-else class="text-[13px]">--</p>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="30" viewBox="0 0 38 30" fill="none">
            <path
              d="M36.347 9.02288L20.6126 4.00256C19.5552 3.66565 18.4454 3.66565 17.388 4.00256L1.65359 9.02288C0.649563 9.34338 0 10.2176 0 11.25C0 12.2824 0.649563 13.1567 1.653 13.4772L3.41703 14.0397C3.22109 14.4018 3.07622 14.7914 2.98181 15.1998C2.34709 15.5033 1.9 16.1321 1.9 16.875C1.9 17.6209 2.34947 18.2531 2.98834 18.5549L1.91662 25.1092C1.82103 25.6946 2.14878 26.25 2.58994 26.25H5.00947C5.45062 26.25 5.77897 25.6946 5.68278 25.1092L4.61166 18.5549C5.25053 18.2531 5.7 17.6209 5.7 16.875C5.7 16.2539 5.37403 15.7301 4.90259 15.3885C4.99106 15.1155 5.12822 14.8694 5.29269 14.6379L8.46747 15.651L7.6 22.5C7.6 24.5713 12.7039 26.25 19 26.25C25.2961 26.25 30.4 24.5713 30.4 22.5L29.5325 15.651L36.347 13.4766C37.3504 13.1567 38 12.2824 38 11.25C38 10.2176 37.3504 9.34338 36.347 9.02288ZM28.4691 22.3746C27.8302 23.0385 24.5836 24.375 19 24.375C13.4164 24.375 10.1698 23.0385 9.53088 22.3746L10.3081 16.2381L17.388 18.4969C17.5423 18.5461 18.9151 19.0377 20.6126 18.4969L27.6925 16.2381L28.4691 22.3746ZM35.7604 11.6942L20.026 16.7145C19.3545 16.9289 18.6455 16.9289 17.974 16.7145L7.54359 13.3864L19.1746 11.2336C19.6905 11.1387 20.0302 10.6489 19.9334 10.1397C19.8372 9.62991 19.3301 9.29651 18.8254 9.39143L6.726 11.6297C5.98975 11.7656 5.33009 12.078 4.76187 12.4987L2.23903 11.6936C1.77294 11.5442 1.79609 10.9471 2.23903 10.8059L17.9734 5.78557C18.87 5.49963 19.6264 5.65842 20.0254 5.78557L35.7598 10.8059C36.1986 10.9459 36.2294 11.5442 35.7604 11.6942Z"
              fill="#259B35"
            />
          </svg>
          <p class="text-[13px]">
            {{ school ? school : "None" }}
          </p>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <g clip-path="url(#clip0_17_725)">
              <path
                d="M0 11.6667V28H28V11.6667H0ZM0 9.33333V5.83333C1.90735e-06 4.90508 0.36875 4.01484 1.02513 3.35846C1.6815 2.70208 2.57174 2.33333 3.5 2.33333H7V0H9.33333V2.33333H18.6667V0H21V2.33333H24.5C25.4283 2.33333 26.3185 2.70208 26.9749 3.35846C27.6313 4.01484 28 4.90508 28 5.83333V9.33333H0ZM7 16.3333H9.33333V18.6667H7V16.3333ZM12.8333 16.3333H15.1667V18.6667H12.8333V16.3333ZM18.6667 16.3333H21V18.6667H18.6667V16.3333ZM7 21H9.33333V23.3333H7V21ZM12.8333 21H15.1667V23.3333H12.8333V21ZM18.6667 21H21V23.3333H18.6667V21Z"
                fill="#259B35"
              />
            </g>
            <defs>
              <clipPath id="clip0_17_725">
                <rect width="28" height="28" fill="white" transform="matrix(-1 0 0 1 28 0)" />
              </clipPath>
            </defs>
          </svg>
          <p class="text-[13px]">
            {{ gradYear ? gradYear : "None" }}
          </p>
        </div>
      </div>

      <div class="personal_info">
        <div class="flex_title pr-4">
          <span class="title_t">My Skills</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            class="cursor-pointer"
            @click="setSkillModel(1)"
          >
            <g clip-path="url(#clip0_17_711)">
              <path
                d="M12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951511 7.71646C0.00541608 10.0005 -0.242126 12.5139 0.24019 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2835 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C24.9964 9.18589 23.6783 6.00855 21.3349 3.66512C18.9915 1.3217 15.8141 0.00358446 12.5 0ZM16.6667 13.5417H13.5417V16.6667C13.5417 16.9429 13.4319 17.2079 13.2366 17.4032C13.0412 17.5986 12.7763 17.7083 12.5 17.7083C12.2237 17.7083 11.9588 17.5986 11.7634 17.4032C11.5681 17.2079 11.4583 16.9429 11.4583 16.6667V13.5417H8.33334C8.05707 13.5417 7.79212 13.4319 7.59677 13.2366C7.40142 13.0412 7.29167 12.7763 7.29167 12.5C7.29167 12.2237 7.40142 11.9588 7.59677 11.7634C7.79212 11.5681 8.05707 11.4583 8.33334 11.4583H11.4583V8.33333C11.4583 8.05707 11.5681 7.79211 11.7634 7.59676C11.9588 7.40141 12.2237 7.29167 12.5 7.29167C12.7763 7.29167 13.0412 7.40141 13.2366 7.59676C13.4319 7.79211 13.5417 8.05707 13.5417 8.33333V11.4583H16.6667C16.9429 11.4583 17.2079 11.5681 17.4032 11.7634C17.5986 11.9588 17.7083 12.2237 17.7083 12.5C17.7083 12.7763 17.5986 13.0412 17.4032 13.2366C17.2079 13.4319 16.9429 13.5417 16.6667 13.5417Z"
                fill="#259B35"
              />
            </g>
            <defs>
              <clipPath id="clip0_17_711">
                <rect width="25" height="25" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div v-for="(skill, index) in skills" :key="skill" class="flex justify-between items-center pr-4">
          <p v-if="skill">
            {{ skill }}
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
            @click="removeHobbiesAndSkills('skills', index)"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
      </div>

      <div class="personal_info">
        <div class="flex_title pr-4">
          <p class="title_t">My Hobbies/ Interests</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            class="cursor-pointer"
            @click="setSkillModel(2)"
          >
            <g clip-path="url(#clip0_17_711)">
              <path
                d="M12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951511 7.71646C0.00541608 10.0005 -0.242126 12.5139 0.24019 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2835 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C24.9964 9.18589 23.6783 6.00855 21.3349 3.66512C18.9915 1.3217 15.8141 0.00358446 12.5 0ZM16.6667 13.5417H13.5417V16.6667C13.5417 16.9429 13.4319 17.2079 13.2366 17.4032C13.0412 17.5986 12.7763 17.7083 12.5 17.7083C12.2237 17.7083 11.9588 17.5986 11.7634 17.4032C11.5681 17.2079 11.4583 16.9429 11.4583 16.6667V13.5417H8.33334C8.05707 13.5417 7.79212 13.4319 7.59677 13.2366C7.40142 13.0412 7.29167 12.7763 7.29167 12.5C7.29167 12.2237 7.40142 11.9588 7.59677 11.7634C7.79212 11.5681 8.05707 11.4583 8.33334 11.4583H11.4583V8.33333C11.4583 8.05707 11.5681 7.79211 11.7634 7.59676C11.9588 7.40141 12.2237 7.29167 12.5 7.29167C12.7763 7.29167 13.0412 7.40141 13.2366 7.59676C13.4319 7.79211 13.5417 8.05707 13.5417 8.33333V11.4583H16.6667C16.9429 11.4583 17.2079 11.5681 17.4032 11.7634C17.5986 11.9588 17.7083 12.2237 17.7083 12.5C17.7083 12.7763 17.5986 13.0412 17.4032 13.2366C17.2079 13.4319 16.9429 13.5417 16.6667 13.5417Z"
                fill="#259B35"
              />
            </g>
            <defs>
              <clipPath id="clip0_17_711">
                <rect width="25" height="25" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div v-for="(hobby, index) in hobbies" :key="hobby" class="flex justify-between pr-4">
          <p>{{ hobby }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
            @click="removeHobbiesAndSkills('hobbies', index)"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- left side bar end-->

    <div class="w-1/2 flex-grow overflow-auto second p-6">
      <div class="flex items-center justify-end">
        <button v-if="!descriptionEditable" @click="toggleEditable()" class="p-2 rounded-full bg-brand-gray">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-4 w-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
            />
          </svg>
        </button>

        <button
          v-if="descriptionEditable"
          @click="toggleEditable('save')"
          class="py-1 px-6 text-sm rounded-full bg-primary text-white"
        >
          Save
        </button>
      </div>

      <p v-if="!descriptionEditable">
        {{ bio }}
      </p>
      <textarea
        v-model="bio"
        placeholder="Write a short bio about yourself"
        v-if="descriptionEditable"
        class="block w-full resize-none py-2 h-16 rounded-md outline-none"
      />

      <div class="academics">
        <div class="flex gap-3">
          <h2><b>Academics</b></h2>
          <p class="text-[12px] text-[#636060]">
            If you've taken any of the tests below, your scores will appear here. No worries if you don't take a
            test--it won't appear on your resume.
          </p>
        </div>
        <div class="exams">
          <div class="exams_one">
            <h1 v-if="store.isSAT === true">
              {{ sat }}
            </h1>
            <h1 v-else>--</h1>
            <p>SAT</p>
          </div>
          <div class="exams_one">
            <h1 v-if="store.isASVAB === true">{{ asvab }}</h1>
            <h1 v-else>--</h1>
            <p>ASVAB</p>
          </div>
          <div class="exams_one">
            <h1 v-if="store.isACT === true">
              {{ act }}
            </h1>
            <h1 v-else>--</h1>
            <p>ACT</p>
          </div>
          <div class="exams_one">
            <h1 v-if="store.isGPA">
              {{ gpa }}
            </h1>
            <h1 v-else>--</h1>
            <p>GPA</p>
          </div>
        </div>
      </div>
      <ResumeSection
        v-if="clubsAndActivities.length > 0"
        heading="Clubs & Activities"
        :dataArray="clubsAndActivities"
      />
      <ResumeSection v-if="athletics.length > 0" heading="Athletics" :dataArray="athletics" />
      <ResumeSection v-if="experience.length > 0" heading="Experience" :dataArray="experience" />
      <ResumeSection v-if="accomplishments.length > 0" heading="Accomplishments" :dataArray="accomplishments" />
    </div>

    <!-- right sidebar start -->
    <div class="w-1/4 flex-none overflow-auto border-l-2">
      <div class="btns">
        <a href="#" class="single_btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <g clip-path="url(#clip0_189_1097)">
              <path
                d="M17.34 7.59003L13.29 11.775C13.14 11.925 12.945 12 12.75 12C12.555 12 12.375 11.9325 12.225 11.79C11.925 11.505 11.9175 11.025 12.21 10.7325L16.065 6.75753H9.75C8.5125 6.75753 7.5 7.77003 7.5 9.00753V12.7575C7.5 13.17 7.1625 13.5075 6.75 13.5075C6.3375 13.5075 6 13.17 6 12.7575V9.00753C6 6.93753 7.68 5.25753 9.75 5.25753H16.065L12.21 1.27503C11.925 0.975026 11.925 0.502526 12.225 0.217526C12.525 -0.0674737 12.9975 -0.0674737 13.2825 0.232526L17.34 4.42503C18.21 5.29503 18.21 6.72003 17.3325 7.59753L17.34 7.59003ZM11.1825 12.8625C10.905 12.5925 9.78 11.1 9 10.05C9 10.05 9 11.55 9 12.66C9 13.77 8.265 14.745 7.2 14.955C5.76 15.2325 4.5 14.1375 4.5 12.75V9.00003C4.5 7.53003 5.1075 6.20253 6.0825 5.25003H3.75C1.68 5.25003 0 6.93003 0 9.00003V14.25C0 16.32 1.68 18 3.75 18H9C11.07 18 12.75 16.32 12.75 14.25V13.5C12.165 13.5 11.6025 13.275 11.1825 12.8625Z"
                fill="#475F4A"
              />
            </g>
            <defs>
              <clipPath id="clip0_189_1097">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Share
        </a>
        <div class="single_btn" @click="downloadPDF">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
            <g clip-path="url(#clip0_189_1101)">
              <path
                d="M10.6667 3.3332C10.6667 3.16094 10.5972 2.99414 10.4722 2.87109L7.75278 0.191406C7.62778 0.0683594 7.45833 0 7.28056 0H7.11111V3.5H10.6667V3.3332ZM15.8611 8.42188L13.2028 5.78594C12.9222 5.50977 12.4417 5.70391 12.4417 6.09492V7.875H10.6639V9.625H12.4417V11.4078C12.4417 11.7988 12.9222 11.993 13.2028 11.7168L15.8611 9.07812C16.0444 8.89766 16.0444 8.60234 15.8611 8.42188ZM5.33333 9.1875V8.3125C5.33333 8.07187 5.53333 7.875 5.77778 7.875H10.6667V4.375H6.88889C6.52222 4.375 6.22222 4.07969 6.22222 3.71875V0H0.666667C0.297222 0 0 0.292578 0 0.65625V13.3438C0 13.7074 0.297222 14 0.666667 14H10C10.3694 14 10.6667 13.7074 10.6667 13.3438V9.625H5.77778C5.53333 9.625 5.33333 9.42813 5.33333 9.1875Z"
                fill="#475F4A"
              />
            </g>
            <defs>
              <clipPath id="clip0_189_1101">
                <rect width="16" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Export
        </div>
      </div>
      <Accordion
        v-if="isDataFetched"
        :assocData="data"
        :allAthletics="athletics"
        :allExperience="experience"
        :allAccomplishments="accomplishments"
        :clubsAndActivities="clubsAndActivities"
        @update-clubs-and-activities="updateClubsAndActivities"
        @update-experience="updateExperience"
        @update-accomplishments="updateAccomplishments"
        @update-athletics="updateAthletics"
        @update-academics="updateAcademics"
      />
    </div>
    <!-- right sidebar end -->
  </div>
</template>
<script>
  import { ref, onMounted } from "vue";
  import Accordion from "../../Accordion.vue";
  import { useAccordion } from "../../../store/AccordionStates";
  import html2pdf from "html2pdf.js";
  import { getAssociatedData, updateAssociatedData } from "../../../AssociatedData.js";
  import ResumeDescription from "./resumeDescription.vue";
  import ResumeSection from "./resumeSection.vue";
  import { useStudentDemo } from "../../../store/StudentDemoStates.js";
  import { defaultDataResume } from "../../../utils/studentDemoData/portfolioData.js";

  export default {
    name: "HomeFeedStudentDefault",
    components: {
      Accordion,
      ResumeDescription,
      ResumeSection,
    },

    setup() {
      const pdfForm = ref(null);

      const store = useAccordion();
      const store2 = useStudentDemo();
      const isDataFetched = ref(true);
      const email = ref("");
      const school = ref("");
      const name = ref("");
      const gradYear = ref("");
      const bio = ref("");
      const data = ref(null);

      const experience = ref([]);
      const accomplishments = ref([]);
      const clubsAndActivities = ref([]);
      const athletics = ref([]);
      const skills = ref([]);
      const skillModel = ref("");
      const skillModal = ref(false);
      const hobbies = ref([]);
      const modelNum = ref(null);
      const hobbyModel = ref("");
      const hobbyModal = ref(false);
      const gpa = ref(null);
      const sat = ref(null);
      const act = ref(null);
      const asvab = ref(null);
      const descriptionEditable = ref(false);

      const saveDataToDB = async (body) => {
        if (!store2.isStudentDemo) {
          const assocData = await getAssociatedData();

          const payload = {
            ...assocData,
            ...body,
          };
          await updateAssociatedData(payload);
        }
      };

      const toggleEditable = async (action) => {
        descriptionEditable.value = !descriptionEditable.value;
        if (action === "save" && bio.value !== "") {
          await saveDataToDB({ studentBio: bio.value });
        }
      };

      // skill and hobbies set and save to db start
      const updateList = async (list, model) => {
        if (model.value !== "") {
          if (list.value) {
            list.value.push(model.value);
            model.value = "";
            await saveDataToDB({
              skills: skills.value,
              hobbies: hobbies.value,
            });
          }
          toggleModals();
        } else {
          toggleModals();
        }
      };

      const toggleModals = () => {
        hobbyModal.value = !hobbyModal.value;
        skillModal.value = !skillModal.value;
        modelNum.value = null;
      };

      const setHobbiesAndSkills = async (type) => {
        if (type === "hobbies") {
          await updateList(hobbies, hobbyModel);
        } else if (type === "skills") {
          await updateList(skills, skillModel);
        }
      };

      const removeFromList = async (list, index) => {
        if (index > -1) {
          list.value.splice(index, 1);
          await saveDataToDB({
            skills: skills.value,
            hobbies: hobbies.value,
          });
        }
      };

      const removeHobbiesAndSkills = async (type, index) => {
        if (type === "hobbies") {
          await removeFromList(hobbies, index);
        } else if (type === "skills") {
          await removeFromList(skills, index);
        }
      };

      const setSkillModel = (num) => {
        skillModal.value = !skillModal.value;
        modelNum.value = num;
      };
      // skill and hobbies set and save to db end

      const downloadPDF = async () => {
        alert("Download PDF");
        const date = new Date();

        try {
          const element = pdfForm.value;
          const pdfOptions = {
            margin: 10,
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 100, logging: true, dpi: 100, letterRendering: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          };

          await html2pdf().from(element).save(`${date}.pdf`, pdfOptions);
        } catch (error) {}
      };

      onMounted(async () => {
        let assocData = null;

        // Check if the student is in demo mode
        if (store2.isStudentDemo) {
          assocData = defaultDataResume; // Use default data for demo mode
        } else {
          // Fetch associated data from API
          assocData = await getAssociatedData();
        }

        if (assocData) {
          data.value = assocData;
          skills.value = assocData.skills || [];
          hobbies.value = assocData.hobbies || [];
          bio.value = assocData.studentBio;
          email.value = assocData.email;
          school.value = assocData.schools;
          gradYear.value = assocData.gradYear;
          name.value = assocData?.firstName + " " + assocData?.lastName;
          asvab.value = assocData.asvab;
          gpa.value = assocData.gpa;
          act.value = assocData.act;
          sat.value = assocData.sat;
          const clubs =
            assocData.clubsAndActivities?.map((_) => ({
              title: _.name,
              description: _.desc,
              date: _.date,
            })) || [];
          const exp =
            assocData.experience?.map((_) => ({
              title: _.name,
              description: _.desc,
              date: _.date,
            })) || [];
          const accomp =
            assocData.accomplishments?.map((_) => ({
              title: _.name,
              description: _.desc,
              date: _.date,
            })) || [];
          const ath =
            assocData.athletics?.map((_) => ({
              title: _.name,
              description: _.desc,
              date: _.date,
            })) || [];
          clubsAndActivities.value = clubs;
          experience.value = exp;
          accomplishments.value = accomp;
          athletics.value = ath;
          store.setSAT(assocData.showSAT);
          store.setACT(assocData.showACT);
          store.setGPA(assocData.showGPA);
          store.setASVAB(assocData.showASVAB);
          store.setEmail(assocData.showEmail);
          store.setPhone(assocData.showPhone);
        }

        const edLinkData = JSON.parse(localStorage.getItem("edlink"));
        gpa.value = parseFloat(edLinkData.properties.custom.gpa).toFixed(2);
      });

      const updateClubsAndActivities = async (newClubsAndActivities) => {
        clubsAndActivities.value = newClubsAndActivities;

        const objForDb = JSON.parse(JSON.stringify(clubsAndActivities.value)).map((_) => ({
          name: _.title,
          desc: _.description,
          date: _.date,
        }));

        await saveDataToDB({ clubsAndActivities: objForDb });
      };
      const updateExperience = async (newExperience) => {
        experience.value = newExperience;

        const objForDb = JSON.parse(JSON.stringify(experience.value)).map((_) => ({
          name: _.title,
          desc: _.description,
          date: _.date,
        }));

        await saveDataToDB({ experience: objForDb });
      };
      const updateAccomplishments = async (newAccomp) => {
        accomplishments.value = newAccomp;

        const objForDb = JSON.parse(JSON.stringify(accomplishments.value)).map((_) => ({
          name: _.title,
          desc: _.description,
          date: _.date,
        }));
        await saveDataToDB({
          accomplishments: objForDb,
        });
      };
      const updateAthletics = async (newAthletics) => {
        athletics.value = newAthletics;

        const objForDb = JSON.parse(JSON.stringify(athletics.value)).map((_) => ({
          name: _.title,
          desc: _.description,
          date: _.date,
        }));
        await saveDataToDB({
          athletics: objForDb,
        });
      };

      const updateAcademics = async (data) => {
        const { type, value } = data;
        const newObj = { [type]: value };
        await saveDataToDB(newObj);
      };

      return {
        isDataFetched,
        email,
        gradYear,
        name,
        school,
        bio,
        skills,
        skillModel,
        skillModal,
        hobbies,
        modelNum,
        hobbyModel,
        pdfForm,
        gpa,
        asvab,
        act,
        sat,
        store,
        data,
        athletics,
        descriptionEditable,
        clubsAndActivities,
        experience,
        accomplishments,
        setSkillModel,
        downloadPDF,
        toggleEditable,
        setHobbiesAndSkills,
        removeHobbiesAndSkills,
        updateClubsAndActivities,
        updateExperience,
        updateAccomplishments,
        updateAthletics,
        updateAcademics,
      };
    },
  };
</script>

<style scoped>
  .main_div {
    height: calc(100vh - 275px);
  }

  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
    /* Ensures the modal is above other content */
  }

  .modal-content {
    width: 24rem;
    /* Tailwind's w-96 */
    height: 11rem;
    /* Tailwind's h-44 */
  }

  .posts-feeed::-webkit-scrollbar {
    display: none;
  }

  .grid_div {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
    width: 100%;
  }

  .first_div div h1 {
    color: #000;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .personal_info {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 60px;
  }

  .personal_info div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .personal_info div p {
    font-family: Inter;
    font-style: normal;
  }

  .personal_info .title_t {
    color: #a5ac9d;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;

    display: inline-flex;
    font-weight: 500;
    line-height: normal;
  }

  .flex_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .second p {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .academics {
    margin-top: 30px;
  }

  .academics h1 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .exams {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
  }

  .exams_one {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
  }

  .exams_one h1 {
    color: #475f4a;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .exams_one p {
    color: #636060;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 14px;
  }

  .single_btn {
    color: #475f4a;
    font-family: Inter;
    font-size: 15px;
    border-radius: 100px;
    display: inline-flex;
    padding: 5px 20px;
    align-items: flex-start;
    gap: 10px;
    background: #f0f4f0;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media all and (max-width: 1000px) {
    .grid_div {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 40px;
      width: 100%;
    }
  }

  @media all and (max-width: 600px) {
    .grid_div {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 40px;
      width: 100%;
    }
  }

  .inter {
    font-family: Inter;
  }
</style>
