<template>
  <div class="wrapper">
    <div class="h-full max-h-full p-6 border border-gray-300 rounded-xl overflow-y-scroll">
      <ul>
        <li class="label" v-for="(result, key) in results" :key="key">
          <div>
            Question: {{ result.question }} <br>
            <div v-for="(answer, idx) in result.answers" :key="idx">
              Answer: {{ answer }} <br>
            </div>
          </div>
          <br>
          <div/>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import onetData from '../../assets/js/onet_job_zones/job_zones.json';
import Searchbar from './Searchbar.vue';
import {getTypeFormResults} from '../../TypeForm.js';
import { onMounted, ref } from "vue";
const formId = ref('nCOP3yFd')// College Interest
const results = ref({})


onMounted(async () => {
  const typeformMap = await getTypeFormResults(formId.value)
  if (typeformMap) {
    results.value = typeformMap;
  }
});


</script>
<style scoped>
  .wrapper {
    border-radius: 12px;
    @apply w-full h-full flex flex-col;
  }

  .wrapper::-webkit-scrollbar,
  .grid-item::-webkit-scrollbar {
    display: none;
  }
</style>
