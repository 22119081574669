<template>

  <div class="space-y-4">
    <div class="rounded-[50px] p-8 bg-white shadow-3xl space-y-4 mb-6 mt-5">
        <h3 class="text-xl font-medium text-black">In-State Estimate Cost</h3>
        <div class="py-2 inline-block w-full">
          <table class="w-full">
            <thead>
            <tr>
              <th
                  v-for="(col, index) in colNames"
                  :key="index"
                  class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[20px] pb-4"
              >
                {{ col }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td
                  v-for="(row, index) in rowsData"
                  :key="index"
                  class="text-[var(--style)] font-medium text-[30px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5"
              >
                {{ row }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    <div class="rounded-[50px] p-8 bg-white shadow-3xl space-y-4 mb-6 mt-5">
      <h3 class="text-xl font-medium text-black">Out-of-State Estimate Costs</h3>
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 inline-block w-full sm:px-6 lg:px-8">
          <table class="w-full">
            <thead>
            <tr>
              <th
                  v-for="(col, index) in colNames"
                  :key="index"
                  class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[20px] pb-4"
              >
                {{ col }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td
                  v-for="(row, index) in rowsData"
                  :key="index"
                  class="text-[var(--style)] font-medium text-[30px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5"
              >
                {{ row }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default{
    data() {
      return {
        colNames: ["Tuition and Fees", "Room and Board", "Total Costs"],
        rowsData: ["$9,238", "$14,204", "$24,441"],
      };
    },
    props:[
        "collegeData"
    ],
}
</script>
<style scoped>
</style>