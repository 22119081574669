export const StudentLogin_gr23431Data = {
    src: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/search-2.svg",
};

export const StudentLogin_gr23432Data = {
    src: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/search-2-1.svg",
};

export const StudentLogin_gr91Data = {
    rectangle10: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64baf9daa52b84c14c1b2d3b/img/rectangle-10@2x.png",
};

export const StudentLogin_gr92Data = {
    rectangle10: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64baf9daa52b84c14c1b2d3b/img/rectangle-10-1@2x.png",
};

export const StudentLogin_gr23491Data = {
    src: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64bf07089c71654b57c5b973/img/user-alt-1.svg",
};

export const StudentLogin_fr2347Data = {
    technicalEducation: "Technical Education",
    loremIpsumDolorSi: "Guiding Students to Their Next Opportunity,",
    x75Match: "50% Match",
    heart1: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64bf07089c71654b57c5b973/img/like-icon.svg",
    StudentLoginPage_gr2351Props: StudentLogin_gr23491Data,
};

export const StudentLogin_gr23492Data = {
    src: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64bf07089c71654b57c5b973/img/user-alt-1-1.svg",
};

export const StudentLogin_fr23472Data = {
    aboutMe: "About You",
    address: "5 min",
    loremIpsumDolorSi: "Some Basic Information To Get You Started,",
    percent: "35%",
    StudentLoginPage_gr2349Props: StudentLogin_gr23492Data,
};

export const loginBoxData = {
    emailAddress: "Email Address",
    inputType1: "email",
    inputPlaceholder1: "Email@example.com",
    passwords: "Passwords",
    inputType2: "password",
    inputPlaceholder2: "Password",
    //forgotPassword: "Forgot Password",
    login: "Login",
};

export const studentLoginPageData = {
    image1: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/image-1@2x.png",
    title: "ScholarPath",
    careerProfile: "Career Profile",
    conventional: "Conventional",
    enterprising: "Enterprising",
    realistic: "Realistic",
    investigative: "Investigative",
    number1: "20",
    number2: "40",
    number3: "60",
    number4: "80",
    number5: "100",
    artistic: "Artistic",
    social: "Social",
    suggestedCareers: "Suggested Careers",
    technicalEducation: "Technical Education",
    x60000Annually1: "$60,000 annually",
    adultBasicSecondaryEd: "Adult Basic Secondary Ed...",
    x60000Annually2: "$60,000 annually",
    opportunities: "Opportunities",
    scholarpath: "ScholarPath",
    stPetersMissouri: "St. Peters Missouri",
    maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72f85f13258bbacb4cb92/img/mask-group-9@2x.png",
    welcomeBack: "Welcome back!",
    or: "or",
    signInWithGoogle: "Sign in with Google",
    image3: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/image-3@2x.png",
    signInWithNcedcloud: "Sign in with NCEdCloud",
    image4: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64baf9daa52b84c14c1b2d3b/img/image-4@2x.png",
    StudentLoginPage_gr23431Props: StudentLogin_gr23431Data,
    StudentLoginPage_gr23432Props: StudentLogin_gr23432Data,
    StudentLoginPage_gr91Props: StudentLogin_gr91Data,
    StudentLoginPage_gr92Props: StudentLogin_gr92Data,
    StudentLoginPage_fr2347Props: StudentLogin_fr2347Data,
    StudentLoginPage_fr23472Props: StudentLogin_fr23472Data,
    loginBoxProps: loginBoxData,
};

