<template>
  <div class="top-educator-navigation-bar">
    <div class="top-nav-container">
      <div class="left-nav-flex-row">
        <img class="sp-logo" :src="spLogo" alt="SP Logo" />
        <div class="scholar-path valign-text-middle">{{ scholarpath }}</div>
      </div>
      <div class="top-navigation-bar">
        <router-link
          to="/educatorHomeFeed"
          :class="[
            currentLink === '/educatorHomeFeed' ? 'parent_a' : 'parent_b',
          ]"
        >
          <svg
            @click="goToSurvey('/educatorHomeFeed')"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 23 20"
            fill="none"
            :class="[
              currentLink === '/educatorHomeFeed' ? 'active' : 'inactive',
              'home-lg-alt-1 main_link',
            ]"
          >
            <g clip-path="url(#clip0_724_1652)">
              <path
                d="M11.5004 4.49258L2.77437 12.0203C2.70969 12.0773 2.62703 12.1039 2.55595 12.1512V19.3754C2.55595 19.5411 2.62326 19.7001 2.74308 19.8173C2.86289 19.9345 3.0254 20.0004 3.19484 20.0004H8.30595C8.4754 20.0004 8.6379 19.9345 8.75772 19.8173C8.87753 19.7001 8.94484 19.5411 8.94484 19.3754V14.3754C8.94484 14.2096 9.01215 14.0507 9.13197 13.9334C9.25178 13.8162 9.41429 13.7504 9.58373 13.7504H13.4171C13.5865 13.7504 13.749 13.8162 13.8688 13.9334C13.9886 14.0507 14.056 14.2096 14.056 14.3754V19.3754C14.056 19.5411 14.1233 19.7001 14.2431 19.8173C14.3629 19.9345 14.5254 20.0004 14.6948 20.0004H19.806C19.9754 20.0004 20.1379 19.9345 20.2577 19.8173C20.3775 19.7001 20.4448 19.5411 20.4448 19.3754V12.1527C20.377 12.1074 20.2963 12.0816 20.2348 12.0277L11.5004 4.49258ZM22.7884 9.23007L12.5685 0.401951C12.276 0.143241 11.8957 0 11.5014 0C11.1071 0 10.7268 0.143241 10.4343 0.401951L0.212029 9.23007C0.149586 9.28493 0.0988021 9.35128 0.0625783 9.42534C0.0263544 9.49939 0.00540059 9.57971 0.000913761 9.66169C-0.00357307 9.74367 0.00849503 9.82571 0.0364286 9.90313C0.0643623 9.98055 0.107614 10.0518 0.163713 10.1129L1.01863 11.0434C1.0747 11.1044 1.14252 11.1541 1.21823 11.1896C1.29393 11.225 1.37603 11.2455 1.45983 11.2499C1.54364 11.2543 1.6275 11.2425 1.70664 11.2151C1.78578 11.1878 1.85864 11.1455 1.92106 11.0906L11.0779 3.18906C11.1947 3.08833 11.3449 3.03274 11.5006 3.03274C11.6562 3.03274 11.8065 3.08833 11.9233 3.18906L21.0801 11.0902C21.1426 11.1451 21.2154 11.1874 21.2946 11.2147C21.3737 11.2421 21.4576 11.2539 21.5414 11.2495C21.6252 11.2451 21.7073 11.2246 21.783 11.1892C21.8587 11.1537 21.9265 11.1041 21.9826 11.043L22.8371 10.1125C22.9502 9.98915 23.0086 9.82689 22.9995 9.66142C22.9904 9.49594 22.9144 9.34079 22.7884 9.23007Z"
                fill="currentcolor"
              />
            </g>
            <defs>
              <clipPath id="clip0_724_1652">
                <rect width="23" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </router-link>
        <router-link
          to="/clusterPathway"
          :class="[currentLink === '/clusterPathway' ? 'parent_a' : 'parent_b']"
        >
          <svg
            @click="goToSurvey('/clusterPathway')"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="20"
            viewBox="0 0 15 20"
            fill="none"
            :class="[
              currentLink === '/clusterPathway' ? 'active' : 'inactive',
              'home-lg-alt-1 main_link',
            ]"
          >
            <g clip-path="url(#clip0_724_1654)">
              <path
                d="M13.125 2.5H10C10 1.12109 8.87891 0 7.5 0C6.12109 0 5 1.12109 5 2.5H1.875C0.839844 2.5 0 3.33984 0 4.375V18.125C0 19.1602 0.839844 20 1.875 20H13.125C14.1602 20 15 19.1602 15 18.125V4.375C15 3.33984 14.1602 2.5 13.125 2.5ZM7.5 1.5625C8.01953 1.5625 8.4375 1.98047 8.4375 2.5C8.4375 3.01953 8.01953 3.4375 7.5 3.4375C6.98047 3.4375 6.5625 3.01953 6.5625 2.5C6.5625 1.98047 6.98047 1.5625 7.5 1.5625ZM2.5625 8.17969L3.05859 7.6875C3.14062 7.60547 3.27344 7.60547 3.35547 7.6875L4.16016 8.5L6.01953 6.65625C6.10156 6.57422 6.23438 6.57422 6.31641 6.65625L6.80859 7.15234C6.89062 7.23438 6.89062 7.36719 6.80859 7.44922L4.30078 9.93359C4.21875 10.0156 4.08594 10.0156 4.00391 9.93359L2.5625 8.47656C2.48047 8.39453 2.48047 8.26172 2.5625 8.17969ZM3.75 15.3125C3.23047 15.3125 2.8125 14.8945 2.8125 14.375C2.8125 13.8555 3.23047 13.4375 3.75 13.4375C4.26953 13.4375 4.6875 13.8555 4.6875 14.375C4.6875 14.8945 4.26953 15.3125 3.75 15.3125ZM12.5 14.6875C12.5 14.8594 12.3594 15 12.1875 15H6.5625C6.39062 15 6.25 14.8594 6.25 14.6875V14.0625C6.25 13.8906 6.39062 13.75 6.5625 13.75H12.1875C12.3594 13.75 12.5 13.8906 12.5 14.0625V14.6875ZM12.5 9.6875C12.5 9.85938 12.332 10 12.125 10H6.01953L7.28125 8.75H12.125C12.332 8.75 12.5 8.89062 12.5 9.0625V9.6875Z"
                fill="currentcolor"
              />
            </g>
            <defs>
              <clipPath id="clip0_724_1654">
                <rect width="15" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </router-link>
      </div>
      <div class="top-nav-container">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_670_3551)">
            <path
              d="M17.3744 11.3711L19.0391 12.332C19.2292 12.4417 19.3174 12.6687 19.2523 12.8783C18.8199 14.2706 18.0816 15.5284 17.116 16.5731C17.0437 16.6513 16.947 16.7026 16.8417 16.7186C16.7365 16.7346 16.6289 16.7143 16.5366 16.6611L14.8732 15.7004C14.1733 16.3 13.3687 16.7653 12.4998 17.0726V18.9938C12.4998 19.1004 12.4635 19.2037 12.397 19.2869C12.3304 19.3701 12.2375 19.4282 12.1335 19.4515C10.768 19.7582 9.30151 19.774 7.86714 19.4518C7.65268 19.4037 7.49983 19.2137 7.49983 18.9939V17.0726C6.63094 16.7653 5.8263 16.3001 5.12643 15.7004L3.463 16.6611C3.37076 16.7143 3.26316 16.7346 3.15788 16.7186C3.0526 16.7026 2.95588 16.6513 2.88362 16.5731C1.91807 15.5284 1.17975 14.2706 0.747332 12.8783C0.682215 12.6687 0.770457 12.4418 0.960574 12.332L2.6253 11.3711C2.45809 10.4647 2.45809 9.53537 2.6253 8.62897L0.960613 7.66807C0.770496 7.55834 0.682254 7.33143 0.747371 7.12178C1.17979 5.72952 1.91807 4.4717 2.88366 3.42702C2.95592 3.34879 3.05264 3.29747 3.15792 3.28147C3.2632 3.26547 3.3708 3.28574 3.46304 3.33897L5.12647 4.29971C5.82635 3.70004 6.63099 3.23481 7.49987 2.92745V1.00624C7.49987 0.899683 7.53614 0.796303 7.60271 0.713109C7.66929 0.629914 7.7622 0.571861 7.86616 0.548501C9.23163 0.24186 10.6982 0.226001 12.1325 0.548227C12.347 0.596391 12.4998 0.786352 12.4998 1.00616V2.92741C13.3687 3.23476 14.1734 3.69999 14.8732 4.29967L16.5367 3.33893C16.6289 3.2857 16.7365 3.26543 16.8418 3.28143C16.9471 3.29743 17.0438 3.34875 17.116 3.42698C18.0816 4.47166 18.8199 5.72948 19.2523 7.12174C19.3175 7.33135 19.2292 7.55827 19.0391 7.66803L17.3744 8.62893C17.5416 9.53535 17.5416 10.4647 17.3744 11.3711ZM13.1248 10C13.1248 8.2769 11.723 6.87502 9.99983 6.87502C8.27671 6.87502 6.87483 8.2769 6.87483 10C6.87483 11.7231 8.27671 13.125 9.99983 13.125C11.723 13.125 13.1248 11.7231 13.1248 10Z"
              fill="#475F4A"
            />
          </g>
          <defs>
            <clipPath id="clip0_670_3551">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div class="top-navigation-bar-settings">BB</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      currentLink: null,
    };
  },
  created() {
    this.currentLink = this.$router.currentRoute.path;
  },
  mounted() {
    this.currentLink = this.$router.currentRoute.path;
  },
  created() {
    this.currentLink = this.$router.currentRoute.path;
  },
  methods: {
    goToSurvey(link) {
      this.$router.push(link);
      this.currentLink = this.$router.currentRoute.path;
    },
  },
  name: "LeftNavigationBar",
  props: ["userObject", "spLogo", "scholarpath"],
};
</script>

<style scoped>
.top-educator-navigation-bar {
  border-radius: 30px;
  margin-top: 30px;
  padding: 30px 16px;
}

.top-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-navigation-bar {
  align-items: center;
  background-color: var(--white);
  border-radius: 100px;
  box-shadow: 0px 0px 12px #0000000d;
  display: flex;
  height: 56px;
  margin-left: 21px;
  min-width: 100px;
  padding: 5px 20px;
  justify-content: space-between;
}

.top-navigation-bar-settings {
  align-items: center;
  background-color: var(--white);
  border-radius: 50%;
  box-shadow: 0px 0px 12px #0000000d;
  display: flex;
  height: 46px;
  margin-left: 21px;
  min-width: 46px;
  padding: 5px 10px;
  justify-content: space-between;
  border: 1px solid #ccc;
  font-size: 20px;
  font-weight: 600;
  font-family: inter;
}

.place {
  color: var(--style);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 500;
  height: 12px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  min-width: 29px;
  white-space: nowrap;
}

.parent_a {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #259b3521;
  display: flex;
  align-items: center;

  justify-content: center;
}

.parent_b {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;

  justify-content: center;
}

.active {
  color: #259b35 !important;
}

.main_link {
  height: 20px;
  width: 23px;
  cursor: pointer;
  color: #a5ac9d;
}

.top-filter-container {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 60px;
  margin-left: 43px;
}

.sp-logo {
  height: 42px;
  object-fit: cover;
  width: 43px;
}

.scholar-path {
  color: var(--style);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 500;
  height: 18px;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2px;
  min-width: 88px;
  white-space: nowrap;
}
</style>
