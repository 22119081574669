export const portfolioDataGrades = [
  {
    term: "S1",
    year: "10",
    grade: "A-",
    title: "EN LANG ARTS II",
    course: "H0020",
    credit: "0.500",
  },
  {
    term: "S2",
    year: "10",
    grade: "A-",
    title: "EN LANG ARTS II",
    course: "H0020",
    credit: "0.500",
  },
  {
    term: "S1",
    year: "10",
    grade: "A",
    title: "STUDENT MEDIA",
    course: "H0075",
    credit: "0.500",
  },
  {
    term: "S2",
    year: "10",
    grade: "A",
    title: "STUDENT MEDIA",
    course: "H0075",
    credit: "0.500",
  },
  {
    term: "S1",
    year: "10",
    grade: "A",
    title: "SPANISH II",
    course: "H0150",
    credit: "0.500",
  },
  {
    term: "S2",
    year: "10",
    grade: "A-",
    title: "SPANISH II",
    course: "H0150",
    credit: "0.500",
  },
];

export const defaultDataResume = {
  skills: ["JavaScript", "Advanced React"],
  hobbies: ["Photography", "Cycling"],
  studentBio:
    " I’m committed to making a positive impact through sustainable practices and community outreach. With a keen interest in research and innovation, I aim to contribute to groundbreaking solutions for global challenges",
  email: "example@example.com",
  schools: "City School",
  gradYear: "2023",
  firstName: "John",
  lastName: "Doe",
  asvab: "5",
  gpa: "0.0",
  act: "3",
  sat: "2",
  clubsAndActivities: [
    { name: "Debate Club", desc: "Participated in weekly debates", date: "2023-09-01" },
    { name: "Science Club", desc: "Worked on science projects", date: "2023-09-15" },
  ],
  experience: [
    { name: "Intern at TechCorp", desc: "Worked on web development projects", date: "2023-06-01" },
    { name: "Volunteer at Local Library", desc: "Assisted with organizing events", date: "2023-07-01" },
  ],
  accomplishments: [
    { name: "Math Olympiad Winner", desc: "Won first place in regional competition", date: "2023-05-01" },
    { name: "Science Fair Champion", desc: "Received top prize for science project", date: "2023-04-01" },
  ],
  athletics: [
    { name: "Varsity Soccer", desc: "Played as a forward", date: "2023-08-01" },
    { name: "Track & Field", desc: "Competed in 400m dash", date: "2023-07-15" },
  ],
  showSAT: false,
  showACT: false,
  showGPA: false,
  showASVAB: false,
  showEmail: false,
  showPhone: false,
};
