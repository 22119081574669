<template>
  <MainLayout>
    <div
      class="w-full h-full border-gray-400 relative bg-white rounded-3xl px-10 flex flex-col"
    >
      <div class="w-full sticky top-0 left-0">
        <div class="px-5 w-full bg-white pt-5">
          <router-link :to="getPath()">
            <button
              class="w-32 h-8 px-5 bg-gray-100 text-black text-sm rounded-md inline-block"
            >
              Back
            </button>
          </router-link>
        </div>
        <div class="p-5 min-h-max">
          <div
            class="flex items-center justify-between gap-5 border-b border-gray-200"
          >
            <div class="w-full flex items-center gap-5 mb-5">
              <div>
                <h2 class="w-full max-w-xl text-2xl font-bold uppercase">
                  {{ job?job["Title"]:"Occupation Title"}}
                </h2>
                <p class="text-gray-800 text-lg">
                  <!-- {{ collegeData.CMPS_METRO_T }} -->
                </p>
                <!-- <p class="text-gray-600 text-sm">Last Date updated 12/12/2002</p> -->
              </div>
            </div>
          </div>
          <div class="w-full flex items-center gap-5 mt-5 overflow-x-scroll noscrollbar">
            <button
              class="tab-btn"
              :class="selectedTab === i ? 'selected-tab' : ''"
              v-for="(tab, i) in tabs"
              :key="i"
              :id="'tab_'+i"
              @click="updateTab(i)"
            >
              {{ tab }}
            </button>
            
          </div>
        </div>
      </div>
      <div class="p-5 pt-0 h-full overflow-y-scroll flex flex-col items-center">
        <Overview :OnetCode="$route.params.id" v-if="selectedTab === 0"/>
        <Salary :OnetCode="$route.params.id" v-else-if="selectedTab === 1"/>
        <Experience :OnetCode="$route.params.id" v-else-if="selectedTab === 2"/>
        <Activities :OnetCode="$route.params.id" v-else-if="selectedTab === 3"/>
        <Knowledge :OnetCode="$route.params.id" v-else-if="selectedTab === 4"/>
        <WorkValue :OnetCode="$route.params.id" v-else-if="selectedTab === 5"/>
        <WorkStyle :OnetCode="$route.params.id" v-else-if="selectedTab === 6"/>
        <Interests :OnetCode="$route.params.id" v-else-if="selectedTab === 7"/>
        <RelatedOccupation :OnetCode="$route.params.id" v-else-if="selectedTab === 8"/>
      </div>
    </div>
  </MainLayout>
  </template>
  
  <script>
  import jobsData from '../../assets/js/onet_job_zones/job_zones.json';
  import MainLayout from '../../layouts/MainLayout.vue';
  import Overview from "./Overview.vue";
  import Salary from "./Salary.vue";
  import Experience from "./Experience.vue";
  import Activities from "./Activities.vue";
  import Knowledge from './Knowledge.vue';
  import WorkValue from './WorkValue.vue';
  import WorkStyle from './WorkStyle.vue';
  import Interests from './Interests.vue';
  import RelatedOccupation from './RelatedOccupation.vue';

  import { useStudentDemo } from '../../store/StudentDemoStates';

  export default {
    name: "CollegeProfile",
    components: {
      MainLayout,
      Overview,
      Salary,
      Experience,
      Activities,
      Knowledge,
      WorkValue,
      WorkStyle,
      Interests,
      RelatedOccupation
    },
    props: ["leftNavigationBarProps"],
    data() {
      return {
        selectedTab: 0,
        tabs: [
            "Overview",
            "Salary and Opportunity",
            "Experience and requirements",
            "Work Activities",
            "Knowledge Skills Abilities",
            "Work values",
            "Work styles",
            "Interests",
            "Related Occupations",
            "Occupation video"
        ],
        store:null
      };
    },
    computed:{
      job(){
        return jobsData.find(el=> el["O*NET-SOC Code"] === this.$route.params.id)
      }
    },
    methods:{
      updateTab(tab){
        const id = this.$route.params.id;
        this.selectedTab = tab;
        this.$router.push('/occupation/'+id+'?tab='+tab)
      },
      getPath() {
        return this.store?.isStudentDemo ? '/educator/studentDemo/researchDefault?tab=1' : '/researchDefault?tab=1';
      },
    },
    mounted(){
      this.store = useStudentDemo()
      const tab = this.$route.query.tab;
      this.selectedTab = tab? parseInt(tab) : 0;
      document.getElementById('tab_'+this.selectedTab).scrollIntoView()
    }
  };
  </script>
  
  <style scoped>
  .tab-btn {
    @apply px-5 py-1.5 border border-gray-300 rounded-full w-max min-w-fit text-sm hover:bg-gray-200 ;
  }
  .selected-tab {
    @apply bg-primary text-white;
  }
  .noscrollbar::-webkit-scrollbar{
    @apply hidden
  }
  .heading1 {
    @apply font-bold text-xl capitalize mb-2.5;
  }
  .paragraph {
    @apply text-base text-gray-700 mb-5;
  }
  </style>
  