<template>
  <div class="main">
    <div class="main_child">
      <div class="upperside">
        <div class="mainUserInfo"></div>

        <div class="mainCentralLink">
          <div class="centralLinks">
            <div class="top-navigation-bar">
              <router-link
                to="/homeFeed"
                :class="[currentLink === '/homeFeed' ? 'parent_a' : '']"
              >
                <svg
                  @click="goToSurvey('/homeFeed')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 23 20"
                  fill="none"
                  :class="[
                    currentLink === '/homeFeed' ? 'active' : 'inactive',
                    'home-lg-alt-1 main_link',
                  ]"
                >
                  <g clip-path="url(#clip0_724_1652)">
                    <path
                      d="M11.5004 4.49258L2.77437 12.0203C2.70969 12.0773 2.62703 12.1039 2.55595 12.1512V19.3754C2.55595 19.5411 2.62326 19.7001 2.74308 19.8173C2.86289 19.9345 3.0254 20.0004 3.19484 20.0004H8.30595C8.4754 20.0004 8.6379 19.9345 8.75772 19.8173C8.87753 19.7001 8.94484 19.5411 8.94484 19.3754V14.3754C8.94484 14.2096 9.01215 14.0507 9.13197 13.9334C9.25178 13.8162 9.41429 13.7504 9.58373 13.7504H13.4171C13.5865 13.7504 13.749 13.8162 13.8688 13.9334C13.9886 14.0507 14.056 14.2096 14.056 14.3754V19.3754C14.056 19.5411 14.1233 19.7001 14.2431 19.8173C14.3629 19.9345 14.5254 20.0004 14.6948 20.0004H19.806C19.9754 20.0004 20.1379 19.9345 20.2577 19.8173C20.3775 19.7001 20.4448 19.5411 20.4448 19.3754V12.1527C20.377 12.1074 20.2963 12.0816 20.2348 12.0277L11.5004 4.49258ZM22.7884 9.23007L12.5685 0.401951C12.276 0.143241 11.8957 0 11.5014 0C11.1071 0 10.7268 0.143241 10.4343 0.401951L0.212029 9.23007C0.149586 9.28493 0.0988021 9.35128 0.0625783 9.42534C0.0263544 9.49939 0.00540059 9.57971 0.000913761 9.66169C-0.00357307 9.74367 0.00849503 9.82571 0.0364286 9.90313C0.0643623 9.98055 0.107614 10.0518 0.163713 10.1129L1.01863 11.0434C1.0747 11.1044 1.14252 11.1541 1.21823 11.1896C1.29393 11.225 1.37603 11.2455 1.45983 11.2499C1.54364 11.2543 1.6275 11.2425 1.70664 11.2151C1.78578 11.1878 1.85864 11.1455 1.92106 11.0906L11.0779 3.18906C11.1947 3.08833 11.3449 3.03274 11.5006 3.03274C11.6562 3.03274 11.8065 3.08833 11.9233 3.18906L21.0801 11.0902C21.1426 11.1451 21.2154 11.1874 21.2946 11.2147C21.3737 11.2421 21.4576 11.2539 21.5414 11.2495C21.6252 11.2451 21.7073 11.2246 21.783 11.1892C21.8587 11.1537 21.9265 11.1041 21.9826 11.043L22.8371 10.1125C22.9502 9.98915 23.0086 9.82689 22.9995 9.66142C22.9904 9.49594 22.9144 9.34079 22.7884 9.23007Z"
                      fill="currentcolor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_724_1652">
                      <rect width="23" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </router-link>
              <router-link
                to="/surveyDefault"
                :class="[currentLink === '/surveyDefault' ? 'parent_a' : '']"
              >
                <svg
                  width="23"
                  height="20"
                  viewBox="0 0 23 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="23" height="20" fill="#1E1E1E" />
                  <g id="Message Page">
                    <rect
                      width="1512"
                      height="982"
                      transform="translate(-747 -51)"
                      fill="#F3F7F8"
                    />
                    <g id="Provider Top Navigation">
                      <g id="Rectangle 3" filter="url(#filter0_d_0_1)">
                        <rect
                          x="-112"
                          y="-21"
                          width="243"
                          height="70"
                          rx="35"
                          fill="none"
                          @click="goToSurvey('/surveyDefault')"
                          :class="[
                            currentLink === '/surveyDefault'
                              ? 'active'
                              : 'inactive',
                            'home-lg-alt-1 main_link',
                          ]"
                        />
                      </g>
                      <g id="bullhorn 2" clip-path="url(#clip0_0_1)">
                        <path
                          id="Vector"
                          d="M23 9.375C23 8.45195 22.4829 7.65469 21.7222 7.22188V1.25039C21.7222 0.908594 21.4435 0 20.4444 0C20.1601 0 19.8778 0.0929688 19.6466 0.274219L16.2513 2.93164C14.5459 4.26523 12.4048 5 10.2222 5H2.55556C1.14401 5 0 6.11914 0 7.5V11.25C0 12.6309 1.14401 13.75 2.55556 13.75H3.90122C3.84571 14.1594 3.81417 14.5758 3.81417 15C3.81417 16.5535 4.18392 18.0215 4.83479 19.3336C5.04203 19.7512 5.49444 20 5.96882 20H8.93486C9.97505 20 10.5996 18.8344 9.96906 18.025C9.3142 17.1844 8.92488 16.1359 8.92488 15C8.92488 14.566 8.98957 14.1488 9.10097 13.75H10.2222C12.4048 13.75 14.5459 14.4848 16.2509 15.8184L19.6462 18.4758C19.8727 18.6531 20.154 18.7498 20.444 18.75C21.4391 18.75 21.7218 17.8602 21.7218 17.5V11.5285C22.4829 11.0953 23 10.298 23 9.375ZM19.1667 14.8992L17.847 13.8664C15.6907 12.1789 12.9822 11.25 10.2222 11.25V7.5C12.9822 7.5 15.6907 6.57109 17.847 4.88359L19.1667 3.85078V14.8992Z"
                          fill="#A5AC9D"
                        />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_0_1"
                      x="-124"
                      y="-33"
                      width="267"
                      height="94"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="6" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_0_1"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_0_1"
                        result="shape"
                      />
                    </filter>
                    <clipPath id="clip0_0_1">
                      <rect width="23" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </router-link>

              <router-link
                to="/messages"
                :class="[currentLink === '/messages' ? 'parent_a' : '']"
              >
                <svg
                  @click="goToSurvey('/messages')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  :class="[
                    currentLink === '/messages' ? 'active' : 'inactive',
                    'home-lg-alt-1 main_link',
                  ]"
                >
                  <path
                    d="M19.6211 7.45312C19.7734 7.33203 20 7.44531 20 7.63672V15.625C20 16.6602 19.1602 17.5 18.125 17.5H1.875C0.839844 17.5 0 16.6602 0 15.625V7.64062C0 7.44531 0.222656 7.33594 0.378906 7.45703C1.25391 8.13672 2.41406 9 6.39844 11.8945C7.22266 12.4961 8.61328 13.7617 10 13.7539C11.3945 13.7656 12.8125 12.4727 13.6055 11.8945C17.5898 9 18.7461 8.13281 19.6211 7.45312ZM10 12.5C10.9062 12.5156 12.2109 11.3594 12.8672 10.8828C18.0508 7.12109 18.4453 6.79297 19.6406 5.85547C19.8672 5.67969 20 5.40625 20 5.11719V4.375C20 3.33984 19.1602 2.5 18.125 2.5H1.875C0.839844 2.5 0 3.33984 0 4.375V5.11719C0 5.40625 0.132812 5.67578 0.359375 5.85547C1.55469 6.78906 1.94922 7.12109 7.13281 10.8828C7.78906 11.3594 9.09375 12.5156 10 12.5Z"
                    fill="currentcolor"
                  />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
        <div class="leftLinks">
          <div class="frame-2354">
            <img
              class="bell-1"
              src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/bell-1-3.svg"
              alt="bell 1"
            />
            <img
              class="cog-1"
              src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/cog-1-3.svg"
              alt="cog 1"
            />
            <div class="overlap-group">
              <div class="jj">Kashif</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-16">
        <div>
          <img
            class="h-32 w-full object-cover lg:h-48"
            src="https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
            alt=""
          />
        </div>
        <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div class="flex">
              <img
                class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                alt=""
              />
            </div>
            <div
              class="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1"
            >
              <div class="mt-6 min-w-0 flex-1 sm:hidden md:block">
                <h1 class="truncate text-2xl font-bold text-gray-900">
                  Ricardo Cooper
                </h1>
                <p>Kashif</p>
              </div>
              <div
                class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0"
              >
                <button
                  type="button"
                  class="inline-flex justify-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <span>Edit</span>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
            <h1 class="truncate text-2xl font-bold text-gray-900">
              Ricardo Cooper
            </h1>
          </div>
        </div>
      </div>

      <div class="downside">
        <div class="leftbar">
          <ul
            role="list"
            class="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1"
          >
            <li
              class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
            >
              <div class="flex items-start flex-col p-8">
                <h3 class="mt-6 title text-sm font-medium text-gray-900">
                  Address
                </h3>
                <dl class="mt-1 flex items-start flex-col justify-between">
                  <dt class="sr-only">Title</dt>
                  <dd class="text-sm text-gray-500 green_heading">
                    3355 University Drive
                  </dd>
                  <dd class="text-sm text-gray-500 green_heading">
                    Tuscan, AZ
                  </dd>
                  <dd class="text-sm text-gray-500 green_heading">85721</dd>
                </dl>
              </div>
              <div>
                <div class="-mt-px flex divide-x divide-gray-200">
                  <div class="flex w-0 flex-1">
                    <a
                      href="mailto:janecooper@example.com"
                      class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="34"
                        viewBox="0 0 30 34"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_49_3842)">
                          <path
                            d="M15.0068 9.36318C10.7479 9.36318 7.31264 12.7698 7.31264 16.9933C7.31264 21.2167 10.7479 24.6233 15.0068 24.6233C19.2658 24.6233 22.701 21.2167 22.701 16.9933C22.701 12.7698 19.2658 9.36318 15.0068 9.36318ZM15.0068 21.9538C12.2546 21.9538 10.0046 19.7292 10.0046 16.9933C10.0046 14.2573 12.2479 12.0327 15.0068 12.0327C17.7658 12.0327 20.0091 14.2573 20.0091 16.9933C20.0091 19.7292 17.7591 21.9538 15.0068 21.9538ZM24.8104 9.05107C24.8104 10.0405 24.0068 10.8308 23.0158 10.8308C22.018 10.8308 21.2211 10.0339 21.2211 9.05107C21.2211 8.06826 22.0247 7.27139 23.0158 7.27139C24.0068 7.27139 24.8104 8.06826 24.8104 9.05107ZM29.9064 10.8573C29.7926 8.47334 29.2434 6.36162 27.4823 4.62178C25.7278 2.88193 23.5984 2.3374 21.1943 2.21787C18.7167 2.07842 11.2903 2.07842 8.81264 2.21787C6.41532 2.33076 4.28585 2.87529 2.52469 4.61514C0.763533 6.35498 0.221122 8.4667 0.100586 10.8507C-0.0400391 13.3077 -0.0400391 20.6722 0.100586 23.1292C0.214425 25.5132 0.763533 27.6249 2.52469 29.3647C4.28585 31.1046 6.40862 31.6491 8.81264 31.7687C11.2903 31.9081 18.7167 31.9081 21.1943 31.7687C23.5984 31.6558 25.7278 31.1112 27.4823 29.3647C29.2367 27.6249 29.7859 25.5132 29.9064 23.1292C30.047 20.6722 30.047 13.3144 29.9064 10.8573ZM26.7055 25.7655C26.1832 27.0671 25.172 28.0698 23.8528 28.5944C21.8774 29.3714 17.1899 29.1921 15.0068 29.1921C12.8238 29.1921 8.1296 29.3647 6.16085 28.5944C4.84835 28.0765 3.83719 27.0737 3.30818 25.7655C2.52469 23.8065 2.7055 19.1581 2.7055 16.9933C2.7055 14.8284 2.53139 10.1733 3.30818 8.221C3.8305 6.91943 4.84166 5.9167 6.16085 5.39209C8.1363 4.61514 12.8238 4.79443 15.0068 4.79443C17.1899 4.79443 21.8841 4.62178 23.8528 5.39209C25.1653 5.91006 26.1765 6.91279 26.7055 8.221C27.489 10.18 27.3082 14.8284 27.3082 16.9933C27.3082 19.1581 27.489 23.8132 26.7055 25.7655Z"
                            fill="#259B35"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_3842">
                            <rect width="30" height="34" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                  <div class="-ml-px flex w-0 flex-1">
                    <a
                      href="tel:+1-202-555-0170"
                      class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="30"
                        viewBox="0 0 29 30"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_49_3847)">
                          <path
                            d="M14.5 0.46875C6.48992 0.46875 0 6.97266 0 15C0 23.0273 6.48992 29.5312 14.5 29.5312C22.5101 29.5312 29 23.0273 29 15C29 6.97266 22.5101 0.46875 14.5 0.46875ZM25.796 9.375H20.9724C20.4462 6.77344 19.5633 4.55859 18.4466 2.98242C21.6623 4.04883 24.305 6.375 25.796 9.375ZM19.6452 15C19.6452 16.3418 19.5516 17.5898 19.3938 18.75H9.60625C9.44839 17.5898 9.35484 16.3418 9.35484 15C9.35484 13.6582 9.44839 12.4102 9.60625 11.25H19.3938C19.5516 12.4102 19.6452 13.6582 19.6452 15ZM14.5 2.34375C16.0728 2.34375 18.0899 4.92773 19.0663 9.375H9.93367C10.9101 4.92773 12.9272 2.34375 14.5 2.34375ZM10.5534 2.98242C9.44254 4.55273 8.55383 6.76758 8.02762 9.375H3.20403C4.69496 6.375 7.3377 4.04883 10.5534 2.98242ZM1.87097 15C1.87097 13.6934 2.06976 12.4336 2.4381 11.25H7.72944C7.57742 12.4512 7.48387 13.6992 7.48387 15C7.48387 16.3008 7.57157 17.5488 7.72944 18.75H2.4381C2.06976 17.5664 1.87097 16.3066 1.87097 15ZM3.20403 20.625H8.02762C8.55383 23.2266 9.43669 25.4414 10.5534 27.0176C7.3377 25.9512 4.69496 23.625 3.20403 20.625ZM14.5 27.6562C12.9272 27.6562 10.9101 25.0723 9.93367 20.625H19.0663C18.0899 25.0723 16.0728 27.6562 14.5 27.6562ZM18.4466 27.0176C19.5575 25.4473 20.4462 23.2324 20.9724 20.625H25.796C24.305 23.625 21.6623 25.9512 18.4466 27.0176ZM21.2706 18.75C21.4226 17.5488 21.5161 16.3008 21.5161 15C21.5161 13.6992 21.4284 12.4512 21.2706 11.25H26.5619C26.9302 12.4336 27.129 13.6934 27.129 15C27.129 16.3066 26.9302 17.5664 26.5619 18.75H21.2706Z"
                            fill="#259B35"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_3847">
                            <rect width="29" height="30" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                  <div class="-ml-px flex w-0 flex-1">
                    <a
                      href="tel:+1-202-555-0170"
                      class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="30"
                        viewBox="0 0 29 30"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_49_3847)">
                          <path
                            d="M14.5 0.46875C6.48992 0.46875 0 6.97266 0 15C0 23.0273 6.48992 29.5312 14.5 29.5312C22.5101 29.5312 29 23.0273 29 15C29 6.97266 22.5101 0.46875 14.5 0.46875ZM25.796 9.375H20.9724C20.4462 6.77344 19.5633 4.55859 18.4466 2.98242C21.6623 4.04883 24.305 6.375 25.796 9.375ZM19.6452 15C19.6452 16.3418 19.5516 17.5898 19.3938 18.75H9.60625C9.44839 17.5898 9.35484 16.3418 9.35484 15C9.35484 13.6582 9.44839 12.4102 9.60625 11.25H19.3938C19.5516 12.4102 19.6452 13.6582 19.6452 15ZM14.5 2.34375C16.0728 2.34375 18.0899 4.92773 19.0663 9.375H9.93367C10.9101 4.92773 12.9272 2.34375 14.5 2.34375ZM10.5534 2.98242C9.44254 4.55273 8.55383 6.76758 8.02762 9.375H3.20403C4.69496 6.375 7.3377 4.04883 10.5534 2.98242ZM1.87097 15C1.87097 13.6934 2.06976 12.4336 2.4381 11.25H7.72944C7.57742 12.4512 7.48387 13.6992 7.48387 15C7.48387 16.3008 7.57157 17.5488 7.72944 18.75H2.4381C2.06976 17.5664 1.87097 16.3066 1.87097 15ZM3.20403 20.625H8.02762C8.55383 23.2266 9.43669 25.4414 10.5534 27.0176C7.3377 25.9512 4.69496 23.625 3.20403 20.625ZM14.5 27.6562C12.9272 27.6562 10.9101 25.0723 9.93367 20.625H19.0663C18.0899 25.0723 16.0728 27.6562 14.5 27.6562ZM18.4466 27.0176C19.5575 25.4473 20.4462 23.2324 20.9724 20.625H25.796C24.305 23.625 21.6623 25.9512 18.4466 27.0176ZM21.2706 18.75C21.4226 17.5488 21.5161 16.3008 21.5161 15C21.5161 13.6992 21.4284 12.4512 21.2706 11.25H26.5619C26.9302 12.4336 27.129 13.6934 27.129 15C27.129 16.3066 26.9302 17.5664 26.5619 18.75H21.2706Z"
                            fill="#259B35"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49_3847">
                            <rect width="29" height="30" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </li>

            <!-- More people... -->
          </ul>
        </div>
        <div class="smallRightBar grid grid-cols-1 gap-10">
          <div class="px-4 rounded-2xl sm:px-0 lg:px-0 mixin">
            A Description of my organization. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum dolore eu fugiat nulla pariatur
          </div>
          <div class="px-4 bg-white rounded-2xl sm:px-6 lg:px-8">
            <div class="sm:flex sm:items-center">
              <div class="sm:flex-auto">
                <h1
                  class="text-base title font-semibold leading-6 text-gray-900"
                >
                  Jobs
                </h1>
              </div>
            </div>
            <div class="flow-root">
              <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                >
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Job
                        </th>
                        <th
                          scope="col"
                          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Starting Salary
                        </th>
                        <th
                          scope="col"
                          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Potential Salary
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                      <tr>
                        <td
                          class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                        >
                          Pattern Maker
                        </td>
                        <td
                          class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                        >
                          $30,000
                        </td>
                        <td
                          class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                        >
                          $50,000
                        </td>
                      </tr>

                      <!-- More people... -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="px-4 sm:px-6 rounded-2xl bg-white lg:px-8">
            <div class="sm:flex sm:items-center">
              <div class="sm:flex-auto">
                <h1
                  class="text-base title font-semibold leading-6 text-gray-900"
                >
                  Scholarship Opportunities
                </h1>
              </div>
            </div>
            <div class="flow-root">
              <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                >
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Job
                        </th>
                        <th
                          scope="col"
                          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Starting Salary
                        </th>
                        <th
                          scope="col"
                          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Potential Salary
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                      <tr>
                        <td
                          class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                        >
                          Pattern Maker
                        </td>
                        <td
                          class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                        >
                          $30,000
                        </td>
                        <td
                          class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                        >
                          $50,000
                        </td>
                      </tr>

                      <!-- More people... -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "../../../img/logo.png";

export default {
  name: "ScholarPathProviderBroadcast",
  data() {
    return {
      Logo,
      currentLink: null,
    };
  },

  created() {
    this.currentLink = this.$router.currentRoute.path;
  },
  methods: {
    goToSurvey(link) {
      this.$router.push(link);
      this.currentLink = this.$router.currentRoute.path;
    },
  },
};
</script>

<style scoped>
.mainUserInfo {
  width: 30%;
}
.mainCentralLink {
  width: 40%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.leftLinks {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.main {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  gap: 25px;
  min-height: 80vh;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 0 18px;
  position: relative;
  max-width: 1512px;
  width: 100%;
  padding-top: 40px;
}
.main_child {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.upperside {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100px;
}
.userInfo {
  width: 362px;
  height: 204px;
  flex-shrink: 0;
  border-radius: 40px;
  background: #fff;
  display: flex;
  align-items: flex-start;
  padding: 10px 40px;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
.logo_center {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(37, 155, 53, 0.13);
}

.logo_center img {
  width: auto;
  height: 43px;
}
.schoolpath_logo {
  color: #259b35;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: auto;
}

.profile img {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  object-fit: cover;
  background-position: center;
}
.proName {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.viewProfile {
  color: #000;
  font-family: Inter;
  text-decoration: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.centralLinks {
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);

  height: 70px;
  flex-shrink: 0;

  width: 243px;
  height: 70px;
  flex-shrink: 0;
}

.top-navigation-bar {
  align-items: center;
  background-color: var(--white);
  border-radius: 100px;
  display: flex;
  height: 100%;
  min-width: 100%;
  padding: 5px 20px;
  justify-content: space-between;
}

.flex-col-4 {
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-height: 58px;
  width: 44px;
}

.overlap-group-4 {
  align-items: center;
  background-color: var(--x259b35);
  border-radius: 100px;
  display: flex;
  height: 44px;
  justify-content: flex-end;
  min-width: 44px;
  padding: 0 10px;
}

.main_link {
  height: 20px;
  width: 23px;
  cursor: pointer;
  color: #a5ac9d;
}

.place {
  color: var(--style);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 500;
  height: 12px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  min-width: 29px;
  white-space: nowrap;
}

.parent_a {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #259b3521;
  display: flex;
  align-items: center;

  justify-content: center;
}

.active {
  color: #259b35 !important;
}

.frame-2354 {
  align-items: center;
  display: flex;
  margin-right: 12px;
  min-width: 109px;
}

.bell-1 {
  height: 20px;
  width: 20px;
}
.cog-1 {
  height: 20px;
  margin-left: 10px;
  width: 20px;
}
.overlap-group {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--celeste);
  border-radius: 20px;
  display: flex;
  height: 40px;
  margin-left: 13px;
  min-width: 46px;
  padding: 10px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downside {
  width: 100%;
  min-height: 1000px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 80px;
}

.downside .leftbar {
  width: 30%;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  border-radius: 40px;
}
.firstStats {
  width: 100%;
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.stat_0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.figure {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.total {
  color: #475f4a;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.last_seen {
  color: var(--style, #a5ac9d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.secondStats {
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 232px;
  padding: 10px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.published {
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
table {
  width: 100%;
  margin-top: 10px;
}
table thead tr th {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
table tbody tr td {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

table tbody tr {
  color: #000;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 1em;
  line-height: normal;
  text-align: left;
}
tr.spaceUnder > td {
  padding-bottom: 1em;
}

tr.spaceUnder > th {
  padding-bottom: 1em;
}

.smallRightBar {
  width: 70%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 40px;
  padding: 20px 40px;
}
.engagement {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mainTopHead {
  margin-top: 50px;
}

.tableLine {
  stroke-width: 8px;
  width: 75px;
  height: 10px;
  flex-shrink: 0;
  background: #3a74e9;
  border-radius: 5px;
}

.tableLine0 {
  stroke-width: 8px;
  width: 75px;
  height: 10px;
  flex-shrink: 0;
  background: #c3e54d;
  border-radius: 5px;
}

.mainBottom {
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 302px;
  flex-shrink: 0;
  padding: 40px 30px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  margin-top: 40px;
}

.tableCircle {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: rgba(37, 155, 53, 0.13);
}

.taleParent {
  display: flex;
  align-items: center;
  gap: 10px;
}
.title {
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 14px;
  margin-left: 5px;
  text-decoration: none;
}
.dTable {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: 20px;
}
.dTable0 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dTable1 {
  display: flex;
  align-items: center;
  gap: 20px;
}
.dullT {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dTable1 p:nth-child(2) {
  border-radius: 3px;
  background: rgba(37, 155, 53, 0.13);
  width: 93px;
  height: 27px;
  flex-shrink: 0;
  color: var(--style, #259b35);
  font-family: Inter;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.tDate {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dMapandTitle {
  display: flex;
  align-items: flex-start;

  gap: 50px;
}
.longTitle {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.BestTests {
  margin: 0px 20px;
  border-top: 1px solid black;
}

.BestTestsP2 {
  margin: 60px 20px;
}
.testOne {
  margin-top: 20px;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.testTwo {
  margin-top: 20px;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.testOne div:nth-child(1) {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.testOne div:nth-child(2) {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.testTwo div:nth-child(1) {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.testTwo div:nth-child(2) {
  color: var(--style, #259b35);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.InsightsTop {
  display: flex;
  align-items: flex-start;
  gap: 40px;
}

.InsightsTopLeft {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.inTD h1 {
  color: var(--style, #259b35);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.inTD p {
  color: var(--style, #259b35);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.green_heading {
  color: var(--style, #259b35);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media all and (max-width: 900px) {
  .downside {
    width: 100%;
    min-height: 1000px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
  }

  .downside .leftbar {
    width: 100%;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    border-radius: 40px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  }
  .smallRightBar {
    width: 100%;
  }
}

.mixin {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
