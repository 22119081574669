<script setup>
  const props = defineProps({
    height: {
      type: Number,
      default: 18,
    },
    width: {
      type: Number,
      default: 18,
    },
  });
</script>

<template>

  <svg :width="props.width" :height="props.height" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.697 9L14.825 3.79188L15.8825 2.71785C16.0385 2.55941 16.0385 2.30195 15.8825 2.14352L14.751 0.994336C14.595 0.835899 14.3415 0.835899 14.1855 0.994336L8 7.27648L1.8145 0.993828C1.6585 0.835391 1.405 0.835391 1.249 0.993828L0.117 2.14301C-0.039 2.30145 -0.039 2.55891 0.117 2.71734L6.303 9L0.117 15.2821C-0.039 15.4406 -0.039 15.698 0.117 15.8565L1.2485 17.0057C1.4045 17.1641 1.658 17.1641 1.814 17.0057L8 10.7235L13.128 15.9316L14.1855 17.0057C14.3415 17.1641 14.595 17.1641 14.751 17.0057L15.8825 15.8565C16.0385 15.698 16.0385 15.4406 15.8825 15.2821L9.697 9Z" fill="#A5AC9D"/>
  </svg>

</template>

<style scoped>

</style>