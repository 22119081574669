export const leftNavigationBarData = {
    spLogo: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/image-1-1@2x.png",
    scholarpath: "ScholarPath",
    HomeFeedStudentDefaultGroup2315: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64c419fccc94fbcba554b978/img/group-2336-7@2x.png",
    percent1: "60%",
    name: "Joe Johnson",
    text1: "3.5",
    percent2: "91%",
    completeSurveys: "Complete Surveys",
    gpa: "GPA",
    attendance: "Attendance",
    ellipse1: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64c419fccc94fbcba554b978/img/ellipse-1-14@2x.png",
    northRidgeHighSchool: "North Ridge High School",
    myResume: "My Resume",
    myTranscript: "My Transcript",
    armedServiceProfile: "Armed Service Profile",
    my4YearPlan: "My 4 Year Plan",
    groups: "Groups",
};

export const topNavigationBarData = {
    place: "Home",
};

export const broadcastPost1Data = {
    logo: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64c419fccc94fbcba554b978/img/ellipse-1-12@2x.png",
    surname: "Duke University",
    posted10MinAgo: "Posted 10 min ago",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    learnMore: "Learn More",
};

export const broadcastPost2Data = {
    whatIsAccessPoint: "What is Access Point?",
    posted10MinAgo: "Posted 10 min ago",
    rectangle8: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64c419fccc94fbcba554b978/img/rectangle-8.png",
    loremIpsumDolorSi: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    sendSvgrepoCom1: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/send-svgrepo-com-1-2@2x.png",
    envelope1: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/envelope-1-6@2x.png",
    heart1: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/heart-1-6@2x.png",
};

export const broadcastPost3Data = {
    ellipse1: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64c419fccc94fbcba554b978/img/ellipse-1-12@2x.png",
    surname: "Duke University",
    posted10MinAgo: "Posted 10 min ago",
    loremIpsumDolorSi: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    sendSvgrepoCom1: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/send-svgrepo-com-1-2@2x.png",
    envelope1: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/envelope-1-6@2x.png",
    heart1: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/heart-1-6@2x.png",
};

export const HomeFeedStudentDefaultFrame2337Data = {
    all: "All",
};

export const HomeFeedStudentDefaultFrame233721Data = {
    all: "Jobs",
};

export const HomeFeedStudentDefaultFrame233722Data = {
    all: "Scholarships",
    className: "scholarships-tab",
};

export const HomeFeedStudentDefaultFrame233723Data = {
    all: "Careers",
    className: "careers-tab",
};

export const broadcastFeedData = {
    broadcastPost1Props: broadcastPost1Data,
    broadcastPost2Props: broadcastPost2Data,
    broadcastPost3Props: broadcastPost3Data,
    HomeFeedStudentDefaultFrame2337Props: HomeFeedStudentDefaultFrame2337Data,
    HomeFeedStudentDefaultFrame233721Props: HomeFeedStudentDefaultFrame233721Data,
    HomeFeedStudentDefaultFrame233722Props: HomeFeedStudentDefaultFrame233722Data,
    HomeFeedStudentDefaultFrame233723Props: HomeFeedStudentDefaultFrame233723Data,
};

export const HomeFeedStudentDefaultGroup23431Data = {
    src: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/search-2-6.svg",
};

export const HomeFeedStudentDefaultGroup23432Data = {
    src: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/search-2-7.svg",
};

export const HomeFeedStudentDefaultGroup91Data = {
    rectangle10: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64c419fccc94fbcba554b978/img/rectangle-10-2@2x.png",
};

export const HomeFeedStudentDefaultGroup92Data = {
    rectangle10: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64c419fccc94fbcba554b978/img/rectangle-10-3@2x.png",
};

export const careerProfileFrameData = {
    careerProfile: "Career Profile",
    conventional: "Conventional",
    enterprising: "Enterprising",
    realistic: "Realistic",
    investigative: "Investigative",
    number1: "20",
    number2: "40",
    number3: "60",
    number4: "80",
    number5: "100",
    artistic: "Artistic",
    social: "Social",
    suggestedCareers: "Suggested Careers",
    technicalEducation: "Technical Education",
    x60000Annually1: "$60,000 annually",
    adultBasicSecondaryEd: "Adult Basic Secondary Ed...",
    x60000Annually2: "$60,000 annually",
    opportunities: "Opportunities",
    scholarpath: "ScholarPath",
    stPetersMissouri: "St. Peters Missouri",
    maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64c419fccc94fbcba554b978/img/mask-group-1@2x.png",
    HomeFeedStudentDefaultGroup23431Props: HomeFeedStudentDefaultGroup23431Data,
    HomeFeedStudentDefaultGroup23432Props: HomeFeedStudentDefaultGroup23432Data,
    HomeFeedStudentDefaultGroup91Props: HomeFeedStudentDefaultGroup91Data,
    HomeFeedStudentDefaultGroup92Props: HomeFeedStudentDefaultGroup92Data,
};

export const homeFeedStudentDefaultData = {
    leftNavigationBarProps: leftNavigationBarData,
    topNavigationBarProps: topNavigationBarData,
    broadcastFeedProps: broadcastFeedData,
    careerProfileFrameProps: careerProfileFrameData,
};

