<script setup>
import {watch} from 'vue';

const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue", "close"]);
const close = () => {
  emit('update:modelValue', false);
  emit('close');
}

watch(() => props.modelValue, (value) => {
  if (value) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
}, {immediate: true});
</script>

<template>
  <div class="group" :class="modelValue ? 'active' : ''">
    <div
        class="fixed px-4 overflow-y-auto inset-0 z-50 overflow-hidden opacity-0 invisible group-[.active]:opacity-100 p-10 group-[.active]:visible transition-all duration-300 backdrop-blur-sm bg-dark/30 lg:flex justify-center items-start"
    >
      <div
          class="mx-auto min-w-[400px] overflow-hidden rounded-[40px] bg-white p-2 shadow-2xl relative translate-y-10 group-[.active]:translate-y-0 transition-all duration-500"
      >
        <button
            type="button"
            class="absolute right-5 top-5 w-8 h-8 flex justify-center items-center"
            @click="close"
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="stroke-primary-100"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>