import { defineStore } from "pinia";

export const useAccordion = defineStore("accordion", {
  state: () => ({
    isSAT: null,
    isACT: null,
    isGPA: null,
    isASVAB: null,
    isEmail: null,
    isPhone: null,
  }),
  actions: {
    setSAT(value) {
      this.isSAT = value;
    },
    setACT(value) {
      this.isACT = value;
    },
    setGPA(value) {
      this.isGPA = value;
    },
    setASVAB(value) {
      this.isASVAB = value;
    },
    setEmail(value) {
      this.isEmail = value;
    },
    setPhone(value) {
      this.isPhone = value;
    },
  },
});
