<template>
    <div class="home-page-educator-side">
        <top-educator-navigation-bar v-bind="leftNavigationBarProps" :userObject="userObject" />
        <educator-home-feed v-bind="leftNavigationBarProps" :userObject="userObject" />
    </div>
</template>

<script>
import TopEducatorNavigationBar from './TopNavigationBar.vue'
import EducatorHomeFeed from './EducatorHomeFeed.vue';
export default {
    components: {
        TopEducatorNavigationBar,
        EducatorHomeFeed
    },
    data() {
        return {
            currentLink: null,
        };
    },
    created() {
        this.currentLink = this.$router.currentRoute.path;
    },
    mounted() {
        this.currentLink = this.$router.currentRoute.path;
    },
    methods: {
        goToSurvey(link) {
            this.$router.push(link);
            this.currentLink = this.$router.currentRoute.path;

        },
    },
    name: "HomeFeedEducatorDefault",
    props: [
        "jj",
        "leftNavigationBarProps",
        "topNavigationBarProps",
        "broadcastFeedProps",
        "careerProfileFrameProps",
        "userObject",
    ],
};
</script>

<style scoped>
.home-page-educator-side {
    align-items: center;
    background-color: #f3f7f8;
    box-sizing: border-box;
}
</style>
