export default [
  {
    company_name: "Elkhorn Healthcare and Rehabilitation",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "CNA - Certified Nursing Assistant",
    location: "Clancy, MT",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "$18 - $23 an hour",
    summary:
      "Company & Job Description Overview: Elkhorn Healthcare and Rehabilitation is currently searching for talented CNA’s to join our team! Our facilities…",
    url: "https://www.indeed.com/pagead/clk?mo=r&ad=-6NYlbfkN0Akemu1KrSYwI3vEahOt2w3qrMO_qHCTyPQ3mP2QpYu5K7YJHGkqLKSAoAH-m14U4le_2g_NPP_VFWybb7eecuUTb07krnmhu2-0SZXGlStNOIy-HsPsdt99GDFvtp1o3isOGHOxHRAMoEyErVyXZmeXFEdLfmYRh9z1vcyW7VpOh49Qu9FUEY18Rm8KQ3YLPePcwL5xfTV_crF3m9wAROVgKj_GIykVkSFT2HtFtXXotcFkk9gZ_MxSLrNJqyyG3v3PUOyKJql9MAuhQq5IbXUo0A5AK0el8IVDHfRMEKmhgdf68IhPlceU2GQhFskq3a38iUWXjhLv1pO4q7B_QH2kUkqjok6EBv6mq0BGl4xaXS3NzV6Pw-gTQ7pw3XsoeuVFa_qWsnGvBmrDlvhnqXM9OqI239RLqles4398fQvBQSU9k_o7bC4AxtvIkrfVTKu-oHGa231c-_nEhCejygz55cHwYzys4lfFfCKDPn_ZQUw5GC-Qyu2SGtjphtksqA7fF3Tj5esmb-2gjoZpmBjXSpgZfK-E3n-sfHU9W6cGYxBKALOk1PT&xkcb=SoAL-_M3O_EstNxgTh0LbzkdCdPP&p=0&fvj=1&vjs=3",
  },
  {
    company_name: "Custom Healthcare Solutions",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "Certified Nursing Assistant (CNA) *Weekends",
    location: "Township of Wall, NJ",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "Up to $30 an hour",
    summary:
      "Custom Healthcare Solutions is seeking skilled and experienced Certified Nursing Assitant’s (CNA) for an exciting opportunity in Wall, NJ! Details: * …",
    url: "https://www.indeed.com/pagead/clk?mo=r&ad=-6NYlbfkN0ABJ9HH5wM6dqPqxt16p2Wg4f736QQirCeYrch3n1bJ0WuI8zwkMuYYimthAv6YF4dxVdhkfFVC68Z3jwjdFtRBSKoP0602z-scgSxEOpxYaehnKIkrORZWe4RC2B79PvE3tG7NuYLrz6BHRO8mC_D31S1hYb4RKrM2KGKpeN2YIGIhxaYo95ouNRJWLNeEQTZs0Z6mclHNnyUkVmIW8ejktgPph3-lh2NxjMn5EL4-L8bwOmWLQwRqG7w9NpHG4MPfCgKMdgVXkWGiaqRxbBYLmB8NgMvSED9x0JZVPovXPp3YGjLllPYls9FkCjENUs_CxS5Ouo8CESLzqqE2mUiHQ7hyrOZtbj0Lq34rUWGPQV8UMRjG-n4XgOaG92Ja9uEpVSk856-gC7T8Oo_FvSoH1GtoRKyKu4cXSvY4YUHv9oNWTVRFM_rShIzkFcB1UBEkMBILnOhFVhY3De92I9d1XmZVPOzZsTygvU1pUKLjIe10P1XI7qGee4h0YBiM9FLeb1iWkD6RXUcRdm_3M7Xnsbzw_7ux8HPuLoupPEEUwQ==&xkcb=SoC_-_M3O_EstNxgTh0KbzkdCdPP&p=1&fvj=1&vjs=3",
  },
  {
    company_name: "Regional Care Network",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "CNA (Certified Nursing Assistant)",
    location: "Emporia, VA 23847",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "$25 an hour",
    summary:
      "CNA (Certified Nursing Assistant) - $25 an hour (Emporia, VA) Regional Care Network is actively hiring caring and compassionate nursing aides who strive for…",
    url: "https://www.indeed.com/rc/clk?jk=5d660034465fb871&fccid=63de07ba2d49c90c&vjs=3",
  },
  {
    company_name: "StaffAid",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "Certified Nursing Assistant",
    location: "Holmdel, NJ 07733",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "$80,000 a year",
    summary:
      "Job description A long-term care facility is seeking Certified Nursing Assistants to join their team. Join our team as a member of our proud and caring staff…",
    url: "https://www.indeed.com/rc/clk?jk=0aa1f697ca919e83&fccid=f403b84b2716bd40&vjs=3",
  },

  {
    company_name: "UCHealth",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "CNA Surgical Specialties Full Time Rotating",
    location: "Highlands Ranch, CO 80129",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "$18.54 - $24.10 an hour",
    summary:
      "Location: Highlands Ranch, CO, United States Category: Technicians/Patient Care Support Job Type: Full Time Job ID: 126374 Date Posted: Jun 27, 2023…",
    url: "https://www.indeed.com/rc/clk?jk=9718146f44820682&fccid=34bd3ad671ce9cba&vjs=3",
  },
  {
    company_name: "UF Health Central Florida",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "Nursing Assistant, Observation Unit",
    location: "The Villages, FL 32159",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "",
    summary:
      "Job Opening Summary Under the direction of the director/manager and RNs, the nursing assistant facilitates patient care by providing the basic physical care…",
    url: "https://www.indeed.com/rc/clk?jk=157d428dedee703d&fccid=3a1835496c6a0d13&vjs=3",
  },
  {
    company_name: "Blue Space Health Care INC",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "CNA Certified Nursing Assistant",
    location: "Cody, WY 82414",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "$31 - $32 an hour",
    summary:
      "*Urgent Hiring for CNA in Cody, WY. Number of Weeks: 13 * Shift Information: 12 hour nights * Guaranteed Hours: 48 * _*Overtime: Yes Starts…",
    url: "https://www.indeed.com/company/Blue-Space-Health-Care-INC/jobs/Nursing-Assistant-1ca216b6b589f058?fccid=72225ef28020a0ad&vjs=3",
  },
  {
    company_name: "Health Care INC",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "Patient Care Assistant- Women Specialty Unit",
    location: "Houston, TX 77001",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "Estimated $32.6K - $41.2K a year",
    summary:
      "We are searching for a Patient Care Assistant - someone who works well in a fast-paced hospital setting. In this position, you'll perform a variety of routine…",
    url: "https://www.indeed.com/rc/clk?jk=4091cbaf1291428b&fccid=513ff76657ded866&vjs=3",
  },
  {
    company_name: "Kaiser Permanente",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "Patient Care Technician",
    location: "Fresno, CA",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "$32.67 - $34.38 an hour",
    summary:
      "The Patient Care Technician supports the work of the Registered Nurse to meet the needs of patients in order to provide safe, quality care. Essential…",
    url: "https://www.indeed.com/rc/clk?jk=259c6687d891c529&fccid=48ecd526e3aa3225&vjs=3",
  },
  {
    company_name: "Baptist Health Arkansas",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "Patient Care Tech I",
    location: "Little Rock, AR",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "Estimated $23.7K - $30K a year",
    summary:
      "Shift: Night Working Hours: 7p - 7a Summary: The primary function of the PCT is to assist the patient and family with basic patient care, monitoring, hygiene…",
    url: "https://www.indeed.com/rc/clk?jk=59b1878f0077f15a&fccid=3226c19dc7ecafc8&vjs=3",
  },
  {
    company_name: "Direct Care Staffing",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "CNA - Certified Nursing Assistant, McALESTER OK",
    location: "McAlester, OK",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "$18 - $25 an hour",
    summary:
      "Ready to take control of your schedule? Start your DirectCare Shifts application and you will be on your way to a better work- life balance in no time. Apply…",
    url: "https://www.indeed.com/rc/clk?jk=21689dc44b5f197f&fccid=bfc8a0132feeb440&vjs=3",
  },
  {
    company_name: "Casey's Pond",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "Certified Nurse Asst (CNA) - Assisted Living - $20.50 - $34.05/hr DOE",
    location: "Steamboat Springs, CO 80487",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "$20.50 - $34.05 an hour",
    summary:
      "Our community is thriving! We are expanding and looking for talented, committed individuals to join our team. If you share our passion for serving older…",
    url: "https://www.indeed.com/rc/clk?jk=7f00d2090a3e2ba7&fccid=a7fc7007218b8cdb&vjs=3",
  },
  {
    company_name: "Beth Israel Deaconess Hospital Plymouth",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "CNA per diem",
    location: "Plymouth, MA 02360 (Wellingsley/Jabez Corner area)",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "Estimated $38.9K - $49.3K a year",
    summary:
      "When you join the growing BILH team, you're not just taking a job, you’re making a difference in people’s lives. Job Type: Per Diem Scheduled Hours: 0…",
    url: "https://www.indeed.com/rc/clk?jk=71104e2eec1f1a1f&fccid=e668f9dd10bd73f7&vjs=3",
  },
  {
    company_name: "Vail Health Hospital",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "Remote Clinical Assistant",
    location: "Remote in Vail, CO 81657",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "Estimated $36.3K - $45.9K a year",
    summary:
      "To us, it's about living life to the fullest while serving our patients, teammates, neighbors and friends. We are dedicated and passionate in everything we do…",
    url: "https://www.indeed.com/rc/clk?jk=15616482bc14c626&fccid=73cf31df21a56ed6&vjs=3",
  },
  {
    company_name: "UVA Medical Center",
    current_page_num: 1,
    date: "Just posted",
    has_next_page: "TRUE",
    job_title: "Certified Nurse Assistant (CNA) - Postpartum/Antepartum, Mother/Baby",
    location: "Charlottesville, VA",
    next_page_url: "https://www.indeed.com/jobs?q=cna&l=United+States&sort=date&start=10",
    salary: "$15.37 - $23.82 an hour",
    summary:
      "**$4,000 Sign On Bonus for qualified candidates with a 1 year work commitment to UVA Health** TIME TYPE: FULL TIME SHIFT: 1st (Day) Your next opportunity…",
    url: "https://www.indeed.com/rc/clk?jk=7443dfaf47a75092&fccid=ac3ca395d43dcd7d&vjs=3",
  },
];