<template>

  <div
    class="w-full h-full border-gray-400 bg-white rounded-3xl flex flex-col items-center justify-start box-border p-5">
    <!-- Tabs Start -->
    <div class="w-full sticky top-0">
      <div class="w-full flex items-center gap-4 mb-5">
        <div v-for="(tab, index) in mainTabs" :key="tab.title" class="custom-select">
          <div class="select-box"
            :style="{ backgroundColor: index === selectedMainTab ? '#259B35' : 'rgba(37, 155, 53, 0.13)' }"
            @click="toggleDropdown(index)">
            <span class="mr-4" :style="{
              color: index === selectedMainTab ? '#fff' : '#000',
            }">
              {{ tab.title }}
            </span>
            <span class="arrow" :style="{
              borderColor: index === selectedMainTab ? '#fff' : '#28a745',
              borderWidth: '0 2px 2px 0',
            }" :class="{ open: isOpen[index] }" />
          </div>
          <ul v-if="isOpen[index]" class="options scrollable-options">
            <li v-for="(option, subIndex) in tab.tabs" :key="option" @click="selectOption(index, option, subIndex)"
              class="wrap-ellipsis">
              {{ option }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Tabs End -->

    <!-- Content Start -->
    <div class="w-full h-full overflow-y-scroll">
      <div v-if="selectedOptions !== 'Career Interest'" class="text-[20px] font-normal pt-2 pb-6">
        {{ selectedOptions }}
      </div>

      <template v-if="selectedMainTab === 0">
        <!-- School Data -->
        <template v-if="selectedSubTab === 0">
          <!-- Course History -->
          <slot name="school-courseHistoy" />
        </template>
        <template v-if="selectedSubTab === 1">
          <!-- GPA - Attendance - SAT - ACT -->
          <slot name="school-gpa" />
        </template>
      </template>

      <template v-else-if="selectedMainTab === 1">
        <!-- Questionnaire Results -->
        <template v-if="selectedSubTab === 0">
          <!-- Career -->
          <slot name="ques-career" />
        </template>
        <template v-else-if="selectedSubTab === 1">
          <!-- Armed Service -->
          <slot name="ques-armed" />
        </template>
        <template v-else-if="selectedSubTab === 2">
          <!-- College -->
          <slot name="ques-college" />
        </template>
        <template v-else-if="selectedSubTab === 3">
          <!-- Learning Styles -->
          <slot name="ques-learning" />
        </template>
        <template v-else-if="selectedSubTab === 4">
          <!-- Accomplishments and Involvement -->
          <slot name="ques-accomplishments" />
        </template>
        <template v-else-if="selectedSubTab === 5">
          <!-- Demographic Quesionnaire -->
          <slot name="ques-demographic" />
        </template>
      </template>

      <template v-else-if="selectedMainTab === 2">
        <!-- Creations -->
        <template v-if="selectedSubTab === 0">
          <!-- Academic Plan -->
          <slot name="creations-academic" />
        </template>

        <template v-else-if="selectedSubTab === 1">
          <!-- Resume -->
          <slot name="creations-resume" />
        </template>
      </template>
    </div>
    <!-- Content End -->
  </div>

</template>
<script setup>
import { onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import MainLayout from "../layouts/MainLayout.vue";

const router = useRouter();
const route = useRoute();

const selectedMainTab = ref(0);
const selectedSubTab = ref(0);
const mainTabs = ref([
  { title: "School Data", tabs: ["Course History", "Scores"] },
  {
    title: "Questionnaire Results",
    tabs: [
      "Career Interest",
      "Armed Service Interest",
      "College Interest",
      "Learning Styles",
      "Accomplishments and Involvement",
      "Demographic Quesionnaire",
    ],
  },
  {
    title: "Plan & Build",
    tabs: ["Academic Plan", "Resumes"],
  },
]);

const selectedOptions = ref("");

const isOpen = ref(new Array(mainTabs.value.length).fill(false));

const toggleDropdown = (index) => {
  isOpen.value = isOpen.value.map((_, i) => (i === index ? !isOpen.value[i] : false));
};

const selectOption = (tabIndex, option, subIndex) => {
  selectedOptions.value = option;
  selectedMainTab.value = tabIndex;
  selectedSubTab.value = subIndex;
  isOpen.value[tabIndex] = false;
};

const setMainTab = (tab) => {
  router.push("/portfolio?tab=" + tab);
  selectedMainTab.value = tab;
};

onBeforeMount(() => {
  const tab = route.query.tab;
  selectedMainTab.value = tab ? parseInt(tab) : 0;
});
</script>
<style>
.subTabWrapper {
  @apply w-full flex items-center border-b gap-1 border-gray-200;
}

.selectedSubTab {
  @apply bg-gray-200 text-gray-800 font-medium;
}

.custom-select {
  position: relative;
  display: inline-block;
}

.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 30px;
  border-radius: 100px;
  cursor: pointer;
}

.scrollable-options {
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  list-style: none;
}

.arrow {
  border: solid #28a745;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(-135deg);
}

.options {
  list-style: none;
  margin-top: 10px;
  padding: 10px;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  z-index: 100;
}

.options li {
  padding: 10px;
  cursor: pointer;
  border-radius: 20px;
}

.options li:hover {
  background-color: rgba(37, 155, 53, 0.13);
  color: rgba(37, 155, 53, 1);
}

.wrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
