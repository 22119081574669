export async function importServerPublicKey(pemKey) {
    const rawKey = pemKey
        .replace(/-----BEGIN PUBLIC KEY-----/, '')
        .replace(/-----END PUBLIC KEY-----/, '')
        .replace(/\n/g, '');
    const uint8Array = new Uint8Array([...atob(rawKey)].map(a => a.charCodeAt(0)));

    // Log the length and a portion of the uint8Array to check its correctness.
   try {
    const key = await window.crypto.subtle.importKey(
        'spki',
        uint8Array,
        {
            name: 'RSA-OAEP',
            hash: { name: 'SHA-256' }
        },
        false,
        ['encrypt']
    );
    return key;
   } catch (error) {
       // Catch any error during the key import.
       throw error; // Re-throw the error if you want the caller to know about it.
   }
};
async function pemToCryptoKey(pem) {
    const rawKey = pem
        .replace(/-----BEGIN PUBLIC KEY-----/, '')
        .replace(/-----END PUBLIC KEY-----/, '')
        .replace(/\n/g, '');
    const uint8Array = new Uint8Array([...atob(rawKey)].map(a => a.charCodeAt(0)));

    return window.crypto.subtle.importKey(
        'spki',
        uint8Array,
        {
            name: 'RSA-OAEP',
            hash: { name: 'SHA-256' }
        },
        false,
        ['encrypt']
    );
}

export async function encryptWithPublicKey(publicKey, data) {
    try {
        const encodedData = new TextEncoder().encode(data);
        const encryptedData = await window.crypto.subtle.encrypt(
            {
                name: 'RSA-OAEP'
            },
            publicKey,
            encodedData
        );

        return encryptedData;
    } catch(error) {
        throw new Error("Failed to encrypt data with the provided public key.");
    }
};

