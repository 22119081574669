<template>
    <div class="wrapper">
        <h3>Parental Controls</h3>
        <div class="content">
            <p>
                A Parent has full viewing rights to their student’s ScholarPath account. These viewing rights include
                every
                aspect of the student’s account including the messaging section.
            </p>

            <p>
                In the upper navigation, there is a Resources tab with explanations on every aspect of the ScholarPath
                platform.
            </p>
            <p>
                On the student’s home page is a one-way newsfeed.  The Providers (college admission counselors, Armed
                Services
                recruiters, and local businesses) will post information and opportunities for the students.
            </p>

            <p>
                Students are anonymous on the platform.  Providers will not know the student’s name unless the student
                shares one
                of these three things:
            <ul class="ulE">
                <li>Course history</li>
                <li>Resume</li>
                <li>Armed Services Profile (This is the answers to basic questions related to interest in the Armed
                    Services)</li>
            </ul>
            </p>
            <p>ScholarPath has given 100% control of the student / Provider messaging to the Parent.  On the following
                page
                are the
                messaging parent controls.  You may change these settings at any time.</p>
        </div>
        <div class="btn">
            <button @click="proceedToMessageSettings">Continue to Mesages Settings --></button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        proceedToMessageSettings() {
            this.$emit('continue');
        },
    },
};
</script>

<style scoped>
.wrapper {

    h3 {
        font-size: 18px;
        color: #475F4A;
    }
}

.content {
    /* height: 500px; */
    overflow: auto;

    p {
        margin-top: 25px;
        font-size: 18px;
        color: #475F4A;
        font-weight: 300;
    }


    .ulE {
        color: #475F4A;
        margin-left: 25px;
        list-style: disc;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 300;
    }



}

.btn {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end
}

button {
    background-color: rgba(37, 155, 53, 1);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
}
</style>
