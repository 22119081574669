<template>
    <div class="cluster-page-educator-side">
        <top-educator-navigation-bar v-bind="leftNavigationBarProps" :userObject="userObject" />
        <div class="body-content">
            <div class="nav">
                <div>
                    <p>
                        Career Clusters & Pathways
                    </p>
                    <p class="support-text">There are 16 Clusters and 79 Pathways.</p>
                </div>
                <div>
                    <p>
                        Courses
                    </p>
                    <p class="support-text">
                        Turn on Clusters that align with your school.
                    </p>
                </div>

            </div>
            <div class="table">
                <table style="width: 100%">
                    <thead>
                        <th class="table-header" style="width: 70%">Cluster</th>
                        <th class="table-header">Status</th>
                        <th class="table-header"></th>
                    </thead>
                    <tbody>
                        <!-- Loop through student items -->
                        <tr>
                            <td class="table-data">Agriculture</td>
                            <td class="table-data-status">Off</td>
                            <td class="table-data-icon"><ArrowRightSvg /></td>
                        </tr>
                        <!-- Repeat for each student item -->
                        <tr>
                            <td class="table-data">Agriculture</td>
                            <td class="table-data-status">Off</td>
                            <td class="table-data-icon"><ArrowRightSvg /></td>
                        </tr>
                        <tr>
                            <td class="table-data">Agriculture</td>
                            <td class="table-data-status">Off</td>
                            <td class="table-data-icon"><ArrowRightSvg /></td>
                        </tr>
                        <tr>
                            <td class="table-data">Agriculture</td>
                            <td class="table-data-status">Off</td>
                            <td class="table-data-icon"><ArrowRightSvg /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import TopEducatorNavigationBar from './TopNavigationBar.vue'
import ArrowRightSvg from './ArrowRightSvg.vue'
export default {
    components: {
        TopEducatorNavigationBar,
        ArrowRightSvg,
    },
    data() {
        return {
            currentLink: null,
        };
    },
    created() {
        this.currentLink = this.$router.currentRoute.path;
    },
    mounted() {
        this.currentLink = this.$router.currentRoute.path;
    },
    methods: {
        goToSurvey(link) {
            this.$router.push(link);
            this.currentLink = this.$router.currentRoute.path;
        },
    },
    name: "ClusterPathWay",
    props: [
        "jj",
        "leftNavigationBarProps",
        "topNavigationBarProps",
        "broadcastFeedProps",
        "careerProfileFrameProps",
        "userObject",
    ],
};
</script>

<style scoped>
.cluster-page-educator-side {
    align-items: center;
    background-color: #f3f7f8;
    box-sizing: border-box;
}

.body-content {
    margin: 43px;
    font-family: inter;
    background: #fff;
    padding: 30px;
    border-radius: 50px;

    .nav {
        display: flex;
        gap: 16px;

        .support-text {
            color: #A5AC9D;
            font-size: 15px;
            font-family: inter;
            line-height: 18.5px;
            font-weight: 400;
        }
    }

    .table {
        width: 100%;

        .table-header {
            color: #475F4A;
            font-family: inter;
            font-weight: 600;
            font-size: 20px;
            padding-top: 24px;
            padding-right: 32px;
            text-align: start;
        }

        .table-data {
            color: #000;
            font-weight: 400;
            font-family: inter;
            padding-top: 16px;
        }
        .table-data-status {
            color: #A5AC9D;
            font-weight: 400;
            font-family: inter;
            padding-top: 16px;
        }
        .table-data-icon {
            padding-top: 16px;
            display: flex;
            align-items: center;
            justify-content: end;
        }
    }

    .item {
        background: #fff;
        padding: 30px;
        border-radius: 50px;
        font-family: inter;

        .header {
            color: #475F4A;
            margin-bottom: 16px;
        }

        .item-header {
            display: flex;
            gap: 20px;
            justify-content: space-between;
            font-size: 12px;
            color: #A5AC9D;
            font-weight: 500;
            margin-top: 24px;
            margin-bottom: 16px;
        }

        .item-content {
            display: flex;
            gap: 20px;
        }
    }
}
</style>
