<!-- PasswordReset.vue -->
<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal-content">
      <h2>Register Your Account</h2>
      <form @submit.prevent="resetPassword">
        <div class="form-group">
          <label for="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            v-model="firstName"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label for="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            v-model="lastName"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label for="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            v-model="newPassword"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            v-model="passwordConfirm"
            class="form-control"
            required
          />
        </div>
        <button type="submit" class="btn">Register</button>
      </form>
      <button @click="closeModal" class="btn">Close</button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showModal: true,
        newPassword: '',
        passwordConfirm: '',
        firstName: '',
        lastName: '',
        email: this.$route.query.email || '',
        token: this.$route.query.token || '',
      };
    },
    methods: {
      async resetPassword() {
        if (this.newPassword !== this.passwordConfirm) {
          alert('Passwords do not match');
          return;
        }

        try {
          const response = await this.$request('/v1/signup', 'POST',{
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.newPassword,
            passwordConfirm: this.passwordConfirm,
            token: this.token,
          });

          if (response.status === 200) {
            alert('Password reset successfully');
            this.closeModal();
            this.$router.push('/login');
          } else {
            alert('Failed to reset password');
          }
        } catch (error) {
          alert('An error occurred while resetting the password');
        }
      },
      closeModal() {
        this.showModal = false;
        this.$router.push('/');
      },
    },
  };
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: var(--black-haze);
    color: #333;
  }

  .form-control::placeholder {
    color: #cccccc99;
  }

  .btn {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #4CAF50; /* Green theme color */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }

  .btn:hover {
    background-color: #45A049; /* Darker green for hover */
  }

  /* Additional styling to match the provided theme */
  h2 {
    color: var(--style);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-l);
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
  }
</style>
