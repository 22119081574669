<template>
    <div class="w-full px-10 py-5 ">
        <ul class="w-full list-disc grid grid-cols-2 gap-x-10 gap-y-5" v-if="workValues && workValues.length>0">
            <li v-for="el in workValues" :key="el['Element ID']">
                <h3 class="font-semibold text-gray-800 mb-2.5">{{ getName(el['Element ID']) }}</h3>
                <p class="text-gray-600 text-sm">{{ getDescription(el['Element ID']) }}</p>
            </li>
        </ul>
        <p class="p-10 flex items-center justify-center w-full text-lg font-medium text-gray-700" v-else>
            No Data Found !!
        </p>
    </div>
</template>
<script>
import WorkStyle from "../../assets/js/filtered_occupation/workStyle/workStyle.json";
import ContentModelRef from "../../assets/js/filtered_occupation/workStyle/ContentModelREf.json"
export default{
    props:["OnetCode"],
    computed:{
        workValues(){
            return WorkStyle.filter(el => el["O*NET-SOC Code"] === this.OnetCode)
        },
    },
    methods:{
        getName(id){
            return ContentModelRef.find(el => el["Element ID"] === id)["Element Name"]
        },
        getDescription(id){
            return ContentModelRef.find(el => el["Element ID"] === id)["Description"]
        }
    }
}
</script>