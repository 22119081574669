<template>
  <div class="rounded-[50px] p-8 bg-white shadow-3xl space-y-4 mb-6 mt-5">
    <h3 class="text-xl font-medium text-black">Enrollment</h3>
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 inline-block w-full sm:px-6 lg:px-8">
        <table class="w-full">
          <thead>
            <tr>
              <th
                v-for="(col, index) in colNames"
                :key="index"
                class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[20px] pb-4"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="(row, index) in rowsData"
                :key="index"
                class="text-[var(--style)] font-medium text-[30px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5"
              >
                {{ row }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default{
    props:[
        "collegeData"
    ],
    data(){
        return {
          colNames: ["Total", "Undergraduate", "Graduate", "Part Time Students", "Full Time Students"],
          rowsData: ["13,991", "10,562", "3,429", "19%", "81%"],
        }
    },
    computed:{
        totalFT(){
            const menFT = parseInt(this.collegeData.EN_TOT_FT_MEN_N)
            const womenFT = parseInt(this.collegeData.EN_TOT_FT_WMN_N)
            return menFT + womenFT;
        },
        totalPT(){
            const menPT = parseInt(this.collegeData.EN_TOT_PT_MEN_N)
            const womenPT = parseInt(this.collegeData.EN_TOT_PT_WMN_N)
            return menPT + womenPT;
        },
        total_Student(){
            return  this.totalFT + this.totalPT;
        }
    },
    methods:{
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }

}
</script>
<style scoped>
.enr-card{
  @apply w-full h-40 max-h-40 bg-white flex items-center justify-center shadow-normal rounded-md  relative;
}
.enr-title{
  @apply font-medium absolute left-5 top-5 text-gray-500 w-full ;
}
.enr-text{
  @apply font-bold text-4xl text-black
}
</style>