<template>
  <div class="main">
    <div class="main_child">
      <div class="upperside">
        <div class="mainUserInfo"></div>

        <div class="mainCentralLink">
          <div class="centralLinks">
            <div class="top-navigation-bar">
              <router-link
                to="/homeFeed"
                :class="[currentLink === '/homeFeed' ? 'parent_a' : '']"
              >
                <svg
                  @click="goToSurvey('/homeFeed')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 23 20"
                  fill="none"
                  :class="[
                    currentLink === '/homeFeed' ? 'active' : 'inactive',
                    'home-lg-alt-1 main_link',
                  ]"
                >
                  <g clip-path="url(#clip0_724_1652)">
                    <path
                      d="M11.5004 4.49258L2.77437 12.0203C2.70969 12.0773 2.62703 12.1039 2.55595 12.1512V19.3754C2.55595 19.5411 2.62326 19.7001 2.74308 19.8173C2.86289 19.9345 3.0254 20.0004 3.19484 20.0004H8.30595C8.4754 20.0004 8.6379 19.9345 8.75772 19.8173C8.87753 19.7001 8.94484 19.5411 8.94484 19.3754V14.3754C8.94484 14.2096 9.01215 14.0507 9.13197 13.9334C9.25178 13.8162 9.41429 13.7504 9.58373 13.7504H13.4171C13.5865 13.7504 13.749 13.8162 13.8688 13.9334C13.9886 14.0507 14.056 14.2096 14.056 14.3754V19.3754C14.056 19.5411 14.1233 19.7001 14.2431 19.8173C14.3629 19.9345 14.5254 20.0004 14.6948 20.0004H19.806C19.9754 20.0004 20.1379 19.9345 20.2577 19.8173C20.3775 19.7001 20.4448 19.5411 20.4448 19.3754V12.1527C20.377 12.1074 20.2963 12.0816 20.2348 12.0277L11.5004 4.49258ZM22.7884 9.23007L12.5685 0.401951C12.276 0.143241 11.8957 0 11.5014 0C11.1071 0 10.7268 0.143241 10.4343 0.401951L0.212029 9.23007C0.149586 9.28493 0.0988021 9.35128 0.0625783 9.42534C0.0263544 9.49939 0.00540059 9.57971 0.000913761 9.66169C-0.00357307 9.74367 0.00849503 9.82571 0.0364286 9.90313C0.0643623 9.98055 0.107614 10.0518 0.163713 10.1129L1.01863 11.0434C1.0747 11.1044 1.14252 11.1541 1.21823 11.1896C1.29393 11.225 1.37603 11.2455 1.45983 11.2499C1.54364 11.2543 1.6275 11.2425 1.70664 11.2151C1.78578 11.1878 1.85864 11.1455 1.92106 11.0906L11.0779 3.18906C11.1947 3.08833 11.3449 3.03274 11.5006 3.03274C11.6562 3.03274 11.8065 3.08833 11.9233 3.18906L21.0801 11.0902C21.1426 11.1451 21.2154 11.1874 21.2946 11.2147C21.3737 11.2421 21.4576 11.2539 21.5414 11.2495C21.6252 11.2451 21.7073 11.2246 21.783 11.1892C21.8587 11.1537 21.9265 11.1041 21.9826 11.043L22.8371 10.1125C22.9502 9.98915 23.0086 9.82689 22.9995 9.66142C22.9904 9.49594 22.9144 9.34079 22.7884 9.23007Z"
                      fill="currentcolor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_724_1652">
                      <rect width="23" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </router-link>
              <router-link
                to="/surveyDefault"
                :class="[currentLink === '/surveyDefault' ? 'parent_a' : '']"
              >
                <svg
                  width="23"
                  height="20"
                  viewBox="0 0 23 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="23" height="20" fill="#1E1E1E" />
                  <g id="Message Page">
                    <rect
                      width="1512"
                      height="982"
                      transform="translate(-747 -51)"
                      fill="#F3F7F8"
                    />
                    <g id="Provider Top Navigation">
                      <g id="Rectangle 3" filter="url(#filter0_d_0_1)">
                        <rect
                          x="-112"
                          y="-21"
                          width="243"
                          height="70"
                          rx="35"
                          fill="none"
                          @click="goToSurvey('/surveyDefault')"
                          :class="[
                            currentLink === '/surveyDefault'
                              ? 'active'
                              : 'inactive',
                            'home-lg-alt-1 main_link',
                          ]"
                        />
                      </g>
                      <g id="bullhorn 2" clip-path="url(#clip0_0_1)">
                        <path
                          id="Vector"
                          d="M23 9.375C23 8.45195 22.4829 7.65469 21.7222 7.22188V1.25039C21.7222 0.908594 21.4435 0 20.4444 0C20.1601 0 19.8778 0.0929688 19.6466 0.274219L16.2513 2.93164C14.5459 4.26523 12.4048 5 10.2222 5H2.55556C1.14401 5 0 6.11914 0 7.5V11.25C0 12.6309 1.14401 13.75 2.55556 13.75H3.90122C3.84571 14.1594 3.81417 14.5758 3.81417 15C3.81417 16.5535 4.18392 18.0215 4.83479 19.3336C5.04203 19.7512 5.49444 20 5.96882 20H8.93486C9.97505 20 10.5996 18.8344 9.96906 18.025C9.3142 17.1844 8.92488 16.1359 8.92488 15C8.92488 14.566 8.98957 14.1488 9.10097 13.75H10.2222C12.4048 13.75 14.5459 14.4848 16.2509 15.8184L19.6462 18.4758C19.8727 18.6531 20.154 18.7498 20.444 18.75C21.4391 18.75 21.7218 17.8602 21.7218 17.5V11.5285C22.4829 11.0953 23 10.298 23 9.375ZM19.1667 14.8992L17.847 13.8664C15.6907 12.1789 12.9822 11.25 10.2222 11.25V7.5C12.9822 7.5 15.6907 6.57109 17.847 4.88359L19.1667 3.85078V14.8992Z"
                          fill="#A5AC9D"
                        />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_0_1"
                      x="-124"
                      y="-33"
                      width="267"
                      height="94"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="6" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_0_1"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_0_1"
                        result="shape"
                      />
                    </filter>
                    <clipPath id="clip0_0_1">
                      <rect width="23" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </router-link>

              <router-link
                to="/messages"
                :class="[currentLink === '/messages' ? 'parent_a' : '']"
              >
                <svg
                  @click="goToSurvey('/messages')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  :class="[
                    currentLink === '/messages' ? 'active' : 'inactive',
                    'home-lg-alt-1 main_link',
                  ]"
                >
                  <path
                    d="M19.6211 7.45312C19.7734 7.33203 20 7.44531 20 7.63672V15.625C20 16.6602 19.1602 17.5 18.125 17.5H1.875C0.839844 17.5 0 16.6602 0 15.625V7.64062C0 7.44531 0.222656 7.33594 0.378906 7.45703C1.25391 8.13672 2.41406 9 6.39844 11.8945C7.22266 12.4961 8.61328 13.7617 10 13.7539C11.3945 13.7656 12.8125 12.4727 13.6055 11.8945C17.5898 9 18.7461 8.13281 19.6211 7.45312ZM10 12.5C10.9062 12.5156 12.2109 11.3594 12.8672 10.8828C18.0508 7.12109 18.4453 6.79297 19.6406 5.85547C19.8672 5.67969 20 5.40625 20 5.11719V4.375C20 3.33984 19.1602 2.5 18.125 2.5H1.875C0.839844 2.5 0 3.33984 0 4.375V5.11719C0 5.40625 0.132812 5.67578 0.359375 5.85547C1.55469 6.78906 1.94922 7.12109 7.13281 10.8828C7.78906 11.3594 9.09375 12.5156 10 12.5Z"
                    fill="currentcolor"
                  />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
        <div class="leftLinks">
          <div class="frame-2354">
            <img
              class="bell-1"
              src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/bell-1-3.svg"
              alt="bell 1"
            />
            <img
              class="cog-1"
              src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/cog-1-3.svg"
              alt="cog 1"
            />
            <div class="overlap-group">
              <div class="jj">Kashif</div>
            </div>
          </div>
        </div>
      </div>
      <a href="#" class="title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="36"
          viewBox="0 0 14 36"
          fill="none"
        >
          <path
            d="M1.83017 17.4023L10.4198 9.24605C10.7625 8.91558 11.3166 8.91558 11.6593 9.24605L12.1771 9.74526C12.5198 10.0757 12.5198 10.6101 12.1771 10.9406L4.71767 18L12.1698 25.0593C12.5125 25.3898 12.5125 25.9242 12.1698 26.2546L11.6521 26.7539C11.3093 27.0843 10.7552 27.0843 10.4125 26.7539L1.82288 18.5976C1.48747 18.2671 1.48747 17.7328 1.83017 17.4023Z"
            fill="#475F4A"
          />
        </svg>
        Tech Job Broadcast
      </a>
      <div class="downside">
        <div class="leftbar">
          <p class="title">Broadcast Details</p>
          <div class="dTable">
            <div class="dTable0">
              <p class="dullT">Post Date</p>
              <p class="tDate">Jul 2023</p>
            </div>
            <div class="dTable1">
              <p class="dullT">Status</p>
              <p>Published</p>
            </div>
          </div>

          <!---->
          <div class="dMapandTitle">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="112"
                height="71"
                viewBox="0 0 112 71"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M74.016 1.24487L110.969 38.2341V69.9385H0.923096V42.5705L26.235 17.2338L42.1396 33.154L74.016 1.24487Z"
                  fill="#EEEEEE"
                />
                <path
                  d="M110.969 38.2341H111.469V38.0272L111.323 37.8808L110.969 38.2341ZM74.016 1.24487L74.3697 0.891492L74.016 0.537412L73.6623 0.891501L74.016 1.24487ZM110.969 69.9385V70.4385H111.469V69.9385H110.969ZM0.923096 69.9385H0.423096V70.4385H0.923096V69.9385ZM0.923096 42.5705L0.56937 42.2171L0.423096 42.3635V42.5705H0.923096ZM26.235 17.2338L26.5887 16.8805L26.235 16.5264L25.8813 16.8805L26.235 17.2338ZM42.1396 33.154L41.7859 33.5074L42.1396 33.8615L42.4934 33.5074L42.1396 33.154ZM111.323 37.8808L74.3697 0.891492L73.6623 1.59825L110.615 38.5875L111.323 37.8808ZM111.469 69.9385V38.2341H110.469V69.9385H111.469ZM0.923096 70.4385H110.969V69.4385H0.923096V70.4385ZM0.423096 42.5705V69.9385H1.4231V42.5705H0.423096ZM25.8813 16.8805L0.56937 42.2171L1.27682 42.9239L26.5887 17.5872L25.8813 16.8805ZM42.4934 32.8006L26.5887 16.8805L25.8813 17.5872L41.7859 33.5074L42.4934 32.8006ZM73.6623 0.891501L41.7859 32.8006L42.4934 33.5074L74.3698 1.59824L73.6623 0.891501Z"
                  fill="black"
                />
              </svg>
            </div>
            <div>
              <p class="longTitle">
                This is the Broadcast content in a preview. Lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur
              </p>
            </div>
          </div>

          <div class="dTable">
            <div class="dTable0">
              <p class="dullT">Audience Criteria</p>
              <p class="tDate">Matches 337 Users</p>
            </div>
          </div>

          <div class="BestTests">
            <div class="testOne">
              <div>User</div>
              <div>Students</div>
            </div>
            <div class="testOne">
              <div>School</div>
              <div>92880</div>
            </div>
            <div class="testOne">
              <div>Grade Levels</div>
              <div>11th Grade - Graduated</div>
            </div>
            <div class="testOne">
              <div>Grade Point Avg.</div>
              <div>3.5</div>
            </div>
            <div class="testOne">
              <div>Attendance %</div>
              <div>Greater than 50%</div>
            </div>
            <div class="testOne">
              <div>Best ACT</div>
              <div>Greater than 50%</div>
            </div>
            <div class="testOne">
              <div>Best SAT</div>
              <div>Greater than 50%</div>
            </div>
            <div class="testOne">
              <div>Best ASVB</div>
              <div>Greater than 50%</div>
            </div>
          </div>
        </div>
        <div class="smallRightBar">
          <p class="title">Broadcast Insights</p>
          <div class="InsightsTop">
            <div class="InsightsTopLeft">
              <div class="inTD">
                <h1>357</h1>
                <p>Account Reviews</p>
              </div>
              <div class="inTD">
                <h1>357</h1>
                <p>Account Reviews</p>
              </div>
            </div>
            <div class="InsightsTopRight">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="145"
                height="145"
                viewBox="0 0 145 145"
                fill="none"
              >
                <circle cx="72.5" cy="72.5" r="72.5" fill="#259B35" />
                <path
                  d="M131.963 113.978C123.759 125.739 112.252 134.803 98.8972 140.024C85.5423 145.244 70.9391 146.388 56.9343 143.309C42.9296 140.231 30.1522 133.068 20.2181 122.728C10.2839 112.388 3.63913 99.3336 1.12399 85.2167L72.5 72.5L131.963 113.978Z"
                  fill="#475F4A"
                />
                <path
                  d="M145 72.5C145 88.039 140.007 103.167 130.758 115.654L72.5 72.5H145Z"
                  fill="#4C9702"
                />
                <path
                  d="M72.5 0C82.0208 1.13535e-07 91.4485 1.87527 100.245 5.51874C109.041 9.16221 117.033 14.5025 123.765 21.2348C130.498 27.967 135.838 35.9594 139.481 44.7555C143.125 53.5516 145 62.9792 145 72.5L72.5 72.5V0Z"
                  fill="#E3F2E5"
                />
              </svg>
            </div>
          </div>

          <div class="BestTestsP2">
            <div class="testTwo">
              <div style="display: flex; align-items: center; gap: 10px">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_403_5759)">
                      <path
                        d="M12.4999 3.125C6.46966 3.125 1.56243 6.98242 1.56243 11.7188C1.56243 16.4551 6.46966 20.3125 12.4999 20.3125C13.8818 20.3125 15.249 20.1025 16.5624 19.6875L17.3046 19.4531L17.9394 19.9023C19.0624 20.6982 20.7958 21.626 22.9492 21.8359C22.3632 21.0986 21.4941 19.8633 20.957 18.4375L20.6103 17.5244L21.2792 16.8115C22.6904 15.3174 23.4374 13.5547 23.4374 11.7188C23.4374 6.98242 18.5302 3.125 12.4999 3.125ZM12.4999 1.5625C19.4042 1.5625 24.9999 6.1084 24.9999 11.7188C24.9999 14.043 24.0283 16.1719 22.4169 17.8857C23.1445 19.8096 24.6581 21.4404 24.6826 21.46C25.0048 21.8018 25.0927 22.2998 24.9072 22.7295C24.7216 23.1592 24.3017 23.4375 23.8329 23.4375C20.83 23.4375 18.4618 22.1826 17.0409 21.1768C15.6249 21.6211 14.1015 21.875 12.4999 21.875C5.59564 21.875 -6.48499e-05 17.3291 -6.48499e-05 11.7188C-6.48499e-05 6.1084 5.59564 1.5625 12.4999 1.5625Z"
                        fill="#475F4A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_403_5759">
                        <rect
                          width="25"
                          height="25"
                          fill="white"
                          transform="matrix(-1 0 0 1 25 0)"
                        />
                      </clipPath>
                    </defs></svg
                ></span>
                User
              </div>
              <div style="display: flex; align-items: center; gap: 10px">
                2
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="36"
                  viewBox="0 0 14 36"
                  fill="none"
                >
                  <path
                    d="M12.1698 18.5977L3.58024 26.754C3.23753 27.0844 2.68337 27.0844 2.34066 26.754L1.82295 26.2547C1.48024 25.9243 1.48024 25.3899 1.82295 25.0594L9.28233 18L1.83024 10.9407C1.48753 10.6102 1.48753 10.0758 1.83024 9.74536L2.34795 9.24614C2.69066 8.91567 3.24482 8.91567 3.58753 9.24614L12.1771 17.4024C12.5125 17.7329 12.5125 18.2672 12.1698 18.5977Z"
                    fill="#259B35"
                  />
                </svg>
              </div>
            </div>
            <div class="testTwo">
              <div style="display: flex; align-items: center; gap: 10px">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M24.5264 9.31641C24.7168 9.16504 25 9.30664 25 9.5459V19.5312C25 20.8252 23.9502 21.875 22.6562 21.875H2.34375C1.0498 21.875 0 20.8252 0 19.5312V9.55078C0 9.30664 0.27832 9.16992 0.473633 9.32129C1.56738 10.1709 3.01758 11.25 7.99805 14.8682C9.02832 15.6201 10.7666 17.2021 12.5 17.1924C14.2432 17.207 16.0156 15.5908 17.0068 14.8682C21.9873 11.25 23.4326 10.166 24.5264 9.31641ZM12.5 15.625C13.6328 15.6445 15.2637 14.1992 16.084 13.6035C22.5635 8.90137 23.0566 8.49121 24.5508 7.31934C24.834 7.09961 25 6.75781 25 6.39648V5.46875C25 4.1748 23.9502 3.125 22.6562 3.125H2.34375C1.0498 3.125 0 4.1748 0 5.46875V6.39648C0 6.75781 0.166016 7.09473 0.449219 7.31934C1.94336 8.48633 2.43652 8.90137 8.91602 13.6035C9.73633 14.1992 11.3672 15.6445 12.5 15.625Z"
                      fill="#475F4A"
                    />
                  </svg>
                </span>
                Direct Messages
              </div>
              <div style="display: flex; align-items: center; gap: 10px">
                15
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="36"
                  viewBox="0 0 14 36"
                  fill="none"
                >
                  <path
                    d="M12.1698 18.5977L3.58024 26.754C3.23753 27.0844 2.68337 27.0844 2.34066 26.754L1.82295 26.2547C1.48024 25.9243 1.48024 25.3899 1.82295 25.0594L9.28233 18L1.83024 10.9407C1.48753 10.6102 1.48753 10.0758 1.83024 9.74536L2.34795 9.24614C2.69066 8.91567 3.24482 8.91567 3.58753 9.24614L12.1771 17.4024C12.5125 17.7329 12.5125 18.2672 12.1698 18.5977Z"
                    fill="#259B35"
                  />
                </svg>
              </div>
            </div>
            <div class="testTwo">
              <div style="display: flex; align-items: center; gap: 10px">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_403_5749)">
                      <path
                        d="M2.42665 3.06142C5.08778 0.795791 9.06727 1.17177 11.538 3.7206L12.4999 4.71669L13.4618 3.72548C15.454 1.66493 19.4725 0.424697 22.5731 3.06142C25.6395 5.6786 25.8007 10.3759 23.0565 13.2079L13.6083 22.9638C13.3056 23.2763 12.9052 23.4374 12.5048 23.4374C12.1044 23.4374 11.704 23.2811 11.4013 22.9638L1.95302 13.2079C-0.80089 10.3759 -0.639757 5.6786 2.42665 3.06142ZM3.0663 12.1239L12.4804 21.8798L21.9335 12.1239C23.8085 10.1903 24.1991 6.5038 21.5575 4.25282C18.8817 1.96767 15.7372 3.62294 14.5849 4.81435L12.4999 6.96767L10.4149 4.81435C9.28212 3.64247 6.10829 1.98231 3.44227 4.25282C0.805555 6.49892 1.1913 10.1854 3.0663 12.1239Z"
                        fill="#475F4A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_403_5749">
                        <rect
                          width="25"
                          height="25"
                          fill="white"
                          transform="matrix(-1 0 0 1 25 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                Likes
              </div>
              <div style="display: flex; align-items: center; gap: 10px">
                9
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="36"
                  viewBox="0 0 14 36"
                  fill="none"
                >
                  <path
                    d="M12.1698 18.5977L3.58024 26.754C3.23753 27.0844 2.68337 27.0844 2.34066 26.754L1.82295 26.2547C1.48024 25.9243 1.48024 25.3899 1.82295 25.0594L9.28233 18L1.83024 10.9407C1.48753 10.6102 1.48753 10.0758 1.83024 9.74536L2.34795 9.24614C2.69066 8.91567 3.24482 8.91567 3.58753 9.24614L12.1771 17.4024C12.5125 17.7329 12.5125 18.2672 12.1698 18.5977Z"
                    fill="#259B35"
                  />
                </svg>
              </div>
            </div>
            <div class="testTwo">
              <div style="display: flex; align-items: center; gap: 10px">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_403_5749)">
                      <path
                        d="M2.42665 3.06142C5.08778 0.795791 9.06727 1.17177 11.538 3.7206L12.4999 4.71669L13.4618 3.72548C15.454 1.66493 19.4725 0.424697 22.5731 3.06142C25.6395 5.6786 25.8007 10.3759 23.0565 13.2079L13.6083 22.9638C13.3056 23.2763 12.9052 23.4374 12.5048 23.4374C12.1044 23.4374 11.704 23.2811 11.4013 22.9638L1.95302 13.2079C-0.80089 10.3759 -0.639757 5.6786 2.42665 3.06142ZM3.0663 12.1239L12.4804 21.8798L21.9335 12.1239C23.8085 10.1903 24.1991 6.5038 21.5575 4.25282C18.8817 1.96767 15.7372 3.62294 14.5849 4.81435L12.4999 6.96767L10.4149 4.81435C9.28212 3.64247 6.10829 1.98231 3.44227 4.25282C0.805555 6.49892 1.1913 10.1854 3.0663 12.1239Z"
                        fill="#475F4A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_403_5749">
                        <rect
                          width="25"
                          height="25"
                          fill="white"
                          transform="matrix(-1 0 0 1 25 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                Link Clicks
              </div>
              <div style="display: flex; align-items: center; gap: 10px">
                9
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="36"
                  viewBox="0 0 14 36"
                  fill="none"
                >
                  <path
                    d="M12.1698 18.5977L3.58024 26.754C3.23753 27.0844 2.68337 27.0844 2.34066 26.754L1.82295 26.2547C1.48024 25.9243 1.48024 25.3899 1.82295 25.0594L9.28233 18L1.83024 10.9407C1.48753 10.6102 1.48753 10.0758 1.83024 9.74536L2.34795 9.24614C2.69066 8.91567 3.24482 8.91567 3.58753 9.24614L12.1771 17.4024C12.5125 17.7329 12.5125 18.2672 12.1698 18.5977Z"
                    fill="#259B35"
                  />
                </svg>
              </div>
            </div>
            <div class="testTwo">
              <div style="display: flex; align-items: center; gap: 10px">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_403_5749)">
                      <path
                        d="M2.42665 3.06142C5.08778 0.795791 9.06727 1.17177 11.538 3.7206L12.4999 4.71669L13.4618 3.72548C15.454 1.66493 19.4725 0.424697 22.5731 3.06142C25.6395 5.6786 25.8007 10.3759 23.0565 13.2079L13.6083 22.9638C13.3056 23.2763 12.9052 23.4374 12.5048 23.4374C12.1044 23.4374 11.704 23.2811 11.4013 22.9638L1.95302 13.2079C-0.80089 10.3759 -0.639757 5.6786 2.42665 3.06142ZM3.0663 12.1239L12.4804 21.8798L21.9335 12.1239C23.8085 10.1903 24.1991 6.5038 21.5575 4.25282C18.8817 1.96767 15.7372 3.62294 14.5849 4.81435L12.4999 6.96767L10.4149 4.81435C9.28212 3.64247 6.10829 1.98231 3.44227 4.25282C0.805555 6.49892 1.1913 10.1854 3.0663 12.1239Z"
                        fill="#475F4A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_403_5749">
                        <rect
                          width="25"
                          height="25"
                          fill="white"
                          transform="matrix(-1 0 0 1 25 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                Profile Views
              </div>
              <div style="display: flex; align-items: center; gap: 10px">
                12
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="36"
                  viewBox="0 0 14 36"
                  fill="none"
                >
                  <path
                    d="M12.1698 18.5977L3.58024 26.754C3.23753 27.0844 2.68337 27.0844 2.34066 26.754L1.82295 26.2547C1.48024 25.9243 1.48024 25.3899 1.82295 25.0594L9.28233 18L1.83024 10.9407C1.48753 10.6102 1.48753 10.0758 1.83024 9.74536L2.34795 9.24614C2.69066 8.91567 3.24482 8.91567 3.58753 9.24614L12.1771 17.4024C12.5125 17.7329 12.5125 18.2672 12.1698 18.5977Z"
                    fill="#259B35"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "../../../img/logo.png";

export default {
  name: "ScholarPathProviderBroadcast",
  data() {
    return {
      Logo,
      currentLink: null,
    };
  },

  created() {
    this.currentLink = this.$router.currentRoute.path;
  },
  methods: {
    goToSurvey(link) {
      this.$router.push(link);
      this.currentLink = this.$router.currentRoute.path;

    },
  },
};
</script>

<style scoped>
.mainUserInfo {
  width: 30%;
}
.mainCentralLink {
  width: 40%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.leftLinks {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.main {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  gap: 25px;

  min-height: 80vh;
  margin-left: auto;
  margin-right: auto;

  overflow: hidden;
  padding: 0 18px;
  position: relative;
  max-width: 1512px;
  width: 100%;
  padding-top: 40px;
}
.main_child {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.upperside {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 180px;
}
.userInfo {
  width: 362px;
  height: 204px;
  flex-shrink: 0;
  border-radius: 40px;
  background: #fff;
  display: flex;
  align-items: flex-start;
  padding: 10px 40px;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
.logo_center {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(37, 155, 53, 0.13);
}

.logo_center img {
  width: auto;
  height: 43px;
}
.schoolpath_logo {
  color: #259b35;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: auto;
}

.profile img {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  object-fit: cover;
  background-position: center;
}
.proName {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.viewProfile {
  color: #000;
  font-family: Inter;
  text-decoration: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.centralLinks {
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);

  height: 70px;
  flex-shrink: 0;

  width: 243px;
  height: 70px;
  flex-shrink: 0;
}

.top-navigation-bar {
  align-items: center;
  background-color: var(--white);
  border-radius: 100px;
  display: flex;
  height: 100%;
  min-width: 100%;
  padding: 5px 20px;
  justify-content: space-between;
}

.flex-col-4 {
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-height: 58px;
  width: 44px;
}

.overlap-group-4 {
  align-items: center;
  background-color: var(--x259b35);
  border-radius: 100px;
  display: flex;
  height: 44px;
  justify-content: flex-end;
  min-width: 44px;
  padding: 0 10px;
}

.main_link {
  height: 20px;
  width: 23px;
  cursor: pointer;
  color: #a5ac9d;
}

.place {
  color: var(--style);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 500;
  height: 12px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  min-width: 29px;
  white-space: nowrap;
}

.parent_a {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #259b3521;
  display: flex;
  align-items: center;

  justify-content: center;
}

.active {
  color: #259b35 !important;
}

.frame-2354 {
  align-items: center;
  display: flex;
  margin-right: 12px;
  min-width: 109px;
}

.bell-1 {
  height: 20px;
  width: 20px;
}
.cog-1 {
  height: 20px;
  margin-left: 10px;
  width: 20px;
}
.overlap-group {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--celeste);
  border-radius: 20px;
  display: flex;
  height: 40px;
  margin-left: 13px;
  min-width: 46px;
  padding: 10px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downside {
  width: 100%;
  min-height: 1000px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  gap: 20px;
}

.downside .leftbar {
  width: 50%;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
.firstStats {
  width: 100%;
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.stat_0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.figure {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.total {
  color: #475f4a;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.last_seen {
  color: var(--style, #a5ac9d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.secondStats {
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 232px;
  padding: 10px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.published {
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
table {
  width: 100%;
  margin-top: 10px;
}
table thead tr th {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
table tbody tr td {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

table tbody tr {
  color: #000;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 1em;
  line-height: normal;
  text-align: left;
}
tr.spaceUnder > td {
  padding-bottom: 1em;
}

tr.spaceUnder > th {
  padding-bottom: 1em;
}

.smallRightBar {
  width: 50%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 40px;
  padding: 20px 40px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
.engagement {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mainTopHead {
  margin-top: 50px;
}

.tableLine {
  stroke-width: 8px;
  width: 75px;
  height: 10px;
  flex-shrink: 0;
  background: #3a74e9;
  border-radius: 5px;
}

.tableLine0 {
  stroke-width: 8px;
  width: 75px;
  height: 10px;
  flex-shrink: 0;
  background: #c3e54d;
  border-radius: 5px;
}

.mainBottom {
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 302px;
  flex-shrink: 0;
  padding: 40px 30px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  margin-top: 40px;
}

.tableCircle {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: rgba(37, 155, 53, 0.13);
}

.taleParent {
  display: flex;
  align-items: center;
  gap: 10px;
}
.title {
  color: #475f4a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 14px;
  margin-left: 5px;
  text-decoration: none;
}
.dTable {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: 20px;
}
.dTable0 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dTable1 {
  display: flex;
  align-items: center;
  gap: 20px;
}
.dullT {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dTable1 p:nth-child(2) {
  border-radius: 3px;
  background: rgba(37, 155, 53, 0.13);
  width: 93px;
  height: 27px;
  flex-shrink: 0;
  color: var(--style, #259b35);
  font-family: Inter;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.tDate {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dMapandTitle {
  display: flex;
  align-items: flex-start;
  gap: 50px;
}
.longTitle {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.BestTests {
  margin: 0px 20px;
  border-top: 1px solid black;
}

.BestTestsP2 {
  margin: 60px 20px;
}
.testOne {
  margin-top: 20px;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.testTwo {
  margin-top: 20px;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.testOne div:nth-child(1) {
  color: #a5ac9d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.testOne div:nth-child(2) {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.testTwo div:nth-child(1) {
  color: #475f4a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.testTwo div:nth-child(2) {
  color: var(--style, #259b35);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.InsightsTop {
  display: flex;
  align-items: flex-start;
  gap: 40px;
}

.InsightsTopLeft {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.inTD h1 {
  color: var(--style, #259b35);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.inTD p {
  color: var(--style, #259b35);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media all and (max-width: 900px) {
  .downside {
    width: 100%;
    min-height: 1000px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
  }

  .downside .leftbar {
    width: 100%;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    border-radius: 40px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  }
  .smallRightBar {
    width: 100%;
  }
}
</style>
