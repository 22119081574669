<template>
  <div class="StudentLoginPage_gr-234">
    <img class="search-2" :src="src" alt="search 2" />
  </div>
</template>

<script>
export default {
  name: "StudentLoginPage_gr2343",
  props: ["src"],
};
</script>

<style scoped>
.StudentLoginPage_gr-234 {
  align-items: flex-end;
  background-color: var(--x259b35);
  border-radius: 100px;
  display: flex;
  height: 33px;
  justify-content: flex-end;
  min-width: 33px;
  padding: 7px;
}

.search-2 {
  height: 18px;
  width: 18px;
}
</style>
