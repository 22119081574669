<template>
  <div class="">
    <div class="flex gap-3 items-center mt-4">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
          <g clip-path="url(#clip0_17_756)">
            <path
              d="M10.3662 8.56289C9.8745 7.29281 9.05057 6.8325 8.11973 6.69586L11.9089 2.98219C12.3575 2.5425 12.3573 1.82977 11.9089 1.39008L10.8266 0.329531C10.6023 0.109922 10.3081 0 10.0142 0C9.72023 0 9.42606 0.109922 9.20172 0.329766L5.74528 3.71648L3.78243 0.840234C3.35648 0.244687 2.79515 0 2.23574 0C0.686895 0 -0.847601 1.87547 0.546026 3.37617L3.18715 6.22289L0.456577 8.89828L0.00837824 11.4166C-0.0566752 11.783 0.276724 12.0471 0.59649 11.9923L3.1661 11.5507L5.40733 9.35414C5.55107 10.4067 6.22457 12 8.80135 12C11.2248 12 12.2454 10.0917 12.2454 7.92023C11.982 8.09648 11.0577 8.82609 10.7589 8.82609C10.5815 8.82609 10.4296 8.72672 10.3662 8.56289ZM9.74272 0.860156C9.84054 0.764297 10.1878 0.764297 10.2856 0.860156L11.3679 1.92047C11.5173 2.06695 11.5173 2.30531 11.3679 2.4518L10.267 3.53062L8.64207 1.93828L9.74272 0.860156ZM1.11237 2.87156C0.757449 2.4893 0.67398 2.08219 0.863879 1.66125C1.09826 1.14164 1.68805 0.75 2.23574 0.75C2.59928 0.75 2.90876 0.925078 3.15582 1.27078L5.19352 4.25719L3.7291 5.69203L1.11237 2.87156ZM2.79563 10.853L0.825134 11.1914L1.16906 9.26086L8.10083 2.46867L9.72573 4.06102L2.79563 10.853ZM8.80135 11.25C7.86191 11.25 7.1621 11.007 6.72156 10.5277C6.26212 10.0277 6.0947 9.27422 6.15378 8.62266L6.55725 8.22727L7.51464 7.39313C9.22923 7.50117 9.48657 8.40492 9.65064 8.82891C9.82595 9.28242 10.261 9.57586 10.7587 9.57586C10.9158 9.57586 11.0737 9.53953 11.2543 9.46195C10.9574 10.3718 10.2861 11.25 8.80135 11.25Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_756">
              <rect width="12.2454" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div>
        <p class="text-[#636060]">{{ title }}</p>
      </div>
    </div>
    <div class="mt-2">
      <p>
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "resumeDescription",
    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style scoped>

</style>
