<template>
    <div class="w-full">
        <div>
            <h3 class="font-semibold text-lg mb-5">Work activity samples</h3>
            <p class="text-black/80" v-if="tasks && tasks.length>0">
                <ul class="list-disc ml-5 space-y-1">
                    <li v-for="el in tasks" :key="JSON.stringify(el)">{{ el["Task"] }}</li>
                </ul>
            </p>
            <p class="p-10 flex items-center justify-center w-full text-lg font-medium text-gray-700" v-else>
                No Data Found !!
            </p>
        </div>
    </div>
</template>
<script>
import tasksDWAs from "../../assets/js/filtered_occupation/Tasks_DWAs.json"
export default{
    props:["OnetCode"],
    computed:{
        tasks(){
           return tasksDWAs.filter(el => el["O*NET-SOC Code"] == this.OnetCode)
        }
    }
}
</script>