<template>
  <div class="navWrapper">
    <div class="logo">
      <router-link to="/educator/home">
        <figure>
          <img src="../../../assets/images/logo.svg" alt="logo.svg" />
        </figure>
      </router-link>
    </div>
    <div class="buttonGroups">
      <div v-for="btn in buttons" :key="btn.title">
        <router-link :to="btn.router" @click.native="studentDemoHandlerFalse">
          <div class="btn" :class="{ 'active': isActive(btn.router) }">
            {{ btn.title }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="demoAndProfile">
      <router-link to="/educator/studentDemo/home" @click.native="studentDemoHandler">
        <button>Student Demo</button>
      </router-link>
    </div>
    <div class="flex items-center gap-3">
      <div class="user-wrap cursor-pointer">
        <div class="logout-popup">
          <button class="btn px-2 py-1.5 text-sm font-medium rounded-md w-full text-center" @click="logoutNow">
            Logout
          </button>
        </div>
        <img src="../../../assets/images/educatordummmyProfile.svg" alt="user" class="w-10 h-10" />
        <h3 class="text-sm font-semibold">Maia Educator</h3>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { useStudentDemo } from "../../../store/StudentDemoStates"

const buttons = ref([
  { title: 'Home', router: '/educator/home' },
  { title: 'My Broadcasts', router: '/educator/broadcasts' },
  { title: 'Insights', router: '/educator/insights' },
  { title: 'Resources', router: '/educator/pathway' },
]);

const route = useRoute();
const store = useStudentDemo();

const isActive = (routePath) => {
  return route.path === routePath;
};
const logoutNow = () => {
  localStorage.clear();
  window.location.href = "/";
}
const studentDemoHandler = () => {
  store.setIsStudentDemo(true)
}
const studentDemoHandlerFalse = () => {
  store.setIsStudentDemo(false)
}

</script>

<style scoped>
.navWrapper {
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 400px;
}

.buttonGroups {
  background-color: #fff;
  box-shadow: 0px 0px 12px 0px #0000000d;
  height: 100%;
  padding: 11px 25px;
  border-radius: 30px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.btn {
  height: 44px;
  padding: 20px 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #a5ac9d;
}

.btn:hover {
  background-color: #cbeccf;
  color: #259B35;
}

.active {
  background-color: #cbeccf;
  color: #259B35;

}

.demoAndProfile {
  display: flex;
  align-items: center;
  gap: 50px;
}

.demoAndProfile button {
  background-color: #475f4a;
  color: #fff;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 100px;
}

.profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-wrap {
  @apply flex items-center gap-5 relative;
}

.logout-popup {
  @apply w-32 rounded-lg bg-white absolute left-0 top-10 shadow p-2 hidden;
}

.user-wrap:hover .logout-popup {
  @apply block;
}
</style>
